import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {formatDate} from '@angular/common';
import {
  MarkerStatus,
  MarkerStatusProperty,
  Marking,
  MarkingDetail,
  SelectedMarking,
  ISelectedMarkerInputChange,
  IItemInputChecker,
  IDetailDateTimeInputChecker,
  IWarehouseQty
} from '@models/marking.model';
import {SearchChargeCodeDialogComponent} from '@shared-components/search-charge-code-dialog/search-charge-code-dialog.component';
import {ChargeCode, WardItemQuantity} from '@models/chargesheet.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ChargeWardQuantityDialogComponent} from '@shared-components/charge-ward-quantity-dialog/charge-ward-quantity-dialog.component';
import {NgxMatMomentAdapter} from '@angular-material-components/moment-adapter';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, PartialObserver } from 'rxjs';
import * as moment from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChargeSheetService } from '@services/chargesheet.service';

interface IChargeItemMarker {
  desc: string,
  code: string,
  qty?: number,
  details?: MarkingDetail[]
}

interface IEditableInputFields {
  code: boolean,
  quantity:  boolean,
  details: boolean,
  misc: boolean
}

@Component({
  selector: 'app-marker-input',
  templateUrl: 'marker-input.component.html',
  styleUrls: ['../markerpanel.component.scss', './marker-input.component.scss']
})

export class MarkerInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() index: number;
  @Input() oriMarkerValue: Marking;
  @Input() selectedMarker: SelectedMarking;
  @Input() selectedMarkerDetails: MarkingDetail[];
  @Input() currentWard: string;
  @Input() isSubmitted = false;
  @Input() isAttention = false;
  @Input() isDataEntry = false;
  @Input() itemInputChecker: IItemInputChecker;
  @Input() currentPageMarkerErrors: boolean[];
  @Input() isRemoved: boolean;
  @Input() isReadOnly: boolean;
  @Input() dateToCharge: string;
  @Input() chargeItemInWarehouse: { code: string, warehouseQty: number };
  @Input() miscChargeCodeList: ChargeCode[];
  @Input() isDefaultQtyEnabled: boolean = false;

  @Output() inputChange: EventEmitter<ISelectedMarkerInputChange> = new EventEmitter<ISelectedMarkerInputChange>();
  @Output() handleWarehouseQtyUpdate: EventEmitter<IWarehouseQty> = new EventEmitter<IWarehouseQty>();

  markerId: number;
  submittedMarking: MarkingDetail[];
  hasNoError: boolean;
  errorRemark: string;
  chargeCodeNotFound: boolean;

  isCodeEditable: boolean = true;
  isQtyEditable: boolean = true;
  isDetailsEditable: boolean = true;
  isMiscChargingEditable: boolean = true;
  isDetailsRemovable: boolean = true;
  isChargeItemTypeTime: boolean = false;

  selectedMarkerFG: FormGroup;
  fcNameDesc: 'desc' = 'desc';
  fcNameCode: 'code' = 'code';
  fcNameQty: 'quantity' = 'quantity';
  fcNameDetails: 'details' = 'details';

  selectedMarkerFCCodeSub: Subscription;
  selectedMarkerFCQtySub: Subscription;
  selectedMarkerFCDetailsSub: Subscription;
  selectedMarkerFCRemoveReasonSub: Subscription;
  selectedMarkerFCDetailsSubArr: Array<Subscription[]> = [];

  isSelectedMarkerDetailExist: boolean = false;
  startAndEndDateTimeError: { [key: string]: boolean } = { startTime: false, endTime: false };
  dateTimeErrorMsg: { [key: string]: string } = { startTime: '', endTime: '' };

  isErrorInputCorrectedFCCode: boolean;
  isErrorInputCorrectedFCQty: boolean;

  overallMsgTxt: string;
  codeMsgTxt: string;
  qtyMsgTxt: string;

  maxQtyNumber: number;
  isQtyOverMax: boolean;
  qtyWarnMsg: string;

  hasWarnMsg = false;
  displayMsg: MarkerStatus = { code: null, quantity: null, overall: null, details: [] };
  moment: NgxMatMomentAdapter = new NgxMatMomentAdapter('en-GB');
  todayDate: Date = new Date();
  defaultTime = [12, 0];
  convertedDateToCharge: Date;
  isBackDateCharging: boolean;

  searchChargeCodeDialog: MatDialogRef<SearchChargeCodeDialogComponent>;
  chargeWardQtyDialog: MatDialogRef<ChargeWardQuantityDialogComponent>;

  isChargeAsMisc: boolean = false;
  allowChargeAsMisc: boolean = false;

  get isMarkerStatusPending(): boolean { return this.selectedMarker.status === 'PENDING'; }

  get isCodeStartWithS(): boolean {
    const CODE_VALUE: string = this.selectedMarkerFG.get(this.fcNameCode).value;

    return !CODE_VALUE ? false : CODE_VALUE.toLowerCase().startsWith('s');
  }

  constructor(private matDialog: MatDialog, private cdr: ChangeDetectorRef, private chargeSheetService: ChargeSheetService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.markerId = this.selectedMarker.id;
    this.submittedMarking = this.selectedMarker.detail;
    this.hasNoError = this.selectedMarker.remark === 'Success';
    this.errorRemark = this.selectedMarker.remark ? this.selectedMarker.remark : '';
    this.isChargeItemTypeTime = this.selectedMarker.chargeItemType === 'TIME';
    this.convertedDateToCharge = new Date(`${this.dateToCharge}T23:59:59`);
    this.isBackDateCharging = this.moment.compareDate(moment(this.convertedDateToCharge), moment(Date.now())) < 0
    this.isChargeAsMisc = this.selectedMarker.isChargeAsMisc;

    if (!this.chargeItemInWarehouse) {
      this.chargeItemInWarehouse = { code: null, warehouseQty: 0 };
    }

    // Check if Input Fields is Editable
    this.isCodeEditable = this.isReadOnly ? false : this.setEditableInputFields('code').code;
    this.isQtyEditable = this.isReadOnly ? false : this.setEditableInputFields('quantity').quantity;
    this.isDetailsEditable = this.isReadOnly ? false : this.setEditableInputFields('details').details;
    this.isDetailsRemovable = this.isReadOnly ? false : (!this.isAttention && this.isDataEntry);
    this.isMiscChargingEditable = this.isReadOnly ? false : this.setEditableInputFields('misc').misc;

    // Initialize FormGroup
    this.initMarkerInputFG();
  }

  ngAfterViewInit(): void {
    this.setCustomErrorMessage();
    this.chargeQtyChecker(this.selectedMarkerFG, this.selectedMarkerDetails);

    this.selectedMarkerFCCodeSub = this.selectedMarkerFG.get(this.fcNameCode).valueChanges.subscribe(this.selectedMarkerCodeOnChangesObs());
    this.selectedMarkerFCQtySub = this.selectedMarkerFG.get(this.fcNameQty).valueChanges.subscribe(this.selectedMarkerQtyOnChangesObs());
  }

  setEditableInputFields(inputField: 'code' | 'quantity' | 'details' | 'misc'): IEditableInputFields {
    const SELECTED_MARKER_STATUS: string = this.selectedMarker.status;
    const ERROR_REMARK: string = this.errorRemark;

    const MARKER_STATUS_SUCCESS: string[] = ['SUCCESS', 'SUCCESS_WITH_MISC'];

    let code: boolean, quantity: boolean, details: boolean, misc: boolean;

    switch (inputField) {
      case 'code':
        code = (this.isDataEntry || this.isAttention) && (MARKER_STATUS_SUCCESS.indexOf(SELECTED_MARKER_STATUS) < 0 && SELECTED_MARKER_STATUS !== 'PENDING');
        break;
      case 'quantity':
        quantity = (this.isAttention && ERROR_REMARK.indexOf('Qty') < 0) ||
                   (this.isDataEntry || this.isAttention) &&
                   (MARKER_STATUS_SUCCESS.indexOf(SELECTED_MARKER_STATUS) < 0);
        break;
      case 'details':
        details = (this.isDataEntry || this.isAttention) && SELECTED_MARKER_STATUS !== 'PENDING';
        break;
      case 'misc':
        misc = (this.isDataEntry || this.isAttention) && SELECTED_MARKER_STATUS !== 'PENDING';
        break;
    }

    return { code, quantity, details, misc };
  }

  selectedMarkerCodeOnChangesObs(fcName: 'code' = 'code'): PartialObserver<string> {
    return {
      next: (codeValue: string) => {
        this.chargeQtyChecker(this.selectedMarkerFG, this.selectedMarkerDetails);

        if (this.selectedMarkerFG.controls[fcName].errors) {
          this.setErrorMessage(fcName);
        }

        this.selectedMarker.chargeItem = this.selectedMarkerFG.get('desc').value;
        this.selectedMarker.chargeCode = codeValue;
        this.itemInputChecker[fcName] = this.selectedMarkerFG.get(fcName).valid;

        if (this.isDataEntry) {
          this.pageMarkerErrorCheck();
        }

        this.emitInputChange('code');
        // this.inputChange.emit({ index: this.index, selectedMarker: this.selectedMarker, inputField: 'code' });
      }
    }
  }

  selectedMarkerQtyOnChangesObs(fcName: 'quantity' = 'quantity'): PartialObserver<number> {
    return {
      next: (qtyValue: number) => {
        const IS_WAREHOUSE_QTY_LESSER: boolean = this.chargeItemInWarehouse.warehouseQty < qtyValue;
        const IS_ZERO_WAREHOUSE_QTY: boolean = this.chargeItemInWarehouse.warehouseQty === 0
        this.allowChargeAsMisc = (IS_WAREHOUSE_QTY_LESSER || IS_ZERO_WAREHOUSE_QTY) && !this.isCodeStartWithS;

        this.isQtyOverMax = (qtyValue > this.maxQtyNumber) || this.allowChargeAsMisc;
        this.qtyWarnMsg = this.allowChargeAsMisc ? 'More than Cerebral Plus\' Quantity' : 'Over Max Quantity';

        if (this.chargeItemInWarehouse.warehouseQty < qtyValue) {
          this.itemInputChecker.isInsufficient = true;
        } else {
          this.itemInputChecker.isInsufficient = false;
        }

        if (this.selectedMarkerFG.controls[fcName].errors) {
          this.setErrorMessage(fcName);
        }

        if (this.selectedMarker.chargeItemType === 'QUANTITY' || this.selectedMarker.chargeItemType === 'PERCENTAGE') {
          this.selectedMarker.quantity = qtyValue;
          this.itemInputChecker[fcName] = this.selectedMarkerFG.get(fcName).valid;
        }

        if (this.selectedMarkerFG.get(fcName).disabled) {
          this.selectedMarkerFG.get(fcName).patchValue(null, { emitEvent: false, onlySelf: true });
        }

        if (qtyValue < 1 && qtyValue !== null) {
          this.selectedMarkerFG.get(fcName).setErrors({ is_zero: true });
          this.itemInputChecker[fcName] =  this.selectedMarkerFG.get(fcName).valid;
          this.qtyMsgTxt = 'Cannot be less than 1.';
        }

        if (qtyValue === null) {
          this.qtyMsgTxt = 'This field is required.';
        }

        if (this.isDataEntry) {
          this.pageMarkerErrorCheck();
        }

        this.emitInputChange('quantity');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMarker) {
      this.chargeCodeNotFound =
        (this.selectedMarker.border_color === 'blue' && this.selectedMarker.searchCode === 'YES') || !this.selectedMarker.chargeItem;
    }

    if (changes.selectedMarkerDetails && this.selectedMarkerFG) {
      changes.selectedMarkerDetails.currentValue.length > 0 ?
        this.selectedMarkerFG.get(this.fcNameQty).disable({ emitEvent: true, onlySelf: true }) :
        this.selectedMarkerFG.get(this.fcNameQty).enable({ emitEvent: false, onlySelf: true });

      this.isQtyOverMax = false;
    }

    if (changes.isRemoved && this.selectedMarkerFG) {
      const IS_MARKER_REMOVED: boolean = changes.isRemoved.currentValue === true;
      this.assignRemoveReasonFC(IS_MARKER_REMOVED, this.selectedMarkerFG);
    }

    if (changes.chargeItemInWarehouse) {
      // console.log('Insufficient', changes.chargeItemInWarehouse);
      if (changes.chargeItemInWarehouse.currentValue) {
        this.chargeItemInWarehouse = changes.chargeItemInWarehouse.currentValue;
        this.allowChargeAsMisc = changes.chargeItemInWarehouse.currentValue.warehouseQty === 0;
      }
    }
  }

  assignRemoveReasonFC(isRemoved: boolean, selectedMarkerFG: FormGroup): void {
    const FC_NAME_REMOVE_REASON: string = 'removeReason';
    const IS_CONTAIN_REMOVE_REASON_FC: boolean = Object.keys(selectedMarkerFG.controls).indexOf(FC_NAME_REMOVE_REASON) > -1;
    const MARKER_HAS_ERROR: boolean = this.selectedMarker.status.indexOf('SUCCESS') < 0;

    if (isRemoved) {
      selectedMarkerFG.addControl('removeReason', new FormControl(this.selectedMarker.removeReason, Validators.required));
      selectedMarkerFG.get('removeReason').markAsDirty();
      selectedMarkerFG.get('removeReason').markAsTouched();
      this.cdr.detectChanges();

      setTimeout(() => {
        if (this.isAttention && !MARKER_HAS_ERROR) {
          this.itemInputChecker = this.renewValidationInput(this.itemInputChecker, 'removeReason');
          this.emitInputChange('reason');
        }
      });

      this.selectedMarkerFCRemoveReasonSub = selectedMarkerFG.get('removeReason').valueChanges.subscribe({
        next: (value: string) => {
          this.selectedMarker.removeReason = value;

          this.itemInputChecker.removeReason = this.selectedMarkerFG.get('removeReason').valid;

          this.pageMarkerErrorCheck();
          this.emitInputChange('reason');
        }
      });
    }

    if (!isRemoved && IS_CONTAIN_REMOVE_REASON_FC) {
      const IS_FC_QTY_ENABLED: boolean = selectedMarkerFG.get('quantity').enabled;
      const HAS_DETAIL: boolean = Object.keys(selectedMarkerFG.controls).indexOf('details') > -1;

      // Unsubscribe RemoveReason EventListener and Form Control from SelectedMarkerFG
      this.selectedMarkerFCRemoveReasonSub.unsubscribe();
      selectedMarkerFG.removeControl(FC_NAME_REMOVE_REASON);

      if (HAS_DETAIL) {
        this.itemInputChecker = this.renewValidationInput(this.itemInputChecker, 'details');
        this.emitInputChange('details');
      }

      if (IS_FC_QTY_ENABLED) {
        this.itemInputChecker = this.renewValidationInput(this.itemInputChecker, 'quantity');
        this.emitInputChange('quantity');
      }

      if (MARKER_HAS_ERROR) {
        this.setCustomErrorMessage();
      }
    }
  }

  ngOnDestroy(): void {
    if (typeof this.searchChargeCodeDialog === 'object' && this.searchChargeCodeDialog.getState() === 0) {
      this.searchChargeCodeDialog.close();
    }

    if (typeof this.chargeWardQtyDialog === 'object' && this.chargeWardQtyDialog.getState() === 0) {
      this.chargeWardQtyDialog.close();
    }

    if (this.selectedMarkerFCQtySub) {
      this.selectedMarkerFCQtySub.unsubscribe();
    }

    if (this.selectedMarkerFCCodeSub) {
      this.selectedMarkerFCCodeSub.unsubscribe();
    }

    if (this.selectedMarkerFCDetailsSub) {
      this.selectedMarkerFCDetailsSub.unsubscribe();
    }

    if (this.selectedMarkerFCDetailsSubArr.length > 0) {
      this.selectedMarkerFCDetailsSubArr.forEach((startEndDateTimeSubs: Subscription[]) => {
        startEndDateTimeSubs.forEach((inputChangeSub: Subscription) => {
          inputChangeSub.unsubscribe();
        });
      });
    }

    if (this.selectedMarkerFCRemoveReasonSub) {
      this.selectedMarkerFCRemoveReasonSub.unsubscribe();
    }
  }

  initMarkerInputFG(): any {
    this.selectedMarkerFG = new FormGroup({
      [this.fcNameDesc]: new FormControl(this.selectedMarker.chargeItem),
      [this.fcNameCode]: new FormControl(this.selectedMarker.chargeCode, Validators.required),
      [this.fcNameQty]: new FormControl({ value: this.selectedMarker.quantity, disabled: this.selectedMarker.chargeItemType === 'TIME' }, Validators.required)
    });

    this.chargeQtyChecker(this.selectedMarkerFG, this.selectedMarkerDetails);
    this.checkMarkerDetailExist(this.selectedMarkerFG, this.selectedMarkerDetails);

    if (this.isRemoved) {
      // this.selectedMarkerFG.addControl('removeReason', new FormControl(this.selectedMarker.removeReason));
      this.assignRemoveReasonFC(this.isRemoved, this.selectedMarkerFG);
    }
  }

  chargeQtyChecker(selectedMarkerFG: FormGroup, selectedMarkerDetails: MarkingDetail[]): void {
    const CHARGE_DESC_ITEM: string = selectedMarkerFG.get(this.fcNameDesc).value ? selectedMarkerFG.get(this.fcNameDesc).value : '';
    const IS_DESC_MASK_OR_GLOVE: boolean = CHARGE_DESC_ITEM.toLocaleLowerCase().indexOf('mask') > -1 || CHARGE_DESC_ITEM.toLocaleLowerCase().indexOf('glove') > -1;
    const IS_CHARGE_TYPE_QTY: boolean = this.selectedMarker.chargeItemType === 'QUANTITY' || this.selectedMarker.chargeItemType === 'PERCENTAGE';

    if (IS_CHARGE_TYPE_QTY) {
      this.maxQtyNumber = IS_DESC_MASK_OR_GLOVE ? 50 : 20;
      this.isQtyOverMax = selectedMarkerFG.get(this.fcNameQty).value > this.maxQtyNumber;
      selectedMarkerFG.get(this.fcNameQty).enable({ emitEvent: false, onlySelf: true });
    }

    if (!IS_CHARGE_TYPE_QTY) {
      selectedMarkerFG.get(this.fcNameQty).disable({ emitEvent: false, onlySelf: true });
    }

    if (selectedMarkerFG.get(this.fcNameQty).value) {
      const INPUT_QTY: number = selectedMarkerFG.get(this.fcNameQty).value;
      const IS_WAREHOUSE_QTY_LESSER: boolean = !this.isCodeStartWithS && (this.chargeItemInWarehouse.warehouseQty < INPUT_QTY);

      this.allowChargeAsMisc = IS_WAREHOUSE_QTY_LESSER;
      this.isQtyOverMax = ((INPUT_QTY > this.maxQtyNumber) || this.allowChargeAsMisc) && (this.isDataEntry || this.isAttention);
      this.qtyWarnMsg = this.allowChargeAsMisc ? 'More than Cerebral Plus\' Quantity' : 'Over Max Quantity';
    }

    if (this.isDefaultQtyEnabled && IS_CHARGE_TYPE_QTY && !selectedMarkerFG.get(this.fcNameQty).value) {
      selectedMarkerFG.get(this.fcNameQty).patchValue(1);
      this.selectedMarker.quantity = this.selectedMarkerFG.get(this.fcNameQty).value;
      selectedMarkerFG.updateValueAndValidity({ emitEvent: true, onlySelf: true });
      this.itemInputChecker.quantity = true;
      this.pageMarkerErrorCheck()
      this.emitInputChange('quantity');
    }
  }

  checkMarkerDetailExist(selectedMarkerFG: FormGroup, selectedMarkerDetails: MarkingDetail[]): any {
    const IS_CHARGE_TYPE_TIME: boolean = this.selectedMarker.chargeItemType === 'TIME';
    if (IS_CHARGE_TYPE_TIME) {
      this.createNewDetailsFCFromSelectedMarkerFG(selectedMarkerFG);
      let detailFGArr: FormGroup[] | FormArray = selectedMarkerFG.get(this.fcNameDetails)['controls'];

      selectedMarkerDetails.forEach((markingDetail: MarkingDetail, index: number) => {
        const INIT_DETAIL_FG: FormGroup = this.initNewDetailFG();

        const DATE_TIME_FORMAT: string = 'YYYY-MM-DDTHH:mm';
        const MOMENT_FORMAT_STARTTIME: moment.Moment = this.moment.parse(markingDetail.startTime, DATE_TIME_FORMAT);
        const MOMENT_FORMAT_ENDTIME: moment.Moment = this.moment.parse(markingDetail.endTime, DATE_TIME_FORMAT);

        const IS_SAME_DATE: boolean = (MOMENT_FORMAT_STARTTIME || MOMENT_FORMAT_ENDTIME) ? this.isStartAndEndSameDateTime(MOMENT_FORMAT_STARTTIME, MOMENT_FORMAT_ENDTIME) : false;

        if (IS_SAME_DATE) {
          const ERROR_SAME_DATE = { sameDate: true };

          // Update FormGroup Validation, Without setTimeout, form will not update
          setTimeout(() => {
            INIT_DETAIL_FG.get('startTime').setErrors(ERROR_SAME_DATE);
            INIT_DETAIL_FG.get('endTime').setErrors(ERROR_SAME_DATE);

            this.pageMarkerErrorCheck();
          }, 0);

          this.itemInputChecker.details[index].startTime = false;
          this.itemInputChecker.details[index].endTime = false;
        }

        INIT_DETAIL_FG.get('startTime').patchValue(markingDetail.startTime);
        INIT_DETAIL_FG.get('endTime').patchValue(markingDetail.endTime);
        INIT_DETAIL_FG.get('date').patchValue(markingDetail.date);

        if (!this.isDetailsEditable) {
          INIT_DETAIL_FG.get('startTime').disable({ emitEvent: false, onlySelf: true });
          INIT_DETAIL_FG.get('endTime').disable({ emitEvent: false, onlySelf: true });
        }

        detailFGArr.push(INIT_DETAIL_FG);

        if ((this.isDataEntry) || (this.isAttention && this.selectedMarker.status.indexOf("SUCCESS") < 0)) {
          this.assignDetailControlsOnChange((detailFGArr as FormArray), index);
        }
      });

      selectedMarkerFG.get(this.fcNameDetails).patchValue(detailFGArr);
      this.isSelectedMarkerDetailExist = true;
    }

    if (this.isChargeItemTypeTime && selectedMarkerDetails.length === 0 && selectedMarkerFG.get(this.fcNameQty).value === null) {
      this.createNewDetailsFCFromSelectedMarkerFG(selectedMarkerFG);
      this.handleAddDateTime();
    }
  }

  setErrorMessage(inputField: 'code' | 'quantity'): string {
    const IS_REQUIRED: boolean = Object.keys(this.selectedMarkerFG.controls[inputField].errors).indexOf('required') > -1;

    if (inputField === 'code') {
      this.codeMsgTxt = IS_REQUIRED ? 'This field is required.' : null;
      return this.codeMsgTxt;
    }

    if (inputField === 'quantity') {
      const IS_OVER_MAX_QTY: boolean = Object.keys(this.selectedMarkerFG.get('quantity').errors).indexOf('max') > -1;
      const IS_OVER_MIN_QTY: boolean = Object.keys(this.selectedMarkerFG.get('quantity').errors).indexOf('min') > -1;
      const IS_ZERO: boolean = Object.keys(this.selectedMarkerFG.get('quantity').errors).indexOf('is_zero') > -1;
      this.qtyMsgTxt = IS_REQUIRED ? 'This field is required.' :
                       IS_OVER_MAX_QTY ? `The max quantity is ${this.maxQtyNumber}.` :
                       IS_OVER_MIN_QTY ? 'The min quantity is 1.' :
                       IS_ZERO ? `Cannot be less than 1.` : null;

      return this.qtyMsgTxt;
    }

    return null;
  }

  setCustomErrorMessage(): void {
    switch(this.selectedMarker.status) {
      case 'CHARGE_CODE_NOT_FOUND':
        this.selectedMarkerFG.get(this.fcNameCode).markAsDirty();
        this.selectedMarkerFG.get(this.fcNameCode).markAsTouched();
        this.isErrorInputCorrectedFCCode = this.oriMarkerValue.code !== this.selectedMarkerFG.get(this.fcNameCode).value;
        this.selectedMarkerFG.get(this.fcNameCode).setErrors({charge_code_not_found: this.isErrorInputCorrectedFCCode}, { emitEvent: false });
        this.codeMsgTxt = this.errorRemark;
        break ;
      case 'INSUFFICIENT_QUANTITY':
        this.selectedMarkerFG.get(this.fcNameQty).markAsDirty();
        this.selectedMarkerFG.get(this.fcNameQty).markAsTouched();
        this.isErrorInputCorrectedFCQty = this.oriMarkerValue.quantity !== this.selectedMarkerFG.get(this.fcNameQty).value;
        this.selectedMarkerFG.get(this.fcNameQty).setErrors({insufficient_quantity: this.isErrorInputCorrectedFCQty}, { emitEvent: false });
        this.qtyMsgTxt = this.errorRemark;
        break ;
    }
  }

  hasWarning(inputField: string): boolean {
    let isWarn: boolean;
    const STATUS_PROPERTY: MarkerStatusProperty = { text: null, isError: false, isWarn: true, isCorrect: false };

    if (inputField === 'code') {
      isWarn = this.chargeCodeNotFound;
    }
    this.hasWarnMsg = isWarn;
    this.displayMsg[inputField] = isWarn ? STATUS_PROPERTY : null;

    return isWarn;
  }

  setOverallError(): boolean {
    const IS_OVERALL_ERROR: boolean =
      !this.hasNoError &&
      this.selectedMarker.status === 'ERROR' ||
      this.selectedMarker.status === 'CHARGE_CODE_NOT_FOUND' ||
      this.selectedMarker.status === 'SUCCESS_WITH_MISC' ||
      this.selectedMarker.status === 'INVALID_CHARGE_TYPE' ||
      this.selectedMarker.status === 'SUCCESS' ||
      this.selectedMarker.status === 'REMOVED' ||
      this.selectedMarker.status === 'PENDING';

    switch (this.selectedMarker.status) {
      case 'CHARGE_CODE_NOT_FOUND':
        this.overallMsgTxt = 'Please change the charge code or contact Administrator';
        break;
      case 'INSUFFICIENT_QUANTITY':
        this.overallMsgTxt = this.setQuantityErrorMsg(this.selectedMarker, this.errorRemark);
        break ;
      case 'SUCCESS':
      case 'SUCCESS_WITH_MISC':
        const FORMATTED_UPDATED_AT = this.selectedMarker.updatedAt ? formatDate(this.selectedMarker.updatedAt, 'dd/MM/yyyy hh:mm aa', 'en-gb') : '';
        this.overallMsgTxt = this.selectedMarker.status === 'SUCCESS' ? `Charged Successfully. ${FORMATTED_UPDATED_AT}` : this.errorRemark;
        break;
      case 'REMOVED':
        this.overallMsgTxt = 'Charge Item Removed';
        break;
      case 'PENDING':
        this.overallMsgTxt = 'Pending to Charge, but was stopped by User';
        break;
      default:
        this.overallMsgTxt = this.errorRemark;
    }

    return IS_OVERALL_ERROR;
  }

  isInputRequired(): boolean {
    if (!this.itemInputChecker) {
      return false;
    }
    return !this.isAttention && !this.itemInputChecker.isDisabled;
  }

  checkQtyIsLessThanOrEqualZero(qtyValue: number): boolean {
    if (qtyValue >= 0) {
      this.displayMsg.quantity = this.selectedMarker.status === 'INSUFFICIENT_QUANTITY' ? { text: this.errorRemark, isError: true } : null;
    }
    return qtyValue >= 0 && !!qtyValue && qtyValue !== 0;
  }

  isInsufficientQty(qtyValue: number): boolean {
    const ERROR_MSG = this.selectedMarker.remark;
    let totalQuantity: number = Number(ERROR_MSG.substring(ERROR_MSG.indexOf('('), ERROR_MSG.indexOf(')')).split(' ')[4]);
    let notMISC = true;

    if (this.selectedMarker.chargeToWard) {
      const WARD_TOTAL_QUANTITY: number = this.selectedMarker.chargeToWard.totalQuantity;
      notMISC = this.selectedMarker.chargeToWard.ward !== 'MISC';
      totalQuantity = WARD_TOTAL_QUANTITY ? WARD_TOTAL_QUANTITY : totalQuantity;
    }
    const IS_INSUFFICIENT: boolean = ((Number(qtyValue) > totalQuantity) || (Number(qtyValue) < 0)) && notMISC;

    return this.itemInputChecker.quantity = !IS_INSUFFICIENT && qtyValue !== null && qtyValue > 0;
  }

  handleRemoveMarkerDetail(index: number): any {
    const MARKER_DETAIL_FA: FormArray = <FormArray>this.selectedMarkerFG.get(this.fcNameDetails);
    const MARKER_DETAIL_INDEX: number = MARKER_DETAIL_FA.value.findIndex((v, i) => i === index);

    if (MARKER_DETAIL_INDEX !== -1) {
      MARKER_DETAIL_FA.controls[MARKER_DETAIL_INDEX].reset();

      this.selectedMarkerFCDetailsSubArr[index].forEach((detailOnChangeSub: Subscription) => {
        detailOnChangeSub.unsubscribe();
      });

      // Remove MarkerDetail by Index
      MARKER_DETAIL_FA.removeAt(MARKER_DETAIL_INDEX);
      this.selectedMarker.detail.splice(MARKER_DETAIL_INDEX, 1);
      this.itemInputChecker.details.splice(MARKER_DETAIL_INDEX, 1);
    }

    // When MarkerDetail Length is 0, then remove Details controller from FormGroup
    if (MARKER_DETAIL_FA.value.length === 0) {
      this.isSelectedMarkerDetailExist = false;
      this.selectedMarkerFG.get(this.fcNameQty).enable({ emitEvent: true, onlySelf: true });
      this.selectedMarkerFG.removeControl(this.fcNameDetails);
      this.itemInputChecker = this.renewValidationInput(this.itemInputChecker, this.fcNameQty);
    }

    this.emitInputChange('details');
  }

  openSearchChargeCodeDialog(marking: SelectedMarking): void {
    this.searchChargeCodeDialog = this.matDialog.open(SearchChargeCodeDialogComponent, {
      minWidth: '1080px',
      autoFocus: false,
      data: {
        marking
      }
    });

    this.searchChargeCodeDialog.afterClosed().subscribe({
      next: (data: ChargeCode) => {
        if (data) {
          this.selectedMarkerFG.get(this.fcNameDesc).setValue(data.description, { emitEvent: false });
          this.selectedMarkerFG.get(this.fcNameCode).setValue(data.code, { emitEvent: true });
          this.itemInputChecker.code = true;
          this.pageMarkerErrorCheck();
          this.setCustomErrorMessage();

          const CONTAIN_TYPE: boolean = Object.keys(data).indexOf('type') > -1;
          const IS_TYPE_TIME: boolean = CONTAIN_TYPE && data.type === 'TIME';
          const IS_TYPE_QUANTITY: boolean = CONTAIN_TYPE && (data.type === 'QUANTITY' || data.type === 'PERCENTAGE');
          this.selectedMarker.chargeItemType = data.type;
          this.isChargeItemTypeTime = IS_TYPE_TIME;

          if (IS_TYPE_TIME) {
            this.handleAddDateTime();
          }

          if (IS_TYPE_QUANTITY) {
            const IS_QTY_DISABLED: boolean = this.selectedMarkerFG.get(this.fcNameQty).disabled;

            if (IS_QTY_DISABLED) {
              this.selectedMarkerFG.get(this.fcNameQty).enable();
              this.selectedMarkerFG.get(this.fcNameQty).setValidators([Validators.required]);

              this.isSelectedMarkerDetailExist = false;
              this.selectedMarkerFG.removeControl(this.fcNameDetails);
            }

            this.selectedMarkerFG.get(this.fcNameQty).updateValueAndValidity({ emitEvent: true, onlySelf: true });
          }

          this.chargeSheetService.getSingleChargeItemWarehouseQty(data.code, this.currentWard, this.selectedMarker.id).subscribe({
            next: (res: IWarehouseQty) => {
              if (res.chargeSheetMarkingSelectedId === this.selectedMarker.id) {
                this.chargeItemInWarehouse.code = data.code;
                this.chargeItemInWarehouse.warehouseQty = res.warehouseQty;

                this.allowChargeAsMisc = res.warehouseQty < this.selectedMarkerFG.get(this.fcNameQty).value;
                this.isQtyOverMax = this.allowChargeAsMisc;

                this.handleWarehouseQtyUpdate.emit({ chargeCode: data.code, chargeSheetMarkingSelectedId: this.selectedMarker.id, warehouseQty: res.warehouseQty });
              }
            }
          })
        }
      }
    });
  }

  openChargeWardDialog(marking: SelectedMarking, index: number): void {
    this.chargeWardQtyDialog = this.matDialog.open(ChargeWardQuantityDialogComponent, {
      data: {
        chargeCode: marking.chargeCode,
        chargeItem: marking.chargeItem,
        chargeQuantity: marking.quantity,
        wardItemQuantity: {
          ward: marking.chargeToWard ? marking.chargeToWard.ward : this.currentWard,
          code: marking.chargeCode,
          quantity: marking.quantity
        }
      }
    });

    this.chargeWardQtyDialog.afterClosed().subscribe({
      next: (wardQuantityData: WardItemQuantity) => {
        if (!wardQuantityData) {
          return;
        }

        if (wardQuantityData.ward === 'RESET') {
          this.selectedMarkerFG.get(this.fcNameCode).setValue(this.oriMarkerValue.items, { emitEvent: false });
          this.selectedMarkerFG.get(this.fcNameDesc).setValue(this.oriMarkerValue.code, { emitEvent: false });
          delete marking.chargeToWard;
          this.itemInputChecker.quantity = this.isInsufficientQty(marking.quantity);
          this.pageMarkerErrorCheck();
          return;
        }

        if (wardQuantityData.ward === 'MISC') {
          this.selectedMarkerFG.get(this.fcNameCode).setValue('SMISISC', { emitEvent: false });
          this.selectedMarkerFG.get(this.fcNameDesc).setValue('MISCELLANEOUS', { emitEvent: false });
        }
        this.itemInputChecker.quantity = true;
        Object.assign(marking, {chargeToWard: wardQuantityData});
        this.pageMarkerErrorCheck();
        this.emitInputChange('ward');
      }
    });
  }

  setQuantityErrorMsg(marker: SelectedMarking, errorMsg: string): string {
    if (!this.isAttention || !this.itemInputChecker) {
      return ;
    }

    const newWardValue: WardItemQuantity = marker.chargeToWard;
    if (!this.itemInputChecker.quantity) {
      return errorMsg;
    }

    if (marker.chargeToWard && this.currentWard !== newWardValue.ward) {
      return `Charge to ${newWardValue.ward}`;
    }

    return errorMsg.replace('Insufficient Qty ', ' ');
  }

  pageMarkerErrorCheck(): any {
    // To Solve ExpressionChangedAfterHasBeenCheckedError for pageMarkerErrors
    setTimeout(() => {
      let checker = this.itemInputChecker.code && this.itemInputChecker.quantity;

      if (this.itemInputChecker.isDisabled) {
        checker = checker && this.itemInputChecker.isDisabled;
      }

      if (this.itemInputChecker.isUnavailable) {
        checker = checker && !this.itemInputChecker.isUnavailable;
      }

      if (this.selectedMarker.chargeItemType === 'TIME' && !this.itemInputChecker.isDisabled && !this.itemInputChecker.isUnavailable) {
        checker = this.itemInputChecker.code;
        const isValidDetail: boolean = this.itemInputChecker.details
          .map((value: IDetailDateTimeInputChecker) => value.startTime && value.endTime)
          .indexOf(false) < 0;
        checker = checker && isValidDetail;
      }

      if (this.isAttention && this.itemInputChecker.removeReason) {
        checker = this.itemInputChecker.removeReason;
      }

      this.currentPageMarkerErrors[this.index] = checker;
    });
  }

  disabledInputNumber(inputEvent: Event) {
    return inputEvent.preventDefault();
  }

  convertToMoment(dateTime: { start: any, end: any }): { START_TIME: any, END_TIME: any } {
    const dateTimeFormat = 'dd/MM/yyyy HH:mm';
    const parseStartTime = this.moment.parse(dateTime.start, dateTimeFormat);
    const parseEndTime = this.moment.parse(dateTime.end, dateTimeFormat);
    return { START_TIME: parseStartTime, END_TIME: parseEndTime };
  }

  compareDateWithTime(detail: MarkingDetail): boolean {
    if (!detail) {
      return false;
    }
    const { START_TIME, END_TIME } = this.convertToMoment({start: detail.startTime, end: detail.endTime});

    return this.moment.compareDateWithTime(START_TIME, END_TIME, false) === 0;
  }

  handleAddDateTime(): any {
    const IS_FG_CONTAIN_DETAILS_FC: boolean = Object.keys(this.selectedMarkerFG.controls).indexOf(this.fcNameDetails) > -1;
    const IS_VALIDATE_INPUT_CONTAIN_DETAILS: boolean = Object.keys(this.itemInputChecker).indexOf(this.fcNameDetails) > -1;

    this.isSelectedMarkerDetailExist = true;

    const NEW_DETAIL_FG: FormGroup = this.initNewDetailFG();
    const NEW_DETAIL: MarkingDetail = this.initNewDetailForSelectedMarker();

    // Check selectedMarker FormGroup contains FormControl name 'details'
    if (!IS_FG_CONTAIN_DETAILS_FC) {
      this.createNewDetailsFCFromSelectedMarkerFG(this.selectedMarkerFG);
    }

    // Check itemInputChecker contains Object Key name 'details'
    if (!IS_VALIDATE_INPUT_CONTAIN_DETAILS) {
      this.itemInputChecker = this.renewValidationInput(this.itemInputChecker, 'details');
    }

    // Create FormGroup[] to store new MarkerDetails
    let newDetailArr: FormGroup[] = this.selectedMarkerFG.controls[this.fcNameDetails]['controls'];
    let newDetailArrIndex: number = 0;

    newDetailArr.push(NEW_DETAIL_FG);
    newDetailArrIndex = newDetailArr.length - 1;
    this.selectedMarkerFG.get(this.fcNameDetails).patchValue(newDetailArr);

    if (newDetailArr.length > 0) {
      this.selectedMarkerFG.get(this.fcNameQty).reset();
      this.selectedMarkerFG.get(this.fcNameQty).disable({ emitEvent: false, onlySelf: true });
      this.selectedMarkerFG.get(this.fcNameQty).updateValueAndValidity({emitEvent: false});
    }

    // Add New MarkerDetail into SelectedMarker.Detail
    this.selectedMarker.detail.push(NEW_DETAIL);
    this.emitInputChange('details');

    // Check itemInputChecker - Renew to Quantity/DateTime
    if (Object.keys(this.itemInputChecker).indexOf('details') > -1) {
      const INIT_VALIDATE_START_END_DATETIME = { startTime: false, endTime: false };
      Object.assign(this.itemInputChecker, { details: [INIT_VALIDATE_START_END_DATETIME] });
    }

    this.pageMarkerErrorCheck();
    this.assignDetailControlsOnChange(this.selectedMarkerFG.controls[this.fcNameDetails]['controls'], newDetailArrIndex);
  }

  createNewDetailsFCFromSelectedMarkerFG(selectedMarkerFG: FormGroup): any {
    selectedMarkerFG.addControl(this.fcNameDetails, new FormArray([]));
    selectedMarkerFG.setValidators([Validators.required]);
    selectedMarkerFG.get(this.fcNameDetails).updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  initNewDetailFG(): FormGroup {
    const FC_CODE_VALUE: string = this.selectedMarkerFG.get(this.fcNameCode).value;
    const FC_ID_VALUE: number = this.selectedMarker.id;

    return new FormGroup({
      chargeCode: new FormControl(FC_CODE_VALUE),
      chargeSheetMarkingSelectedId: new FormControl(FC_ID_VALUE),
      endTime: new FormControl(null, Validators.required),
      startTime: new FormControl(null, Validators.required),
      date: new FormControl(new Date())
    });
  }

  initNewDetailForSelectedMarker(): MarkingDetail {
    return {
      chargeCode: null,
      chargeSheetMarkingSelectedId: null,
      endTime: null,
      startTime: null,
      date: new Date(),
      miscChargeCode: null,
      miscChargeItem: null
    };
  }

  assignDetailControlsOnChange(detailFA: FormArray, index: number): Subscription[][] {
    const NEW_DETAIL_FG: FormGroup = detailFA[index];
    const DETAIL_START_TIME: string = 'startTime';
    const DETAIL_END_TIME: string = 'endTime';

    const DETAIL_START_TIME_ON_CHANGE: Subscription = NEW_DETAIL_FG.get(DETAIL_START_TIME).valueChanges.subscribe(this.selectedMarkerStartDateTimeObs(NEW_DETAIL_FG, index));
    const DETAIL_END_TIME_ON_CHANGE: Subscription = NEW_DETAIL_FG.get(DETAIL_END_TIME).valueChanges.subscribe(this.selectedMarkerEndDateTimeObs(NEW_DETAIL_FG, index));

    const DETAIL_START_END_TIME: Subscription[] = [DETAIL_START_TIME_ON_CHANGE, DETAIL_END_TIME_ON_CHANGE];

    this.selectedMarkerFCDetailsSubArr.push(DETAIL_START_END_TIME);
    this.cdr.detectChanges();

    return this.selectedMarkerFCDetailsSubArr;
  }

  selectedMarkerStartDateTimeObs(detailFG: FormGroup, currentIndex?: number): PartialObserver<any> {
    return {
      next: (startTime: moment.Moment) => {
        const FC_NAME: 'startTime' = 'startTime';
        const DETAIL_DATETIME_CHECKER: IDetailDateTimeInputChecker = this.itemInputChecker.details[currentIndex];
        DETAIL_DATETIME_CHECKER.startTime = !!startTime;
        const IS_DATETIME_MOMENT = moment.isMoment(startTime);
        let value = startTime;

        if (!IS_DATETIME_MOMENT) {
          value = moment(startTime, 'YYYY-MM-DDTHH:mm');
        }

        this.dateTimeOnChange(FC_NAME, value, detailFG, currentIndex, DETAIL_DATETIME_CHECKER);
        this.cdr.detectChanges();
      }
    }
  }

  selectedMarkerEndDateTimeObs(detailFG: FormGroup, currentIndex?: number): PartialObserver<any> {
    return {
      next: (endTime: moment.Moment) => {
        const FC_NAME: 'endTime' = 'endTime';
        const DETAIL_DATETIME_CHECKER: IDetailDateTimeInputChecker = this.itemInputChecker.details[currentIndex];
        DETAIL_DATETIME_CHECKER.endTime = !!endTime;
        const IS_DATETIME_MOMENT = moment.isMoment(endTime);
        let value = endTime;

        if (!IS_DATETIME_MOMENT) {
          value = moment(endTime, 'YYYY-MM-DDTHH:mm');
        }

        this.dateTimeOnChange(FC_NAME, value, detailFG, currentIndex, DETAIL_DATETIME_CHECKER);
        this.cdr.detectChanges();
      }
    }
  }

  dateTimeOnChange(inputField: 'startTime' | 'endTime', value: moment.Moment, detailFG: FormGroup, index: number, dateTimeInputChecker: IDetailDateTimeInputChecker): any {
    const INPUT_DATETIME_TO_COMPARE: string = inputField === 'startTime' ? 'endTime' : 'startTime';
    const DATE_TIME_FORMAT: string = 'dd/MM/yyyy HH:mm';
    const COMPARE_VALUE: moment.Moment = this.moment.parse(detailFG.get(INPUT_DATETIME_TO_COMPARE).value, DATE_TIME_FORMAT);
    let isSameDate: boolean = false;

    if (value) {
      this.selectedMarker.detail[index][inputField] = value;
      this.emitInputChange('details');
    }

    if (!value || !COMPARE_VALUE) {
      return ;
    }

    if (inputField === 'endTime' && value) {
      isSameDate = this.isStartAndEndSameDateTime(COMPARE_VALUE, value);
    }

    if (inputField === 'startTime' && value) {
      isSameDate = this.isStartAndEndSameDateTime(value, COMPARE_VALUE);
    }

    // Set Form Control Error for StartTime and EndTime
    if (isSameDate) {
      const ERROR_SAME_DATE = { sameDate: true };
      detailFG.get('startTime').setErrors(ERROR_SAME_DATE);
      detailFG.get('endTime').setErrors(ERROR_SAME_DATE);

      // Validate Input set to False
      dateTimeInputChecker.startTime = false;
      dateTimeInputChecker.endTime = false;
    }

    // Remove Form Control Error from StartTime/EndTime
    if (!isSameDate && inputField !== INPUT_DATETIME_TO_COMPARE) {
      detailFG.get(INPUT_DATETIME_TO_COMPARE).setErrors(null);
      dateTimeInputChecker[INPUT_DATETIME_TO_COMPARE] = true;
    }

    this.pageMarkerErrorCheck();
  }

  setDateTimeValidation(dateTimeFC: FormControl): boolean {
    const IS_EMPTY: boolean = dateTimeFC.hasError('required');
    const IS_EMPTY_AND_MODIFIED: boolean = (IS_EMPTY && dateTimeFC.dirty);
    const IS_SAME_DATE: boolean = dateTimeFC.hasError('sameDate');

    // Update DateTime Error Message, only for empty date time
    if (IS_EMPTY) {
      const REQUIRED_MSG: string = 'This field is required';
      this.dateTimeErrorMsg.startTime = REQUIRED_MSG;
      this.dateTimeErrorMsg.endTime = REQUIRED_MSG;
    }

    if (IS_SAME_DATE) {
      this.dateTimeErrorMsg.startTime = '';
      this.dateTimeErrorMsg.endTime = '';
    }

    return IS_SAME_DATE || IS_EMPTY_AND_MODIFIED;
  }

  isStartAndEndSameDateTime(startTime: moment.Moment, endTime: moment.Moment): boolean {
    if (!startTime || !endTime) {
      return false;
    }

    return this.moment.compareDateWithTime(startTime, endTime, false) === 0;
  }

  renewValidationInput(itemInputChecker: IItemInputChecker, valueToAdd: 'details' | 'quantity' | 'removeReason'): IItemInputChecker {
    // Renew validation input, instead of using delete object value
    const COMPULSORY_KEY_OBJ: IItemInputChecker = { code: itemInputChecker.code };
    const ITEM_CHECKER_CONTAIN_UNAVAILABLE: boolean = Object.keys(itemInputChecker).indexOf('isUnavailable') > -1;
    const ITEM_CHECKER_CONTAIN_DISABLED: boolean = Object.keys(itemInputChecker).indexOf('isDisabled') > -1;
    const ITEM_CHECKER_CONTAIN_INSUFFICIENT: boolean = Object.keys(itemInputChecker).indexOf('isInsufficient') > -1;
    const NEW_VALIDATE_INPUT = {
      [valueToAdd]: valueToAdd === 'details' ? [] : false,
      isDisabled: ITEM_CHECKER_CONTAIN_DISABLED ? itemInputChecker.isDisabled : false,
      isUnavailable: ITEM_CHECKER_CONTAIN_UNAVAILABLE ? itemInputChecker.isUnavailable : false,
      isInsufficient: ITEM_CHECKER_CONTAIN_INSUFFICIENT ? itemInputChecker.isInsufficient : false
    };
    const ARRANGED_OBJ: IItemInputChecker = Object.assign(NEW_VALIDATE_INPUT, COMPULSORY_KEY_OBJ);

    return ARRANGED_OBJ;
  }

  emitInputChange(inputType?: 'code' | 'quantity' | 'details' | 'ward' | 'reason' | 'misc'): void {
    // Emit Input Changes to MarkerPanel Component
    let inputChangeBody: ISelectedMarkerInputChange = {
      selectedMarkerIndex: this.index,
      selectedMarker: this.selectedMarker,
      itemInputChecker: this.itemInputChecker,
      inputType
    };

    const FG_CONTAIN_MISC_INFO: boolean = Object.keys(this.selectedMarkerFG.controls).indexOf('miscInfo') > -1;
    const IS_MISC_CHARGING: boolean = Object.keys(this.selectedMarker).indexOf('isChargeAsMisc') > -1;

    if (IS_MISC_CHARGING && FG_CONTAIN_MISC_INFO) {
      if (this.selectedMarker.isChargeAsMisc) {
        const MISC_INFO_FG = this.selectedMarkerFG.get('miscInfo');
        const MISC_CODE: string = MISC_INFO_FG.get('code').value;
        const MISC_DESC: string = MISC_INFO_FG.get('desc').value;

        inputChangeBody = Object.assign(inputChangeBody, { miscInfo: { code: MISC_CODE, desc: MISC_DESC }, isChargeAsMisc: this.isChargeAsMisc });

        this.selectedMarker.miscChargeCode = MISC_CODE;
        this.selectedMarker.miscChargeItem = MISC_DESC;
      }

      if (!this.selectedMarker.isChargeAsMisc) {
        inputChangeBody = Object.assign(inputChangeBody, { isChargeAsMisc: this.isChargeAsMisc });
        this.selectedMarker.miscChargeCode = null;
        this.selectedMarker.miscChargeItem = null;
      }
    }

    return this.inputChange.emit(inputChangeBody);
  }

  handleChargeAsMisc(cbChargeAsMisc: MatCheckboxChange): void {
    this.selectedMarker.isChargeAsMisc = cbChargeAsMisc.checked;
    this.isChargeAsMisc = this.selectedMarker.isChargeAsMisc;

    if (cbChargeAsMisc.checked) {
      this.currentPageMarkerErrors[this.index] = false;
    } else {
      const FG_CONTROLS_NAME: string[] = Object.keys(this.selectedMarkerFG.controls);

      if (FG_CONTROLS_NAME.indexOf('miscInfo') >= 0){
        this.selectedMarkerFG.removeControl('miscInfo');
      }

      if (this.isDataEntry) {
        this.currentPageMarkerErrors[this.index] = this.selectedMarkerFG.valid;
      }

      if (this.isAttention) {
        this.currentPageMarkerErrors[this.index] = this.selectedMarkerFG.valid && this.selectedMarker.status.indexOf('SUCCESS') >= 0;
      }

      this.emitInputChange('quantity');
    }
  }

  handleChargeAsMiscInputChange(chargeAsMiscInfo: FormGroup): void {
    this.selectedMarkerFG.addControl('miscInfo', chargeAsMiscInfo);

    if (this.currentPageMarkerErrors) {
      this.currentPageMarkerErrors[this.index] = this.selectedMarkerFG.get('miscInfo').valid;
    }

    this.emitInputChange('quantity');
  }

  displayChargeAsMiscCheckbox(): boolean {
    if ((!this.isAttention && !this.allowChargeAsMisc) || this.isCodeStartWithS) {
      return false;
    }

    if (this.chargeItemInWarehouse) {
      const WAREHOUSE_QTY: number = this.chargeItemInWarehouse.warehouseQty;
      const SELECTED_MARKER_QTY: number = this.selectedMarkerFG.get(this.fcNameQty).value;
      this.allowChargeAsMisc = (WAREHOUSE_QTY < SELECTED_MARKER_QTY);
    }

    if (this.isAttention) {
      return this.selectedMarker.status.indexOf('SUCCESS') < 0 && !this.isCodeStartWithS;
    }

    return (this.allowChargeAsMisc && this.miscChargeCodeList.length > 0) && this.isDataEntry;
  }
}
