import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ChargeSheetService} from '@services/chargesheet.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {ChargeSheet, IChargeSheetAndChecker} from '@models/chargesheet.model';
import {IDialogData} from '@models/warn-dialog.model';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorDetail, IMiscInfo, Marking, SelectedMarking} from '@models/marking.model';
import { MatDialog } from '@angular/material/dialog';
import { ChargeSheetWarningDialogComponent } from '@shared-components/charge-sheet-warning-dialog/charge-sheet-warning-dialog.component';
import { Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-attention-list',
  templateUrl: 'attention.component.html'
})

export class AttentionComponent implements OnInit, OnDestroy {
  @Output() listLoading: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Input() currentUserId: number;
  list: any[] = [];
  chargeSheetForm: FormGroup;
  submissionId: number = null;

  episodeNumberData = {
    isError: false,
    errorMsg: null,
    value: null,
    overallError: null
  };

  episodeNumberFCSub: Subscription;

  submittedChargeSheetId: number = null;
  errorMarkers = new Map();
  errorChanges: Map<'details', ErrorDetail[]> = new Map();
  unavailableWard: Map<number, number[]> = new Map();

  isReadOnly: boolean = false;
  isLoading: boolean = false;

  hasQtyWarningArr: boolean[] = [];
  chargeItemAsMiscById: Map<string, IMiscInfo> = new Map();

  csStatusMsg: string = '';

  constructor(private chargeSheetService: ChargeSheetService,
              private userService: UserService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private matSnackBar: MatSnackBar,
              private matDialog: MatDialog) { }

  ngOnInit() {
    this.chargeSheetForm = this.formBuilder.group({
      id: [''],
      episodeNumber: [{value: '', disabled: true}, Validators.required],
      backDateCharging: [{value: '', disabled: true}, Validators.required],
      serialNumber: [{value: '', disabled: true}],
      ward: [{value: '', disabled: true}, Validators.required],
      dateTime: [{value: '', disabled: true}, Validators.required],
      marking: [{value: []}],
      mrnNumber: [''],
      updatedDateTime: [{value: ''}]
    });

    this.activatedRoute.params.subscribe({
      next: (queryParams) => this.submissionId = queryParams.submissionId
    });

    this.isReadOnly = (this.userService.currentUserRoleValue.name === 'STORE USER') || (this.userService.currentUserRoleValue.name === 'GUEST');
  }

  ngOnDestroy(): void {
      if (this.episodeNumberFCSub) {
        this.episodeNumberFCSub.unsubscribe();
      }
  }

  setCustomFCErrorsWithTimeout(remark: string): void {
    const getFGObjKeysArr: string[] = Object.keys(this.chargeSheetForm.controls);
    // Only Episode Number
    const errorFCName: string = getFGObjKeysArr[1];

    setTimeout(() => {
      let errorObj: { [key: string]: boolean } = {};

      switch(remark.toLowerCase()) {
        case 'patient not found':
          this.episodeNumberFCSub = this.chargeSheetForm.get('episodeNumber').valueChanges
          .pipe(
            startWith(this.episodeNumberData.value),
            pairwise())
          .subscribe({
            next: ([previousValue, episodeNumber]: [string, string]) => {
              this.episodeNumberData.isError = this.episodeNumberData.value === episodeNumber || !episodeNumber;
              if (this.episodeNumberData.isError && episodeNumber) {
                this.episodeNumberData.errorMsg = remark;
              }

              if (!episodeNumber) {
                this.episodeNumberData.errorMsg = 'This field is required.'
              }
            }
          });
          Object.assign(errorObj, { [remark.toLowerCase().split(' ').join('_')]: true });
        break;
      }

      this.chargeSheetForm.get(errorFCName).markAsTouched();
      this.chargeSheetForm.get(errorFCName).markAsPristine();
      this.chargeSheetForm.get(errorFCName).setValidators([Validators.required]);
      this.chargeSheetForm.get(errorFCName).updateValueAndValidity();
      this.chargeSheetForm.setErrors(errorObj, {emitEvent: false});
    }, 1);
  }

  handleChargeSheetLoad(chargeSheetData: IChargeSheetAndChecker): void {
    const chargeSheet: ChargeSheet = chargeSheetData.data;
    this.errorMarkers = chargeSheetData.ItemInputCheckerById;
    this.submittedChargeSheetId = chargeSheet.submittedChargeSheetId;

    // If ChargeSheet have remark for EpisodeNumber
    if (chargeSheet.remark) {
      this.episodeNumberData = {
        isError: chargeSheet.remark.toLowerCase().indexOf('patient not found') > -1,
        errorMsg: chargeSheet.remark,
        value: chargeSheet.episodeNumber,
        overallError: chargeSheet.remark.toLowerCase()
      };
      this.chargeSheetForm.controls.episodeNumber.enable();
      this.setCustomFCErrorsWithTimeout(chargeSheet.remark);
    }

    this.csStatusMsg = this.chargeSheetService.setChargeSheetStatus(chargeSheet.dischargeStatus, chargeSheet.billFinalizedStatus);
    this.chargeSheetService.setChargeSheetHeader(this.chargeSheetForm.controls, chargeSheet);
  }

  handleErrorCorrected(markerChanged: { type: string, id: null, isErrorCorrected: null, marker: null }): void {
    this.errorMarkers.set(markerChanged.id, markerChanged.isErrorCorrected);

    if (markerChanged.marker) {
      this.setErrorChanges(markerChanged.marker);
    }
  }

  handleDisableMarker(markerChanged: { marker: null, isDisabled: null }): any {
    const MARKER: SelectedMarking = markerChanged.marker;
    const DISABLED_MARKER_OBJ = {id: MARKER.id, status: 'RM', removeReason: MARKER.removeReason, chargeItemType: MARKER.chargeItemType};

    let getErrorChangesDetail: any[] = [];
    let getIndex: number = null;
    let errorDetail = null;
    let keyCounter = 0;

    if (!this.errorChanges.get('details')) {
      return this.errorChanges.set('details', [DISABLED_MARKER_OBJ]);
    }

    if (this.errorChanges.get('details')) {
      getErrorChangesDetail = this.errorChanges.get('details');
      getIndex = getErrorChangesDetail.findIndex((value, index) => value.id === MARKER.id);
      errorDetail = getErrorChangesDetail[getIndex];
    }

    if (errorDetail) {
      keyCounter = Object.keys(errorDetail).length;
    }

    if (keyCounter <= 2 && !markerChanged.isDisabled) {
      return getErrorChangesDetail.splice(getIndex, 1);
    }

    if (getIndex >= 0 && markerChanged.isDisabled) {
      return Object.assign(errorDetail, {status: 'RM'});
    }

    if (getIndex >= 0 && !markerChanged.isDisabled) {
      return delete errorDetail.status;
    }

    if (getIndex < 0) {
      return getErrorChangesDetail.push(DISABLED_MARKER_OBJ);
    }
  }

  setErrorChanges(marker: SelectedMarking): any {
    const detail: ErrorDetail = {id: marker.id, chargeCode: marker.chargeCode, chargeItem: marker.chargeItem, chargeItemType: marker.chargeItemType};
    const IS_CHARGE_TYPE_TIME: boolean = marker.chargeItemType === 'TIME';

    if (marker.removeReason) {
      Object.assign(detail, {status: 'RM', removeReason: marker.removeReason});
    }

    if (marker.quantity) {
      Object.assign(detail, {quantity: marker.quantity});
    }

    if (IS_CHARGE_TYPE_TIME) {
      Object.assign(detail, { startTime: marker.detail[0].startTime, endTime: marker.detail[0].endTime });
    }

    if (!this.errorChanges.get('details')) {
      return this.errorChanges.set('details', [detail]);
    }

    const getErrorChangesDetail: any[] = this.errorChanges.get('details');
    const getIndex = getErrorChangesDetail.findIndex((value, index) => value.id === marker.id);

    if (Object.keys(marker).indexOf('chargeToWard') > -1) {
      Object.assign(detail, {ward: marker.chargeToWard.ward});
    }

    if (getIndex >= 0) {
      return Object.assign(getErrorChangesDetail[getIndex], detail);
    } else {
      return this.errorChanges.get('details').push(detail);
    }
  }

  isInputErrorCorrected(episodeNumberData: any): boolean {
    if (!episodeNumberData) {
      return false;
    }
    const oriValue: string = episodeNumberData.value;
    const value: string = this.chargeSheetForm.controls.episodeNumber.value;
    const isError: boolean = (oriValue === value && episodeNumberData.isError) || value.length === 0;
    this.episodeNumberData.isError = isError;
    return isError;
  }

  submit(): any {
    const hasError: boolean = this.validateMarkerError();
    if (this.episodeNumberData.isError) {
      let sortedPagesNumber: number[] = [];
      if (this.unavailableWard.size > 0) {
        sortedPagesNumber = Array.from(this.unavailableWard.keys()).sort();
      }
      this.openSnackBar(
        `Please update the error(s) before proceed.
        ${sortedPagesNumber.length > 0 ? `Unavailable ward found in Page ${sortedPagesNumber.toString()}` : ''}`,
        true);
      return;
    }

    // if (hasError) {
    //   this.openSnackBar('Please fill in required/valid input field(s) before proceed.', true);
    //   return;
    // }

    let qtyOverMaxArr: Array<{ [key: string]: string | number }> = this.errorChanges.size === 0 ? [] : this.getQtyOverMaxArr(this.errorChanges.get('details'));
    const HAS_QTY_WARN: boolean = this.hasQtyWarningArr.indexOf(true) > -1;

    if (qtyOverMaxArr.length === 0) {
      qtyOverMaxArr = [];
      this.openWarnMsgDialog('S', null);
      return ;
    }

    if (!this.episodeNumberData.isError && this.episodeNumberData.overallError === 'patient not found') {
      const episodeNumber = this.chargeSheetForm.controls.episodeNumber.value;
      return this.submitAttention('S', episodeNumber, 'episode-no');
    }

    if ((qtyOverMaxArr.length > 0 || HAS_QTY_WARN)) {
      this.openWarnMsgDialog('S', qtyOverMaxArr);
      return;
    }

    return this.submitAttention('S', this.errorChanges, 'charge-sheet');
  }

  getQtyOverMaxArr(changedMarkings: ErrorDetail[]): { [key: string]: string | number }[] {
    let overMaxQtyItem = [];
    for (let marker of changedMarkings) {
      if (Object.keys(marker).indexOf('quantity') < 0) {
        continue;
      }

      const getChargeItem: string = marker.chargeItem ? marker.chargeItem.toLowerCase() : '';
      const getSelectedMarkings: SelectedMarking[] = this.chargeSheetForm.get('marking').value;
      const isMaskOrGloveDesc: boolean = getChargeItem.indexOf('mask') > -1 && getChargeItem.indexOf('glove') > -1;
      const maxQtyBaseOnDesc: number = isMaskOrGloveDesc ? 50 : 20;
      const isSelectedMarkerSuccess: boolean = !marker.status ? false : marker.status.indexOf('SUCCESS') > -1;

      if ((marker.quantity > maxQtyBaseOnDesc) && !isSelectedMarkerSuccess) {
        const selectedMarker = getSelectedMarkings.find(selectedMarker => marker.id === selectedMarker.id);
        overMaxQtyItem.push({ item: marker.chargeItem, page: selectedMarker.page, quantity: marker.quantity });
      }
    }
    return overMaxQtyItem;
  }

  openWarnMsgDialog(submitStatus: 'S', qtyOverMaxArr: { [key: string]: string | number }[]): void {
    const DIALOG_DATA: IDialogData = {
      warnType: ['non-input-change']
    };

    if (qtyOverMaxArr && qtyOverMaxArr.length > 0) {
      DIALOG_DATA.warnType.push('quantity');
    }

    if (this.hasQtyWarningArr.indexOf(true) > -1) {
      DIALOG_DATA.warnType.push('insufficient');
    }

    const DIALOG_CONFIG: { [key: string]: any | any[] } = {
      maxHeight: '700px',
      minWidth: '600px',
      data: DIALOG_DATA
    };

    this.matDialog.open(ChargeSheetWarningDialogComponent, DIALOG_CONFIG)
      .afterClosed()
      .subscribe({
        next: (isDialogConfirm: boolean) => {
          if (isDialogConfirm) {
            this.submitAttention(submitStatus, this.errorChanges, 'charge-sheet');
          }
        }
      });
  }

  submitAttention(status: 'S', updateValue: string | Map<'details', ErrorDetail[]>, updateType: 'episode-no' | 'charge-sheet'): void {
    this.isLoading = true;
    this.chargeSheetService.updateAttentionChargeSheet(this.submittedChargeSheetId, updateValue, updateType, this.chargeItemAsMiscById)
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.openSnackBar('Updating Charge Sheet ...', false);
          this.router.navigate([`/charge-sheet/submitted-list`], { queryParams: { tab: 0 } }).then();
        },
        error: (error) => {
          this.isLoading = false;
          this.openSnackBar('Failed to update charge sheet.', true);
        }
      });
  }

  validateMarkerError(): boolean {
    const errorArr: boolean[] = [];
    this.hasQtyWarningArr = [];
    this.errorMarkers.forEach((value, key, map) => {
      const FILTER_CHARGE_TYPE = Object.keys(value).filter((keyName) => keyName === 'chargeType');
      const hasDisabled: boolean = Object.keys(value).indexOf('isDisabled') > -1;
      const hasUnavailable: boolean = Object.keys(value).indexOf('isUnavailable') > -1;
      const HAS_INSUFFICIENT: boolean =  Object.keys(value).indexOf('isInsufficient') > -1;

      const HAS_DETAIL: boolean = Object.keys(value).indexOf('details') > -1;
      const HAS_REMOVE_REASON: boolean = Object.keys(value).indexOf('removeReason') > -1;

      if (hasDisabled && !HAS_REMOVE_REASON) {
        return;
      }

      if (HAS_INSUFFICIENT) {
        this.hasQtyWarningArr.push(value.isInsufficient);
      }

      if (hasUnavailable) {
        this.setWardUnavailableList(key);
        errorArr.push(value.code && value.qty && !value.isUnavailable);
      }

      if (HAS_REMOVE_REASON) {
        errorArr.push(value.removeReason);
        return ;
      }

      if (HAS_DETAIL) {
        const markerDetail: any[] = value.details;
        const hasError = markerDetail.filter(detail => !detail.startTime || !detail.endTime);
        errorArr.push(value.code && hasError.length < 1);
      }

      if (FILTER_CHARGE_TYPE.length > 0) {
        errorArr.push(value.code && value.qty && value.chargeType);
      }

      if (FILTER_CHARGE_TYPE.length <= 0 && !hasUnavailable) {
        errorArr.push(value.code && value.qty);
      }
    });

    return errorArr.indexOf(false) > -1;
  }

  handleMiscChargeItem(miscInfo: Map<string, IMiscInfo>): any {
    this.chargeItemAsMiscById = miscInfo;
  }

  setWardUnavailableList(chargeSheetId: number): void {
    const selectedMarker: Marking[] = this.chargeSheetForm.value.marking;
    for (const marker of selectedMarker) {
      if (chargeSheetId === marker.id) {
        !this.unavailableWard.get(marker.page) ?
          this.unavailableWard.set(marker.page, [marker.id]) :
          this.unavailableWard.get(marker.page).push(marker.id);
      }
    }
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
