import {Component, OnInit} from '@angular/core';
import {ChargeSheetService} from '@services/chargesheet.service';
import {Dashboard, History} from '@models/dashboard.model';
import {ChartOptions, ChartType, ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

interface IDashboardTask {
  label: string;
  link: string;
  tooltip: string;
  taskNumber: number;
  className: string;
}

interface ICumulativeDashboard {
  startDate: string;
  endDate: string;
  dashboard: IDashboardTask[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  todayDate: string = new Date().toISOString();

  submittedListQueryParam = { 'waiting': 0, 'complete': 1, 'attention': 2, 'error': 0 };
  todayTasks: IDashboardTask[] = [];
  cumulative: ICumulativeDashboard = { startDate: null, endDate: null, dashboard: [] };

  barChartData: ChartDataSets[] = [{data: []}];

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartOptions: ChartOptions = {};
  barChartColor: Color[] = [{ backgroundColor: '#9575CD' }];
  barChartPlugins = [pluginDataLabels];

  startInterval: any;
  countdown = 0;
  constructor(private chargeSheetService: ChargeSheetService) {
  }

  ngOnInit(): void {
    this.chargeSheetService.getDashboard().subscribe({
      next: (data: Dashboard) => {
        this.setTodayTaskText(data.today);
        this.setBarChartData(data.history);
      }
    });

    this.chargeSheetService.getCumulativeDashboard().subscribe({
      next: (value: any) => {
        this.setCumulativeTask(value);
      }
    });
  }

  setTodayTaskText(todayTaskData: any): void {
    Object.keys(todayTaskData).map((key) => {
      const NEW_TODAY_TASK: IDashboardTask = {} as IDashboardTask;

      if (key !== 'startDate' && key !== 'endDate' && key !== 'history') {
        Object.assign(NEW_TODAY_TASK, this.assignDashboardTask(key));
        NEW_TODAY_TASK.taskNumber = todayTaskData[key];

        this.todayTasks.push(NEW_TODAY_TASK);
      }

      if (NEW_TODAY_TASK.className !== 'draft' && NEW_TODAY_TASK.className  !== 'unassigned') {
        NEW_TODAY_TASK.link = '/charge-sheet/submitted-list';
      }
    });
  }

  setCumulativeTask(cumulativeData: any): void {
    Object.keys(cumulativeData).map((key) => {
      const NEW_TODAY_TASK: IDashboardTask = { label: '', link: '', taskNumber: 0, tooltip: '', className: '' };

      if (key !== 'startDate' && key !== 'endDate' && key !== 'history') {
        Object.assign(NEW_TODAY_TASK, this.assignDashboardTask(key));
        NEW_TODAY_TASK.taskNumber = cumulativeData[key];

        this.cumulative.dashboard.push(NEW_TODAY_TASK);
      } else {
        this.cumulative[key] = cumulativeData[key];
      }
    });
  }

  assignDashboardTask(taskName: string): IDashboardTask {
    let newTask: IDashboardTask = {} as IDashboardTask;
    let toolTip: string = 'Go To [TEXT]';

    switch(taskName) {
      case 'unassigned':
        newTask.className = 'unassigned';
        newTask.label = 'Unassigned';
        newTask.tooltip = toolTip.replace('[TEXT]', 'Unassigned List');
        newTask.link = '/charge-sheet/unassigned';
        break;
      case 'inProgress':
        newTask.className = 'waiting';
        newTask.label = 'In Queue';
        newTask.tooltip = toolTip.replace('[TEXT]', 'In Queue List');
        break;
      case 'attention':
        newTask.className = 'attention';
        newTask.label = 'Attention';
        newTask.tooltip = toolTip.replace('[TEXT]', 'Attention List');
        break;
      case 'success':
        newTask.className = 'complete';
        newTask.label = 'Completed';
        newTask.tooltip = toolTip.replace('[TEXT]', 'Completed List');
        break;
      case 'draft':
        newTask.className = 'draft';
        newTask.label = 'Draft';
        newTask.tooltip = toolTip.replace('[TEXT]', 'Draft List');
        newTask.link = '/charge-sheet/my-draft';
        break;
      case 'error':
        newTask.className = 'error';
        newTask.label = 'Error';
        newTask.tooltip = toolTip.replace('[TEXT]', 'In Queue List');
        break;
      default:
        return {} as IDashboardTask;
    }

    return newTask;
  }

  handleRefreshToday(): void {
    this.btnRefreshCountdown();
    this.chargeSheetService.getDashboard('today').subscribe({
      next: (data: Dashboard) => {
        this.todayTasks = [];
        this.setTodayTaskText(data.today);
      }
    });
  }

  btnRefreshCountdown(): void {
    this.countdown = 5;

    this.startInterval = setInterval(() => {
      if (this.countdown === 0) {
        clearInterval(this.startInterval);
        return;
      }
      this.countdown--;
    }, 1000);
  }

  setBarChartData(dashboardData: History[]): any {
    let largestNum: number = null;
    if (!dashboardData.length) {
      return ;
    }

    for (const history of dashboardData) {
      this.barChartLabels.push(history.stmtDate);
      this.barChartData[0].data.push(history.number);
      if (history.number > largestNum) {
        largestNum = history.number;
      }
    }

    const IS_EVEN: boolean = (largestNum % 2) === 0;
    let stepSize: number = 2;
    let maxYAxis: number = 2;

    if (largestNum > 0) {
      stepSize = this.assignAverageStepSize(largestNum, IS_EVEN);
      maxYAxis = (largestNum + stepSize);
    }

    this.barChartOptions = {
      plugins: {
        datalabels: {
          color: '#868686',
          align: 'top',
          anchor: 'end',
          font: {
            size: 20,
            weight: 'bold',
          },
          borderWidth: 1
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 4.5,
      scales: {
        xAxes: [{}],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: stepSize,
            max: maxYAxis
          },
        }],
      }
    };
  }

  assignAverageStepSize(largestNum: number, isEvenNumber: boolean): number {
    const numberToDivide: number = isEvenNumber ? 2 : 3;
    return Math.round((largestNum / numberToDivide));
  }
}
