import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

interface IReportsQueryStr {
  tab: number;
}

@Component({
  selector: 'app-reports',
  templateUrl: 'reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  reportTabIndex: {[key:string]: number} = { 'misc': 0 };
  selectedTabIndex: number;

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next: (query: IReportsQueryStr) => {
        this.selectedTabIndex = query.tab;
      }
    });
  }
}
