import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';

import {ChargeSheetService} from '@services/chargesheet.service';

import {Ward} from '@models/ward.model';
import {ChargeSheet} from '@models/chargesheet.model';

import {PDFDocumentProxy} from 'ng2-pdf-viewer';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-newentry',
  templateUrl: './newentry.component.html',
  styleUrls: ['./newentry.component.scss'],
  providers: []
})

export class NewEntryComponent implements OnInit, OnDestroy {
  loadingPDF = false;
  loading = true;

  selectedWard: number;
  wardSelection: Ward[];
  newEntryForm: FormGroup;
  uploadedFile: string | ArrayBuffer;
  totalPdfPages: number;
  currentPage = 1;
  pageNumGreaterThan: boolean = this.currentPage > 1;

  selectedPreviewPdfTitle: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private chargeSheetService: ChargeSheetService,
    private matSnackBar: MatSnackBar) {
    this.newEntryForm = this.formBuilder.group({
      ward: ['', [Validators.required]],
      chargeSheets: [[]]
    });
  }

  ngOnInit(): void {
    this.chargeSheetService.getWardList().subscribe({
      next: (wardList) => {
        this.wardSelection = wardList;
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.newEntryForm.reset();
  }

  resetForm() {
    this.newEntryForm.reset({
      ward: '',
      chargeSheets: []
    });
    this.uploadedFile = null;
  }

  fnChange(e) {
    this.newEntryForm.get('ward').setValue(e.value, {
      onlySelf: true
    });
  }

  onSubmit() {
    const chargeSheetsValue: any[] = this.newEntryForm.controls.chargeSheets.value;
    const wardValue: string = this.newEntryForm.controls.ward.value;
    if (this.newEntryForm.invalid || chargeSheetsValue.length === 0) {
      // console.log('Something wrong. Please try again.', this.newEntryForm.controls);
      return this.openSnackBar(this.validationMessage(wardValue, chargeSheetsValue), true);
    }

    this.progressSnackBar('Perform Scanning ...');

    this.chargeSheetService.uploadAttachmentBase64(this.newEntryForm.get('chargeSheets').value)
      .pipe(
        tap(_ => {
          console.log('Creating New Entry');
        }),
        switchMap((attachmentId: any) => {
          return this.chargeSheetService.createNewEntry(this.newEntryForm.get('ward').value, attachmentId.id);
        })
      ).subscribe({
      next: () => {
        this.loading = false;
        this.openSnackBar('Successfully Create New Entry', false);
        this.router.navigate([`/charge-sheet/my-draft`]);
      },
      error: (errMsg) => this.openSnackBar(errMsg, true)
    });
  }

  pdfCallbackFn(pdf: PDFDocumentProxy) {
    this.totalPdfPages = pdf.numPages;
    this.loadingPDF = false;
  }

  handleChooseFile(file, uploadFileEl: HTMLInputElement) {
    const selectedFile: File = file.target.files[0];
    this.selectedPreviewPdfTitle = selectedFile.name;
    this.renderSelectedFile(selectedFile, true);
    return uploadFileEl.value = null;
  }

  renderSelectedFile(file: File, addNewFile: boolean = false) {
    const fileReader: FileReader = new FileReader();
    this.selectedPreviewPdfTitle = file.name;
    fileReader.onload = () => {
      this.uploadedFile = fileReader.result;
      if (addNewFile) {
        this.newEntryForm.controls.chargeSheets.value.push({
          file,
          base64Str: (fileReader.result as string).replace(/^data:.+;base64,/, '') });
      }
    };
    fileReader.readAsDataURL(file);
  }

  onProgress(e) {
    this.loadingPDF = true;
  }

  validationMessage(ward: string, chargeSheets: any[]): string {
    let errMsg = 'Please ';
    if (ward.length === 0) {
      errMsg += 'select ward';
    }

    if (chargeSheets.length === 0) {
      errMsg += ward.length === 0 ? ' and upload charge sheet' : ' upload attachment';
    }

    errMsg += ' before proceed.';
    return errMsg;
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }

  private progressSnackBar(message: string) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError: false
      },
      verticalPosition: 'top',
      duration: 0,
      panelClass: ['primary-sb']
    });
  }

  handleClickPdfPreview(attachment: any): any {
    this.renderSelectedFile(attachment.file);
  }

  handleRemoveAttachment(index): any[] {
    const attachments = this.newEntryForm.controls.chargeSheets.value;
    if (index !== 0 && attachments[index].file.name === this.selectedPreviewPdfTitle) {
      this.renderSelectedFile(attachments[index - 1].file);
    } else {
      this.uploadedFile = null;
    }
    return attachments.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    const attachments = this.newEntryForm.controls.chargeSheets.value;
    moveItemInArray(attachments, event.previousIndex, event.currentIndex);
  }
}
