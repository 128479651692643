import {Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {ChargeSheetService} from '@services/chargesheet.service';
import {UserService} from '@services/user.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {HttpResponse} from '@angular/common/http';
import {startWith} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared-components/confirm-dialog/confirm-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

interface ISubmittedListTab {
  tab: number;
  label?: string;
  en?: string;
}

@Component({
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss']
})
export class SubmittedComponent implements OnInit, OnDestroy {
  currentUserId: number = this.userService.currentUserValue.id;
  private submittedList$: Subscription;
  private isComponentOnInit: boolean = true;
  loading = true;
  list: any[] = [];
  submittedTab = {0: 'I,E,CG', 1: 'C,ARC', 2: 'A,S', 3: ''};
  submittedStatusName: { [key: string]: string } = {
    'I': 'In Progress', 'E': 'Error', 'CG': 'Charging',
    'C': 'Completed', 'ARC': 'Archived',
    'A': 'Attention', 'S': 'Stop'
  };
  tabIndexObj = { waiting: 0, complete: 1, attention: 2, archived: 3, searchAll: 4 };
  tabIndex = 0;
  autoRefreshList: any;
  pageNumber = 1;

  totalItem = 0;
  searchCriteriaArr: string[] = ['episodeNumber'];
  searchCriteria: { episodeNumber: string } = { episodeNumber: null };
  searchHasRecord: boolean;

  currentFilterType: string;
  filterTypeText: { [key:number]: string[] } = {
    0: ['I', 'E', 'CG'],
    1: ['C', 'ARC'],
    2: ['A', 'S']
  };

  constructor(
    private chargeSheetService: ChargeSheetService,
    private router: Router,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private location: Location) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next: (query: ISubmittedListTab) => {
        this.tabIndex = query.tab;
        this.currentFilterType = this.submittedTab[this.tabIndex];
        const QUERY_PARAMS_CONTAINS_EN: boolean = Object.keys(query).indexOf('en') >  -1;

        if (QUERY_PARAMS_CONTAINS_EN) {
          this.searchCriteria.episodeNumber = query.en;
        }

        if (this.isComponentOnInit) {
          this.initAutoRefreshList(true);
        }

        this.isComponentOnInit = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.autoRefreshList.unsubscribe();
    this.submittedList$.unsubscribe();

    this.matDialog.closeAll();
  }

  handleTabChange(value: MatTabChangeEvent): void {
    this.loading = true;
    this.tabIndex = value.index;
    this.searchCriteria = { episodeNumber: null };
    this.list = [];
    this.currentFilterType = this.submittedTab[value.index];

    const URL_QUERY_PARAMS: string = this.router.createUrlTree([], { relativeTo: this.route, queryParams: { tab: value.index } }).toString();
    this.location.go(URL_QUERY_PARAMS);

    if (this.submittedList$) {
      this.submittedList$.unsubscribe();
    }

    if (this.tabIndexObj.searchAll === this.tabIndex) {
      this.loading = false;
      this.autoRefreshList.unsubscribe();
    }

    if (this.tabIndexObj.searchAll !== this.tabIndex) {
      this.getSubmittedList(this.currentUserId, this.currentFilterType);

      if (this.autoRefreshList.isStopped) {
        this.initAutoRefreshList(false);
      }
    }
  }

  initAutoRefreshList(isInit: boolean): void {
    this.autoRefreshList =
      this.chargeSheetService.autoRefreshList()
        .pipe(startWith(() => isInit ? this.getSubmittedList(this.currentUserId, this.currentFilterType) : null))
        .subscribe({
          next: () => {
            this.getSubmittedList(this.currentUserId, this.currentFilterType, this.pageNumber);
          },
          error: (errMsg: string) => this.openSnackBar(errMsg, true)
        });
  }

  handleGetSubmittedList(): void {
    this.tabIndexObj.searchAll !== this.tabIndex ?
      this.getSubmittedList(this.currentUserId, this.currentFilterType) :
      this.getSearchSubmittedList(this.searchCriteria, this.pageNumber);
  }

  getSubmittedList(currentUserId: number, submittedType: string, pageNumber = 1): void {
    this.submittedList$ = this.chargeSheetService
      .getSubmittedList(currentUserId, submittedType, pageNumber, this.tabIndexObj.waiting === Number(this.tabIndex) ? 100 : 15 )
      .subscribe({
        next: (res: HttpResponse<any>) => {
          this.totalItem = Number(res.headers.get('x-total-count'));
          this.list = res.body;
          this.loading = false;
        },
        error: (errMsg: string) => {
          this.openSnackBar(errMsg, true);
          this.loading = false;
          this.list = [];
        }
      });
  }

  getSearchSubmittedList(criteriaObj, pageNumber = 1): void {
    this.submittedList$ = this.chargeSheetService.searchSubmittedListDetail(criteriaObj, pageNumber, 100)
      .subscribe({
        next: (res: HttpResponse<any>) => {
          this.totalItem = Number(res.headers.get('x-total-count'));
          this.list = res.body;
          this.searchHasRecord = this.list.length > 0;
          this.loading = false;
        },
        error: (errMsg: string) => {
          this.openSnackBar(errMsg, true);
          this.loading = false;
          this.list = [];
        }
      });
  }

  handleSearchResult(result: {criteriaObj: any, searchValue: string, searchNew: boolean}): any {
    this.loading = true;

    if (result) {
      this.searchCriteria = result.criteriaObj;
      if (!result.searchNew) {
        this.searchHasRecord = undefined;
        this.pageNumber = 1;
        this.list = [];
      }
      this.getSearchSubmittedList(this.searchCriteria);
    }
    return ;
  }

  handleCBFilterChanged(cbFilterChange: MatCheckboxChange, filterType: string) {
    let splitCurrentFilterType = this.currentFilterType.split(',');
    const IS_CHECKED: boolean = cbFilterChange.checked;
    let indexToRemove: number = -1;
    let checkedFilterType: string;

    switch(filterType.toLowerCase()) {
      case 'c':
        checkedFilterType = 'C';
        indexToRemove = splitCurrentFilterType.indexOf('C');
        break;
      case 'arc':
        checkedFilterType = 'ARC';
        indexToRemove = splitCurrentFilterType.indexOf('ARC');
        break;
    }

    if (indexToRemove > -1 && !IS_CHECKED) {
      splitCurrentFilterType.splice(indexToRemove, 1);
    }

    if (indexToRemove < 0 && IS_CHECKED) {
      splitCurrentFilterType = splitCurrentFilterType.concat(checkedFilterType);
    }

    this.currentFilterType = splitCurrentFilterType.join(',');

    this.refreshList();
  }

  refreshList(): any {
    this.loading = true;

    if (this.tabIndexObj.searchAll !== this.tabIndex) {
      this.getSubmittedList(this.currentUserId, this.currentFilterType);
    }
  }

  handlePageChange(pageNumber: number): void {
    this.pageNumber = pageNumber;

    if (this.tabIndexObj.searchAll === this.tabIndex) {
      this.getSearchSubmittedList(this.searchCriteria, pageNumber);
    } else {
      this.getSubmittedList(this.currentUserId, this.currentFilterType, pageNumber);
    }
  }

  handleListLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  handleItemClick(value = { action: null, data: null, listType: null }): void {
    const submissionId: number = value.data.id;
    const action: string = value.action;
    let listType: string = value.listType;

    if (listType === 'searchAll') {
      switch (value.data.status.toLowerCase()) {
        case 'attention':
          listType = 'attention';
          break;
        case 'completed':
          listType = 'completed';
          break;
        case 'archived':
          listType = 'archived';
          break;
        default:
          listType = 'waiting';
      }
    }

    switch (action) {
      case 'resubmit':
        this.chargeSheetService.resubmitChargeSheet(submissionId).subscribe({
          next: () => this.handleGetSubmittedList()
        });
        break;
      case 'preview':
        this.router.navigate([`/charge-sheet/submitted-list/${listType}/${submissionId}`]).then();
        break;
      case 'stop':
        this.handleStopProcess(submissionId);
        break;
      case 'download':
        this.handleDownloadPDF(value.data.attachmentUri);
        break;
      case 'archive':
        const episodeNumber: string = value.data.episodeNumber;
        this.matDialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Archive Selected Charge Sheet',
            iconTitle: 'info',
            description: `Update Charge Sheet to Archive.`,
            dialogType: 'accent',
            section: 'archive',
            value: { episodeNumber: episodeNumber ? episodeNumber : '::NO EPISODE NUMBER::', ward: value.data.ward }
          }
        }).afterClosed()
          .subscribe({
            next: (isDialogConfim) => {
              if(isDialogConfim) {
                this.chargeSheetService.updateChargeSheetToArchive(submissionId).subscribe({
                  next: () => {
                    this.openSnackBar('Successfully moved to archived.', false);
                    this.pageNumber = 1;
                    this.handleGetSubmittedList();
                  },
                  error: () => this.openSnackBar('Successfully moved to archived.', true)
                });
              }
            }
          });
        break;
    }
  }

  private handleDownloadPDF(attachmentUri: string): void {
    if (attachmentUri) {
      window.open(attachmentUri, '_blank');
    } else {
      this.openSnackBar('This charge sheet does not contain PDF attachment link.', true);
    }
  }

  public handlePriorityClick(submissionId: number): void {
    this.chargeSheetService.setUrgentChargeSheet(submissionId)
      .subscribe({
        next: () => this.handleGetSubmittedList(),
        error: (errMsg: string) => this.openSnackBar(errMsg, true)
      });
  }

  private handleStopProcess(submissionId: number): void {
    this.chargeSheetService.setStopRobotProcess(submissionId)
      .subscribe({
        next: () => {
          this.openSnackBar('Successfully stopped the process.', false);
          this.handleGetSubmittedList();
        },
        error: () => this.openSnackBar('The charge sheet is being updated now. You are not allowed to stop the process. Please contact your administrator.', true)
      });
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
