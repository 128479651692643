import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@shared-helpers/auth.guard';
import {ChargeSheetGuard} from '@shared-helpers/charge-sheet.guard';
import {ReportsComponent} from './reports/reports.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'auth/login', pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charge-sheet',
    loadChildren: () => import('./chargesheet/chargesheet.module').then(m => m.ChargesheetModule),
    canActivate: [ChargeSheetGuard],
    canActivateChild: [ChargeSheetGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings-routng.module').then(m => m.SettingsRoutingModule)
  },
  {
    path: '**', redirectTo: 'charge-sheet/dashboard'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
