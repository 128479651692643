import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ReportsService } from '@services/reports.service';

import { IMiscChargeItemReport, IReportRange } from '@models/reports.model';
import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-misc-only-report',
  templateUrl: 'misc-only.component.html',
  styleUrls: ['./misc-only.component.scss']
})

export class MiscellaneousOnlyComponent implements OnInit {
  miscDateFG: FormGroup;

  selectedDateRange: IReportRange = { startDate: null, endDate: null };
  totalDataFound: number = null;
  miscData: IMiscChargeItemReport[];
  isLoading: boolean = false;
  isStartDownload: boolean = false;

  currentReportName: string;

  get startDate(): Date { return this.selectedDateRange.startDate; };

  get endDate(): Date { return this.selectedDateRange.endDate; };

  get isFormInvalid(): boolean { return this.miscDateFG.invalid; };

  constructor(private reportsService: ReportsService, private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.miscDateFG = new FormGroup({
      startDate: new FormControl(null, [Validators.required]),
      endDate: new FormControl(null, [Validators.required])
    });
  }

  handleDownloadMiscReport(): void {
    if (this.isFormInvalid) {
      this.totalDataFound = null;
      this.isLoading = false;
      this.isStartDownload = false;
      return ;
    }

    this.selectedDateRange.startDate = this.miscDateFG.get('startDate').value;
    this.selectedDateRange.endDate = this.miscDateFG.get('endDate').value;

    this.isLoading = true;
    this.totalDataFound = null;

    this.miscData = [];

    this.reportsService.downloadMiscReport(this.startDate, this.endDate)
      .pipe(
        tap(_ => this.miscDateFG.disable())
      ).subscribe({
        next: (res: IMiscChargeItemReport[]) => {
          this.miscData = res;
          this.totalDataFound = res.length;
          this.isLoading = false;
          this.miscDateFG.enable();

          this.currentReportName = this.reportsService.reportExcelFileName('misc', this.selectedDateRange);
        },
        error: (err: any) => {
          this.isLoading = false;
          this.miscDateFG.enable();
          console.error(err);
          this.openSnackBar('Failed to retrieve data from server', true);
        }
      })
  }

  downloadMiscReport(miscData: any[]): void {
    this.isLoading = true;
    this.reportsService.generateExcelFile(miscData, 'misc', this.selectedDateRange)
      .then(_ => {
        this.isLoading = false;
      }).catch((err) => {
        this.isLoading = false;
        this.miscDateFG.enable();
        console.error(err);
        this.openSnackBar('Failed to retrieve data from server', true);
      });
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
