import {Component, OnDestroy, OnInit} from '@angular/core';

import {ChargeSheetService} from '@services/chargesheet.service';
import {UserService} from '@services/user.service';

import {ChargeSheet} from '@models/chargesheet.model';

import {first} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {ChargeSheetErrorDialogComponent} from '@shared-components/charge-sheet-error-dialog/charge-sheet-error-dialog.component';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {Subscription} from 'rxjs';
import { ChargeSheetWarningDialogComponent } from '@shared-components/charge-sheet-warning-dialog/charge-sheet-warning-dialog.component';

@Component({
  templateUrl: './mydraft.component.html',
  styleUrls: ['./mydraft.component.scss']
})
export class MydraftComponent implements OnInit, OnDestroy {
  myDraftList: ChargeSheet[];
  fgError: FormGroup;
  loading: boolean;
  totalItem: number = null;
  autoRefreshList: Subscription;
  pageNumber = 1;

  startInterval: any;
  countdown = 0;

  correctionDialog: MatDialogRef<any>;
  warnDialog: MatDialogRef<any>;

  constructor(
    private chargeSheetService: ChargeSheetService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.getDraftList();
    this.startAutoRefreshTimer();
    this.fgError = this.formBuilder.group({
      mrn: ['', Validators.required],
      episodeNumber: ['', Validators.required]
    });
  }

  ngOnDestroy(): any {
    this.autoRefreshList.unsubscribe();
    clearInterval(this.startInterval);
  }

  refreshList(pageNumber?: number, isManual = false): void {
    if (isManual) {
      this.autoRefreshList.unsubscribe();
    }
    this.loading = true;
    this.myDraftList = [];
    this.getDraftList(pageNumber);
    this.delayRefreshButton();
  }

  startAutoRefreshTimer(): void {
    this.autoRefreshList = this.chargeSheetService.autoRefreshList()
      .subscribe({
        next: () => this.refreshList(this.pageNumber),
        error: (errMsg: string) => this.openSnackBar(errMsg, true)
      });
  }

  delayRefreshButton(): void {
    this.countdown = 5;
    this.startInterval = setInterval(() => {
      if (this.countdown === 0) {
        if (this.autoRefreshList.closed) {
          this.startAutoRefreshTimer();
        }
        clearInterval(this.startInterval);
        return;
      }
      this.countdown--;
    }, 1000);
  }

  getDraftList(pageNumber = 1): void {
    const currentUser = this.userService.currentUserValue;
    this.chargeSheetService.getDraftedChargeSheet(currentUser.id, pageNumber)
      .pipe(first())
      .subscribe({
        next: (res: HttpResponse<any>) => {
          this.totalItem = Number(res.headers.get('x-total-count'));
          const draftList = res.body;
          draftList.forEach((draftItem: any) => {
            draftItem.name = currentUser.name;
          });
          this.myDraftList = draftList;
          this.loading = false;
        },
        error: () => this.loading = false
      });
  }

  handleListPagination(pageIndex: number): void {
    this.pageNumber = pageIndex;
    this.getDraftList(pageIndex);
  }

  handleItemClick(event: { action: string, data: ChargeSheet, listType: any }): any {
    const EVENT_ACTION: string = event.action;
    let isDownload: boolean;

    switch(EVENT_ACTION) {
      case 'download':
        this.getChargeSheet(event.data, isDownload = true);
        break;
      case 'edit':
        this.getChargeSheet(event.data, isDownload = false);
        break;
      case 'duplicate':
        const CHARGE_SHEET: ChargeSheet = event.data;
        this.handleOpenWarnMsgDialog(CHARGE_SHEET);
        break;
    }
  }

  getChargeSheet(chargeSheet: ChargeSheet, isDownload: boolean): any {
    if (isDownload) {
      return this.chargeSheetService.getChargeSheet(chargeSheet.id, true)
        .pipe(first())
        .subscribe({
          next: (uri: string) => {
            return this.router.navigate([]).then(_ => window.open(uri, '_blank'));
          }
        });
    }

    if (chargeSheet.lastViewPath) {
      const splitStr = chargeSheet.lastViewPath.split('/');
      if (splitStr.length === 4) {
        return this.router.navigate([`/charge-sheet/my-draft/${chargeSheet.id}/${splitStr[3]}`]);
      }
      return this.router.navigate([chargeSheet.lastViewPath]);
    } else {
      return this.router.navigate([`/charge-sheet/my-draft/${chargeSheet.id}/marking-stage`]);
    }
  }

  duplicateChargeSheet(id: number): void {
    this.chargeSheetService.duplicateChargeSheet(id).subscribe({
      next: (res) => {
        this.refreshList(1, true);
      }
    });
  }

  handleOpenWarnMsgDialog(chargeSheet: ChargeSheet): void {
    const ID: number = chargeSheet.id;
    const EPISODE_NUMBER: string = chargeSheet.episodeNumber;

    const DIALOG_CONFIG: { [key: string]: any | any[] } = {
      maxHeight: '700px',
      minWidth: '600px',
      data: {
        warnType: ['duplicate'],
        contents: EPISODE_NUMBER
      }
    };

    this.warnDialog = this.matDialog.open(ChargeSheetWarningDialogComponent, DIALOG_CONFIG);

    this.warnDialog.afterClosed().subscribe({
      next: (isConfirm: boolean) => {
        if (isConfirm) {
          this.duplicateChargeSheet(ID);
        }
      }
    });
  }

  handleRemoveDraftItem({dialogComponent, id}): void {
    dialogComponent.afterClosed().subscribe({
      next: (isConfirm: boolean) => {
        if (isConfirm) {
          this.removeChargeSheet(id);
        }
      }
    });
  }

  removeChargeSheet(id: number) {
    this.loading = true;
    this.chargeSheetService.removeDraftedChargeSheet(id).subscribe({
      next: () => {
        this.getDraftList();
      }
    });
  }

  handleChargeSheetError({chargeSheet, index}: any): void {
    this.correctionDialog = this.matDialog.open(ChargeSheetErrorDialogComponent, {
      restoreFocus: false,
      disableClose: true,
      data: {
        type: 'draft',
        chargeSheet,
        index,
        ward: chargeSheet.ward
      }
    });

    this.correctionDialog.afterClosed()
      .pipe(first())
      .subscribe({
        next: (isSuccessUpdate: boolean) => {
          if (isSuccessUpdate) {
            this.myDraftList = [];
            this.loading = true;
            this.getDraftList();
          }
        }
      });
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
