import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {IConfiguration} from '@models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private apiSettingUrl: string = `api/configuration`;

  constructor(private httpClient: HttpClient) { }

  retrieveAllConfig(): Observable<IConfiguration[]> {
    return this.httpClient.get<IConfiguration[]>(`${this.apiSettingUrl}/list?type=S,C,E`);
  }

  updateApplicationCredential(username: string, newPassword: string): any {
    const TYPE: string = 'CREDENTIAL';
    const BODY = {
      id: 'CPLUS_CREDENTIAL',
      value: username,
      type: TYPE,
      newPassword
    };

    return this.httpClient.patch(`${this.apiSettingUrl}`, BODY);
  }

  updateMiscCharging(value: 'YES' | 'NO'): Observable<any> {
    const BODY = {
      id: 'MISC_CHARGING_ENABLE',
      value,
      type: 'SETTING'
    };

    return this.httpClient.patch(`${this.apiSettingUrl}`, BODY);
  }

  updateWeeklyMiscEmail(value: string): Observable<any> {
    const BODY = {
      id: 'REPORT_EMAIL',
      value: value,
      type: 'EMAIL'
    };
    return this.httpClient.patch(`${this.apiSettingUrl}`, BODY);
  }

  retrieveApplicationCredential(): Observable<any> {
    return this.httpClient.get(`${this.apiSettingUrl}/list?type=C`);
  }

  retrieveMiscCharging(): Observable<any> {
    return this.httpClient.get(`${this.apiSettingUrl}/list?type=S`);
  }
}
