import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserComponent } from './user/user.component';
import { ConfigurationComponent } from './configuration/configuration.component';

import { SettingGuard } from '@shared-helpers/setting.guard';

const settingsRoutes: Routes = [
  {
    path: '',
    redirectTo: '/charge-sheet/dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'users',
        component: UserComponent
      },
      {
        path: 'configuration',
        component: ConfigurationComponent
      }
    ],
    canActivate: [SettingGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(settingsRoutes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
