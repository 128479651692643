import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChargeWardQuantityDialog} from '@models/charge-ward-quantity.model';
import {ChargeSheetService} from '@services/chargesheet.service';
import {WardItemQuantity} from '@models/chargesheet.model';

@Component({
  template: `
    <div class="cmat-dialog-container">
      <div class="dialog-header">
        <h2 mat-dialog-title class="dialog-title text-icon">
          <mat-icon class="dialog-icon-title" color="warn">warning</mat-icon>
          <span class="dialog-text-title">Insufficient Quantity</span>
        </h2>
<!--        <h3>{{ chargeItem }}</h3>-->
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="fill">
            <mat-label>Charge Item</mat-label>
            <input matInput type="text" [value]="this.chargeItem" [matTooltip]="this.chargeItem" readonly/>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Charge Code</mat-label>
            <input matInput type="text" [value]="this.chargeCode" [matTooltip]="this.chargeCode" readonly/>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Quantity</mat-label>
            <input matInput type="number" [value]="oriQuantity" readonly/>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div mat-dialog-content>
        <h3>Charge To :</h3>
        <div class="cprogess-spinner-container" *ngIf="isLoading">
          <mat-progress-spinner class="cprogress-spinner" color="primary" mode="indeterminate">Loading</mat-progress-spinner>
          <h2>Loading ...</h2>
        </div>
        <div class="charge-ward-items" *ngIf="!isLoading">
          <div class="items" *ngFor="let item of wardItem; let i = index">
            <input type="radio" [id]="item.ward" name="ward" [checked]="item.ward === selectedWardItem.ward" (change)="handleRadioChange(item)"/>
            <label [for]="item.ward" [class.selected]="item.ward === selectedWardItem.ward">
              <span class="select-indicator"><mat-icon>check</mat-icon></span>
              <span class="item-ward">{{ item.ward }}</span>
              <span class="item-quantity" *ngIf="item.ward !== 'RESET' && item.ward !== 'MISC'">{{ item.quantity }}</span>
            </label>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div mat-dialog-actions class="dialog-actions" fxLayout="row wrap" fxLayoutAlign="space-between none">
        <button mat-raised-button mat-dialog-close color="basic" [matDialogClose]="false">Cancel</button>
        <button mat-raised-button mat-dialog-close color="primary" (click)="handleConfirmDialog()" [matDialogClose]="true" [disabled]="isLoading">Confirm</button>
      </div>
    </div>`,
  styleUrls: ['./charge-ward-quantity-dialog.scss']
})

export class ChargeWardQuantityDialogComponent implements OnInit {
  isLoading = true;
  wardItem: WardItemQuantity[] = [
    { ward: 'RESET', code: 'RESET', quantity: null, totalQuantity: null },
    { ward: 'MISC', code: 'MISC', quantity: null, totalQuantity: null }
  ];
  chargeCode: string = null;
  chargeItem: string = null;
  selectedWardItem: WardItemQuantity = { ward: null, code: null, quantity: null };
  oriQuantity: number = null;

  constructor(public dialogRef: MatDialogRef<ChargeWardQuantityDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: ChargeWardQuantityDialog,
              private chargeSheetService: ChargeSheetService) {
    this.chargeCode = data.chargeCode;
    this.chargeItem = data.chargeItem;
    if (data.wardItemQuantity && Object.keys(data.wardItemQuantity).length === 3) {
      this.selectedWardItem = data.wardItemQuantity;
      this.oriQuantity = data.chargeQuantity;
    }
  }

  ngOnInit() {
    this.getWardItemQuantity(this.chargeCode, this.oriQuantity);
  }

  getWardItemQuantity(chargeCode: string, quantity: number): void {
    this.chargeSheetService.getWardItemQuantity(chargeCode, quantity)
      .subscribe({
        next: (data: WardItemQuantity[]) => {
          this.wardItem.push(...data);
          for (const ward of data) {
            if (ward.code === this.selectedWardItem.code) {
              Object.assign(this.selectedWardItem, { totalQuantity: ward.quantity });
            }
          }
          this.isLoading = false;
        },
        error: () => this.isLoading = false
      });
  }

  handleChargeCodeChange(e): any {
    return this.selectedWardItem = e.option.value;
  }

  handleRadioChange(item: WardItemQuantity): void {
    this.selectedWardItem = item;
  }

  handleConfirmDialog(): void {
    Object.assign(this.selectedWardItem, { totalQuantity: this.selectedWardItem.totalQuantity });
    return this.dialogRef.close(this.selectedWardItem);
  }
}
