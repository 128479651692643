import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '@services/user.service';

import { UserpopupComponent } from '@shared-components/userpopup/userpopup.component';

@Component({
  selector: 'app-useravatar',
  templateUrl: './useravatar.component.html',
  styleUrls: ['./useravatar.component.scss']
})
export class UseravatarComponent implements OnInit, OnChanges {
  @Input() isSidebarClosed: boolean;
  userName: string;
  roleName: string;

  displayUsername: string;

  constructor(private userService: UserService, private matDialog: MatDialog, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.userService.currentUser
      .subscribe(user => {
        this.userName = user.name;
        this.roleName = user.role.name;
        this.displayUsername = user.name;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSidebarClosed) {
      this.isSidebarClosed = changes.isSidebarClosed.currentValue;
      if (this.userName) {
        const shorthandUsername = `${this.userName.split(' ')[0][0]} ${this.userName.split(' ')[1][0]}`;
        this.displayUsername = this.isSidebarClosed ? shorthandUsername : this.userName;
      }
      this.cdr.detectChanges();
    }
  }

  openUserDialog(userConfig: string) {
    const dialogRef = this.matDialog.open(UserpopupComponent, {
      width: '500px',
      data: {
        userDetail: this.userService.currentUserValue,
        userConfig
      }
    });
  }
}
