import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IReportRange } from '@models/reports.model';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

// import * as fs from 'file-saver';

@Injectable({providedIn: 'root'})
export class ReportsService {
  public applicationType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(private datePipe: DatePipe, private httpClient: HttpClient) { }

  downloadMiscReport(startDate: Date, endDate: Date): Observable<any> {
    const formattedStartDate: string = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    const formattedEndDate: string = this.datePipe.transform(endDate, 'yyyy-MM-dd');

    const BODY = {
      "startDate": formattedStartDate,
      "endDate": formattedEndDate
    };

    return this.httpClient.get(`api/report/misc?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
  }

  dateFormatStr(date: Date): string {
    const TODAY: Date = new Date();
    const DATE_STR: string = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
    const MONTH_STR: string = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
    const YEAR_STR: string = date.getFullYear().toString();

    return `${DATE_STR}${MONTH_STR}${YEAR_STR}`;
  }

  reportExcelFileName(reportType: string, reportDateRange: IReportRange): string {
    const FORMATTED_START_DATE: string = this.dateFormatStr(reportDateRange.startDate);
    const FORMATTED_END_DATE: string = this.dateFormatStr(reportDateRange.endDate);

    switch (reportType) {
      case 'misc':
        return `misc_charging_detail_${FORMATTED_START_DATE}_To_${FORMATTED_END_DATE}`;
      default:
        return 'wicharge_report';
    }
  }

  generateExcelFile(data: any[], reportType: string, reportDateRange: IReportRange): Promise<any> {
    if (data.length === 0) {
      return ;
    }

    // const FILE_SAVER: any = new FileSaver();
    const WORKBOOK = new Workbook();
    const WORKSHEET = WORKBOOK.addWorksheet('misc charging detail');

    const TODAY_DATE = new Date().toLocaleDateString();

    const HEADER = ['WARD', 'EPISODE NUMBER', 'CHARGE CODE', 'DESCRIPTION', 'QTY', 'REMARK', 'STATUS', 'CREATED AT', 'UPDATE AT'];
    const HEADER_ROW = WORKSHEET.addRow(HEADER);

    HEADER_ROW.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '33CCCC' },
        bgColor: { argb: '' }
      };

      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' }
      }
    });

    // Add Data
    data.forEach(d => {
      WORKSHEET.addRow([d.ward, d.episodeNumber, d.chargeCode, d.chargeItem, d.qty, d.remark, d.status, d.createdAt, d.updatedAt]);
    });

    WORKSHEET.getColumn(2).width = 18;
    WORKSHEET.getColumn(3).width = 22;
    WORKSHEET.getColumn(4).width = 80;
    WORKSHEET.getColumn(6).width = 47;
    WORKSHEET.getColumn(8).width = 27;
    WORKSHEET.getColumn(9).width = 27;
    WORKSHEET.addRow([])

    const REPORT_FILE_NAME: string = this.reportExcelFileName(reportType, reportDateRange);

    return WORKBOOK.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${REPORT_FILE_NAME}.xlsx`);
    }).catch((err) => err);
  }
}
