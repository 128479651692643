import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserpopupComponent } from '@shared-components/userpopup/userpopup.component';

import { User } from '@models/user.model';

import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { first, tap } from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  userList: User[];
  totalItem: number;
  currentPage = 1;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getUserListing();
  }

  getUserListing(pageNumber = 1) {
    this.userService.getAllUsers(pageNumber)
    .pipe(first())
    .subscribe((res: HttpResponse<any>) => {
      this.totalItem = Number(res.headers.get('x-total-count'));
      this.userList = res.body;
    });
  }

  handleListPagination(pageIndex: number): void {
    this.currentPage = pageIndex;
    this.getUserListing(pageIndex);
  }

  editUser(userDetail) {
    return this.openUserDialog('update user', userDetail);
  }

  deleteUser(userDetail) {
    return this.openUserDialog('delete user', userDetail);
  }

  openUserDialog(userConfig: string, userDetail?: User) {
    const dialogRef = this.matDialog.open(UserpopupComponent, {
      width: '500px',
      data: {
        userDetail: userDetail === undefined ? null : userDetail,
        userConfig
      }
    });

    dialogRef.afterClosed()
    .pipe(
      tap(_ => console.log('User Popup Closed, Updating User Listing...'))
    ).subscribe(result => this.getUserListing(this.currentPage));
  }

  handleItemClick(e) {
    const action = e.action;
    const userDetail = e.data;
    switch (action) {
      case 'edit':
        return this.editUser(userDetail);
      case 'delete':
        return this.deleteUser(userDetail);
    }
  }
}
