import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';

import {AuthService} from '@services/auth.service';
import {Router} from '@angular/router';
import {Route} from '@models/route.model';

import {Idle, NotIdle} from 'idlejs/dist';
import {tap, takeWhile} from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {UserService} from '@services/user.service';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  titledVersion: string = '';
  appVersion: string = '';
  appEnv: string = '';

  idle: Idle = new Idle();
  notIdle: NotIdle = new NotIdle();
  urlPrefix = '/charge-sheet';
  title = 'wicharge-web';
  mockRoute: Route[] = [
    {link: `${this.urlPrefix}/dashboard`, linkText: 'Dashboard', icon: 'dashboard_rounded', isDisabled: false},
    {link: `${this.urlPrefix}/unassigned`, linkText: 'Unassigned', icon: 'report', isDisabled: false},
    {link: `${this.urlPrefix}/my-draft`, linkText: 'Draft', icon: 'bookmark', isDisabled: false},
    {link: `${this.urlPrefix}/submitted-list`, linkText: 'In Queue', icon: 'hourglass_top_rounded', isDisabled: false},
    {link: `${this.urlPrefix}/submitted-list`, linkText: 'Completed / Archived', icon: 'done_outline_rounded', isDisabled: false},
    {link: `${this.urlPrefix}/submitted-list`, linkText: 'Attention', icon: 'warning_rounded', isDisabled: false},
    {link: `${this.urlPrefix}/submitted-list`, linkText: 'Search', icon: 'manage_search', isDisabled: false},
    {link: `/reports`, linkText: 'Reports', icon: 'assignment', isDisabled: false},
    {link: `/settings/users`, linkText: 'User Management', icon: 'supervisor_account', isDisabled: false},
    {link: `/settings/configuration`, linkText: 'Settings', icon: 'settings', isDisabled: false}
  ];
  currentRoute: string;
  isSidebarClosed: boolean;

  currentToken: string = null;
  currentFacility: string = null;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.titledVersion = this.appService.titledAppVersion;
    this.appVersion = this.appService.appVersion;
    this.appEnv = this.appService.appEnv;

    const requestPassRegex = new RegExp(/(\/auth\/(activate-account|request-password)\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12}))/g);
    this.authService.currentTokenInfo
      .pipe(
        tap(_ => console.log('Checking Authentication ...')),
        takeWhile(url => !requestPassRegex.test(window.location.pathname))
      ).subscribe({
      next: (tokenInfo = {token: null, expires: null}) => {
        this.currentToken = tokenInfo.token;
        if (tokenInfo.expires) {
          this.idle = this.initialUserInactivityCountdown();
          // this.notIdle = this.initialExpiresCountdown(tokenInfo.expires);
        } else {
          this.idle.stop();
          // this.notIdle.stop();
        }
      }
    });

    this.authService.currentFacility
      .subscribe({
        next: (newFacility: string) => {
          this.currentFacility = newFacility;
        }
      });
  }

  ngAfterViewInit(): void {
    this.appService.currentSideBar.subscribe({
      next: (value: boolean) => {
        this.isSidebarClosed = value;
        this.cdr.detectChanges();
      }
    })
  }

  logout() {
    this.authService.logout().subscribe({
      next: () => {
        this.appService.closeSideBar();
        this.router.navigate(['/auth/login']).then();
      }
    });
  }

  initialExpiresCountdown(expiresCountdown: number): NotIdle {
    return new NotIdle()
      .whenInteractive()
      .within(expiresCountdown - 10, 1000)
      .do(() => this.refreshToken())
      .start();
  }

  initialUserInactivityCountdown(): Idle {
    return new Idle()
      .whenNotInteractive()
      .within(15)
      .do(() => {
        this.openSnackBar('Logged out due to user inactivity.', true, 'info');
        this.logout();
      })
      .start();
  }

  private refreshToken() {
    const REFRESH_TOKEN = localStorage.getItem('refreshToken');
    if (this.router.url !== '/auth/login') {
      this.authService.refreshToken(REFRESH_TOKEN).subscribe({
        next: (data) => {

        },
        error: (err) => {
          console.error(err);
          if (err.indexOf('401') > -1) {
            this.openSnackBar('Session has expired, return to login page.', true, 'info');
            this.router.navigate(['/auth/login']).then(() => this.authService.removeAuth());
          }
        }
      });
    }
  }

  handleClickSidebar(sidebar: MatSidenav): any {
    const sideNavEl: HTMLElement = document.querySelector('mat-sidenav');
    const hasClosed: boolean = sideNavEl.classList.contains('is-closed');

    if (hasClosed) {
      this.appService.openSideBar();
    } else {
      this.appService.closeSideBar();
    }
  }

  private openSnackBar(message: string, isError: boolean, sbType: string) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: [`${sbType}-sb`]
    });
  }
}
