import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChargesheetRoutingModule } from './chargesheet-routing.module';
import { NewEntryComponent } from './newentry/newentry.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { MaterialModule } from './material.module';
import { CorrectComponent } from './lists/correct/correct.component';
import { ErrorComponent } from './lists/error/error.component';
import { MydraftComponent } from './lists/mydraft/mydraft.component';

import { SharedComponentsModule } from '@shared-components/shared-components.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MarkingstageComponent } from './markingstage/markingstage.component';
import { DataentrystageComponent } from './dataentrystage/dataentrystage.component';
import {SubmittedComponent} from './lists/submitted/submitted.component';
import {WaitingComponent} from './lists/submitted/waiting/waiting.component';
import {CompletedComponent} from './lists/submitted/completed/completed.component';
import {AttentionComponent} from './lists/submitted/attention/attention.component';
import {SearchChargeCodeDialogComponent} from '@shared-components/search-charge-code-dialog/search-charge-code-dialog.component';
import { ChartsModule } from 'ng2-charts';
import {UnassignedComponent} from './lists/unassgined/unassigned.component';

const ChargeSheetComponents = [
  NewEntryComponent,
  DashboardComponent,
  CorrectComponent,
  SubmittedComponent,
  WaitingComponent,
  CompletedComponent,
  AttentionComponent,
  ErrorComponent,
  MydraftComponent,
  MarkingstageComponent,
  DataentrystageComponent,
  SearchChargeCodeDialogComponent,
  UnassignedComponent
];

@NgModule({
  declarations: [
    ...ChargeSheetComponents
  ],
  imports: [
    CommonModule,
    ChargesheetRoutingModule,
    MaterialModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    PdfViewerModule,
    MaterialModule
  ],
  providers: [DatePipe]
})
export class ChargesheetModule { }
