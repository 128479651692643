import {
  Component,
  EventEmitter, HostListener,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges, ChangeDetectorRef,
  ViewChild, ElementRef, Renderer2
} from '@angular/core';
import {PDFDocumentProxy} from 'ng2-pdf-viewer';

import {ChargeSheetService} from '@services/chargesheet.service';
import {AppService} from '@services/app.service';

import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ChargeCode, ChargeSheet, IChargeSheetAndChecker} from '@models/chargesheet.model';
import {AttachmentDetail} from '@models/attachment.model';
import {ISelectedMarkerInputChange, MarkerCanvas, Marking, MarkingDetail, SelectedMarking, IItemInputChecker, IWarehouseQty, IMiscInfo} from '@models/marking.model';
import {delay, filter, first} from 'rxjs/operators';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {NGX_MAT_DATE_FORMATS, NgxMatDateFormats} from '@angular-material-components/datetime-picker';
import {environment} from '@proj-env/environment';
import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import * as moment from 'moment';

interface MarkCanvas {
  strokeRect?: boolean;
  getImageData?: boolean;
  fill?: boolean;
}

interface IAssignSelectedMarkerValue {
  border_color?: string,
  selectedMarkerId?: number,
  detail?: MarkingDetail[],
  quantity?: number,
  chargeItem?: string,
  chargeCode?: string,
  remark?: string,
  status: 'SUCCESS_WITH_MISC' | 'CHARGE_CODE_NOT_FOUND' | 'SUCCESS' | 'ERROR' | 'INSUFFICIENT_QUANTITY' | 'INVALID_CHARGE_TYPE' | 'DRAFT';
  chargeItemType: 'TIME' | 'QUANTITY' | 'PERCENTAGE';
  updatedAt: string;
  miscChargeCode: string | null;
  miscChargeItem: string | null;
  isChargeAsMisc: boolean;
}

interface IMarkerBoardDimension {
  width: number;
  height: number;
  x: number;
  y: number;
}

interface IAdjustMarkerCoordinate {
  adjustedX: number,
  adjustedY: number,
  adjustedWidth: number,
  adjustedHeight: number
}

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'L, LT',
  },
  display: {
    dateInput: 'L, LT',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-markerpanel',
  templateUrl: './markerpanel.component.html',
  styleUrls: ['./markerpanel.component.scss'],
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
  ]
})
export class MarkerpanelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputStage: string;
  @Input() buttonAction: string;
  @Input() chargeSheetId: number;
  @Input() isReadOnly: boolean;
  @Input() isUpdatingCS: boolean;
  @Output() handleChargeSheetLoad: EventEmitter<IChargeSheetAndChecker> = new EventEmitter();
  @Output() handleMarkingChange: EventEmitter<any> = new EventEmitter();
  @Output() handleErrorCorrected: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleDisableMarker: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleMiscChargeItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('markerPanel') markerPanelEl: ElementRef;

  private proxyPdfUrl: string = environment.proxy_pdf_url;
  loading = false;
  isHighlightClicked = false;
  isStartMarker = false;
  isSubmitted = false;
  currentWard: string = null;
  oriBtnManualMarker: ClientRect = null;
  zoomValue = 1;
  chargeSheetStatus: string;
  dateToCharge: string;

  private pdfViewCanvasSubject: BehaviorSubject<HTMLCanvasElement>;
  private onPdfViewCanvasChange: Observable<HTMLCanvasElement>;
  currentPageSubject: BehaviorSubject<number>;
  onCurrentPageChange: Observable<number>;
  pdfOriSizes: any = {};
  itemInputCheckerById: Map<number, IItemInputChecker> = new Map();

  markerCoor = {};
  markingsSelected: SelectedMarking[] = [];
  markerCanvasProperties: MarkerCanvas[] = [];
  markerCanvasProperty = {
    width: null,
    height: null,
    color: null,
    nativeElement: null,
    context2D: null,
    offsetX: null,
    offsetY: null
  };

  currentMarkerLocation = {
    width: null,
    height: null,
    clientX: null,
    clientY: null,
    startX: null,
    startY: null
  };

  manualMarkerProperty = {
    x: null,
    y: null,
    width: null,
    height: null,
    cropped_img: null
  };

  currentDate: Date = new Date();
  pdfFileName: string;
  currentPage = 1;
  currentPageIndex = 0;
  totalPdfPages: number;
  totalSelectedMarker = 0;
  totalChargeCodeNotFound = 0;
  pagesArray: number[] = [];
  pagesContainedMarker: any[];
  pagesSelectedMarker: any = {};
  pageMarkerErrors: object = {};

  openPreviewMarkerPanel: boolean = false;

  disabledMarkerIdByPageNum: Map<number, number[]> = new Map();

  selectedFocusMarker: string = null;

  miscChargeCodeListSub: Subscription;
  miscChargeCodeList: ChargeCode[] = [];
  warehouseQtyBySelectedChargeId: Map<number, { [key: string]: any }> = new Map();
  chargeItemAsMiscBySelectedChargeId: Map<number, IMiscInfo> = new Map();

  isDefaultQtyEnabled: boolean = false;

  moment: NgxMatMomentAdapter = new NgxMatMomentAdapter('en-GB');

  currentCoorByChargeCode: Map<string, IAdjustMarkerCoordinate> = new Map<string, IAdjustMarkerCoordinate>();

  public get isContainedMarkerHalfSize(): boolean {
    if (this.inputStage !== 'marking' || Object.entries(this.markerCoor).length === 0) {
      return false;
    }

    const CURRENT_MARKINGS: Marking[] = this.markerCoor[this.currentPage];
    const FILTERED_HALF_SIZE: Marking[] = CURRENT_MARKINGS.filter((v) => v.marking_type === 'PRE_RECORDED_DM_HALF');

    return FILTERED_HALF_SIZE.length > 0;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const MARKER_ACTION_EL: HTMLElement = document.querySelector('.action-button-container');

    const MARKER_PANEL_EL = document.querySelector('.marker-panel');
    const ng2PdfViewerEl = document.querySelector('.ng2-pdf-viewer-container');

    if (MARKER_PANEL_EL.scrollTop > MARKER_ACTION_EL.getBoundingClientRect().top) {
      MARKER_ACTION_EL.style.top = `${MARKER_PANEL_EL.scrollTop}px`;
    } else {
      MARKER_ACTION_EL.style.top = 'initial';
    }

    if (MARKER_PANEL_EL.scrollLeft > 0) {
      ng2PdfViewerEl.scrollTo({ left: MARKER_PANEL_EL.scrollLeft, behavior: 'auto' });
      MARKER_ACTION_EL.style.right = `${5 - MARKER_PANEL_EL.scrollLeft}px`;
    } else {
      MARKER_ACTION_EL.style.right = `5px`;
    }
  }

  constructor(private appService: AppService, private chargeSheetService: ChargeSheetService, private cdr: ChangeDetectorRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.isSubmitted = this.inputStage.indexOf('submitted') > -1;
    this.loading = true;
    this.currentPageSubject = new BehaviorSubject<number>(1);
    this.onCurrentPageChange = this.currentPageSubject.asObservable();
    this.pdfViewCanvasSubject = new BehaviorSubject<HTMLCanvasElement>(null);
    this.onPdfViewCanvasChange = this.pdfViewCanvasSubject.asObservable();

    if (this.inputStage === 'marking') {
      this.appService.closeSideBar();
    }

    if (this.inputStage === 'marking' || this.inputStage === 'data-entry') {
      this.getChargeSheet();
    } else {
      this.getSubmittedChargeSheet();
    }

    this.onPdfViewCanvasChange
      .pipe(filter((val) => {
        return val !== null || val !== this.CurrentPdfViewCanvas.canvasValue;
      }))
      .subscribe({
        next: (updatedCanvas) => {
          this.markerCanvasProperties[this.currentPageIndex].nativeElement = document.querySelector('#marker-board');
          this.markerCanvasProperties[this.currentPageIndex].context2D =
            this.markerCanvasProperties[this.currentPageIndex].nativeElement.getContext('2d');
          this.handlePdfPageChange(this.currentPage, this.markerCanvasProperties[this.currentPageIndex].context2D, this.markerCoor);
        }
      });

      this.miscChargeCodeListSub = this.chargeSheetService.searchMiscItem().subscribe({
        next: (miscList: ChargeCode[]) => {
          this.miscChargeCodeList = miscList.filter((misc: ChargeCode) => misc.status === 'ACTIVE');
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (Object.keys(changes).indexOf('buttonAction') > -1 && changes.buttonAction.currentValue === 'draft') {
      for (let i = 1; i < this.totalPdfPages; i++) {
        const disabledMarkerIds: number[] = this.disabledMarkerIdByPageNum.get(i);

        if (disabledMarkerIds) {
          for (const markerId of disabledMarkerIds) {
            this.pagesSelectedMarker[i].filter((v, index) => {
              if (v.id === markerId) {
                this.pagesSelectedMarker[i].splice(index, 1);
              }
            });
          }
        }
      }
      this.handleResetSelectedMarker();
      this.handleMarkingChange.emit(this.pagesSelectedMarker);
      return;
    }

    if (changes.isUpdatingCS) {
      this.loading = changes.isUpdatingCS.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.currentPageSubject.unsubscribe();
    this.pdfViewCanvasSubject.unsubscribe();
    this.markerCanvasProperties = [];
    this.pagesSelectedMarker = {};
    this.markerCoor = {};

    if (this.miscChargeCodeListSub) {
      this.miscChargeCodeListSub.unsubscribe();
    }

    this.chargeSheetService.chargeSheet = null;
  }

  assignWarehouseQty(markingSelectedWarehouseQty: IWarehouseQty[]): any {
    markingSelectedWarehouseQty.forEach((detail: IWarehouseQty) => {
      const CODE_WAREHOUSE_QTY = { code: detail.chargeCode, warehouseQty: detail.warehouseQty }
      this.warehouseQtyBySelectedChargeId.set(detail.chargeSheetMarkingSelectedId, CODE_WAREHOUSE_QTY);
    });
  }

  handleResetSelectedMarker(): void {
    this.loading = true;
    const storePageSelectedMarker = this.pagesSelectedMarker;
    this.pagesSelectedMarker = {};
    setTimeout(() => {
      this.pagesSelectedMarker = storePageSelectedMarker;
      this.resetAllMarker();
      this.loading = false;
    }, 500);
  }

  getChargeSheet(): void {
    this.chargeSheetService.getChargeSheet(this.chargeSheetId)
      .pipe(first())
      .subscribe({
        next: (chargeSheet: ChargeSheet) => {
          this.assignChargeSheetMarker(chargeSheet);
        },
        error: (errMsg: string) => console.error(errMsg)
      });
  }

  getSubmittedChargeSheet(): void {
    this.chargeSheetService.getSubmittedChargeSheet(this.chargeSheetId)
      .pipe(first())
      .subscribe({
        next: (chargeSheet: ChargeSheet) => {
          this.assignChargeSheetMarker(chargeSheet, true);
        },
        error: (errMsg: string) => console.error(errMsg)
      });
  }

  assignChargeSheetMarker(chargeSheet: ChargeSheet, isSubmitted: boolean = false): void {
    if (this.inputStage.indexOf('data-entry') > -1) {
      this.isDefaultQtyEnabled = chargeSheet.defaultQtyEnable;
    }

    this.chargeSheetService.chargeSheet = chargeSheet;
    this.pdfFileName = `data:application/pdf;base64,${chargeSheet.attachment.fileBase64}`;
    this.currentWard = chargeSheet.ward;
    this.pagesContainedMarker = chargeSheet.markings;
    this.pdfOriSizes = this.setPdfOriSize(chargeSheet.attachment.details);
    this.markingsSelected = chargeSheet.markingsSelected.length > 0 ? chargeSheet.markingsSelected : [];
    this.chargeSheetStatus = chargeSheet.status;
    this.dateToCharge = chargeSheet.chargeDate;
    this.assignWarehouseQty(chargeSheet.warehouseDetails);
    this.handleChargeSheetLoad.emit({data: chargeSheet, ItemInputCheckerById: this.itemInputCheckerById});
  }

  setPdfOriSize(attchDetails: AttachmentDetail[]): any {
    const newObj = {};
    for (const detail of attchDetails) {
      Object.assign(newObj, {[detail.page]: {width: detail.pageWidth, height: detail.pageHeight}});
    }
    return newObj;
  }

  setPdfMarkerPages(markers: Marking[], totalPages: number = null): any {
    const newMarkerObj = {};
    if (totalPages) {
      for (let i = 1; i <= totalPages; i++) {
        newMarkerObj[i] = [];
        Object.assign(this.pagesSelectedMarker, {[i]: []});
      }
    }
    for (const marker of markers) {
      if (!newMarkerObj[marker.page]) {
        newMarkerObj[marker.page] = [];
        Object.assign(this.pagesSelectedMarker, {[marker.page]: []});
      }
      newMarkerObj[marker.page].push(marker);
      // Auto Assign Barcode Sticker Items
      if (this.markingsSelected.length > 0) {
        const filterMarker: SelectedMarking[] = this.markingsSelected.filter((selectedMarker) => {
          return (selectedMarker.chargeCode === marker.code) &&
            (selectedMarker.x_axis === marker.x_axis) && (selectedMarker.y_axis === marker.y_axis)
            && marker.border_color !== 'red' && selectedMarker.page === marker.page;
        });
        if (filterMarker.length > 0) {
          const {detail, quantity, chargeItem, chargeCode, remark, status, chargeItemType, updatedAt, isChargeAsMisc, miscChargeCode, miscChargeItem}: IAssignSelectedMarkerValue = this.assignSelectedMarkerValue(filterMarker[0]);
          const selectedMarkerBody: SelectedMarking = JSON.parse(JSON.stringify(filterMarker[0]));
          selectedMarkerBody.detail = detail;
          selectedMarkerBody.quantity = quantity;
          selectedMarkerBody.remark = remark;
          selectedMarkerBody.status = status;
          selectedMarkerBody.chargeCode = chargeCode;
          selectedMarkerBody.chargeItem = chargeItem;
          selectedMarkerBody.updatedAt = updatedAt;
          selectedMarkerBody.isChargeAsMisc = isChargeAsMisc;
          selectedMarkerBody.miscChargeCode = miscChargeCode;
          selectedMarkerBody.miscChargeItem = miscChargeItem;
          selectedMarkerBody.marking_type = marker.marking_type;

          if (selectedMarkerBody.chargeItemType !== chargeItemType) {
            selectedMarkerBody.chargeItemType = chargeItemType;
          }

          marker.code = chargeCode;
          marker.items = chargeItem;

          if (this.isSubmitted) {
            this.setMarkerRemark(remark, selectedMarkerBody.id, selectedMarkerBody);
          }

          if (this.inputStage.indexOf('data-entry') > -1) {
            this.setMarkerRemark('', selectedMarkerBody.id, selectedMarkerBody);
          }

          if (miscChargeCode) {
            this.chargeItemAsMiscBySelectedChargeId.set(selectedMarkerBody.id, { code: miscChargeCode, desc: miscChargeItem });
          }

          this.totalSelectedMarker++;
          this.pagesSelectedMarker[marker.page].push(selectedMarkerBody);
        }
      } else {
        if (marker.border_color === 'orange') {
          this.totalSelectedMarker++;
          this.pagesSelectedMarker[marker.page].push(this.setSelectedMarkerProperty(marker, this.inputStage));
        }
      }
    }
    this.assignManualSelectedMarker(this.markingsSelected, newMarkerObj);

    if (this.inputStage.indexOf('attention') > -1 || this.inputStage.indexOf('data-entry') > -1) {
      this.handleErrorCorrected.emit(this.itemInputCheckerById);
    }
    return newMarkerObj;
  }

  assignManualSelectedMarker(selectedMarkings: SelectedMarking[], newMarkerCoor: any): any {
    for (const marker of selectedMarkings) {
      if (marker.border_color === 'red') {
        const selectedMarker: SelectedMarking = JSON.parse(JSON.stringify(marker));
        const {quantity, chargeItem, chargeCode, remark, status, chargeItemType, updatedAt, isChargeAsMisc, miscChargeCode, miscChargeItem} = this.assignSelectedMarkerValue(selectedMarker);
        selectedMarker.chargeItem = chargeItem;
        selectedMarker.chargeCode = chargeCode;
        selectedMarker.quantity = quantity;
        selectedMarker.remark = remark;
        selectedMarker.status = status;
        selectedMarker.chargeItemType = chargeItemType;
        selectedMarker.updatedAt = updatedAt;
        selectedMarker.isChargeAsMisc = isChargeAsMisc;
        selectedMarker.miscChargeCode = miscChargeCode;
        selectedMarker.miscChargeItem = miscChargeItem;

        if (remark && this.isSubmitted) {
          this.setMarkerRemark(remark, selectedMarker.id, selectedMarker);
        }

        if (this.inputStage.indexOf('data-entry') > -1) {
          this.setMarkerRemark('', selectedMarker.id, selectedMarker);
        }

        if (!this.pagesSelectedMarker[selectedMarker.page]) {
          Object.assign(this.pagesSelectedMarker, {[selectedMarker.page]: []});
        }

        if (!newMarkerCoor[selectedMarker.page]) {
          Object.assign(newMarkerCoor, {[selectedMarker.page]: []});
        }
        this.totalSelectedMarker++;
        this.pagesSelectedMarker[selectedMarker.page].push(selectedMarker);
        newMarkerCoor[selectedMarker.page].push(selectedMarker);
      }
    }
    return;
  }

  setMarkerRemark(remark: string, markerId: number, marker?: SelectedMarking): Map<number, object> {
    const DEFAULT_DATA = {code: true, quantity: true};
    const MARKER_STATUS = marker.status;
    const IS_CHARGE_CODE_NOT_FOUND: boolean =
      (marker.searchCode === 'YES' && marker.border_color === 'blue') || !marker.chargeItem;
    DEFAULT_DATA.code = !!marker.chargeCode && MARKER_STATUS !== 'CHARGE_CODE_NOT_FOUND';
    DEFAULT_DATA.quantity = !!marker.quantity && MARKER_STATUS !== 'INSUFFICIENT_QUANTITY' && marker.quantity >= 0;

    if (MARKER_STATUS === 'CHARGE_CODE_NOT_FOUND' || IS_CHARGE_CODE_NOT_FOUND) {
      this.totalChargeCodeNotFound++;
    }

    if (marker && marker.chargeItemType === 'TIME') {
      const markerDetail: any[] = [];
      for (const detail of marker.detail) {
        markerDetail.push({startTime: !!detail.startTime, endTime: !!detail.endTime});
      }
      delete DEFAULT_DATA.quantity;
      Object.assign(DEFAULT_DATA, {details: markerDetail});
    }

    if (MARKER_STATUS === 'ERROR' || MARKER_STATUS === 'INVALID_CHARGE_TYPE') {
      Object.assign(DEFAULT_DATA, { isUnavailable: true });
    }

    // When Charge Item Type is TIME
    if (marker.chargeItemType === 'TIME' && marker.detail.length === 0) {
      const markerDetail: any[] = [{ startTime: false, endTime: false }];
      delete DEFAULT_DATA.quantity;
      Object.assign(DEFAULT_DATA, { details: markerDetail });
    }

    this.setTabMarkerErrorDisplayNumber(markerId, DEFAULT_DATA, marker.page, marker);
    return this.itemInputCheckerById.set(markerId, DEFAULT_DATA);

    // if (lowercase.indexOf('invalid charge type') > -1) {
    //   console.log('[DEBUGGING] SET MARKER REMARK - INVALID CHARGE TYPE');
    //   Object.assign(this.correctedMarkerList.get(markerId), { chargeType: false });
    // }
  }

  setTabMarkerErrorDisplayNumber(
    markerId: number,
    inputChecker: { code: boolean, details?: Array<{ startTime: boolean, endTime: boolean }>, quantity?: boolean, isUnavailable?: boolean },
    pageNumber: number,
    marker: SelectedMarking): any {
    if (this.inputStage.indexOf('attention') < 0 && this.inputStage !== 'data-entry') {
      return ;
    }

    if (!this.pageMarkerErrors[pageNumber]) {
      Object.assign(this.pageMarkerErrors, {[pageNumber]: []});
    }

    if (inputChecker.isUnavailable) {
      return this.pageMarkerErrors[pageNumber].push(false);
    }

    if (inputChecker.details && marker.chargeItemType === 'TIME') {
      const INPUT_CHECKER_CODE = inputChecker.code;
      for (const detail of inputChecker.details) {
        const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm';
        const START_TIME_MOMENT: moment.Moment = detail.startTime ? moment(marker.detail[0].startTime, MOMENT_FORMAT) : null;
        const END_TIME_MOMENT: moment.Moment = detail.endTime ? moment(marker.detail[0].endTime, MOMENT_FORMAT) : null;
        let inputCheckerDetail: boolean = (detail.startTime && detail.endTime);
        let isStartAndEndTimeSame: boolean = false;

        if (START_TIME_MOMENT && END_TIME_MOMENT) {
          isStartAndEndTimeSame = this.moment.compareDateWithTime(START_TIME_MOMENT, END_TIME_MOMENT, false) === 0;
          inputCheckerDetail = !isStartAndEndTimeSame;
        }

        this.pageMarkerErrors[pageNumber].push(INPUT_CHECKER_CODE && inputCheckerDetail);
      }
      return ;
    }
    this.pageMarkerErrors[pageNumber].push(inputChecker.code && inputChecker.quantity);
  }

  assignSelectedMarkerValue(selectedMarking: SelectedMarking): IAssignSelectedMarkerValue {
    let detailChargeItem: string = selectedMarking.chargeItem;
    let detailChargeCode: string = selectedMarking.chargeCode;
    let detailItemQuantity: number = null;

    let detailStatus: 'SUCCESS_WITH_MISC' | 'CHARGE_CODE_NOT_FOUND' | 'SUCCESS' | 'ERROR' | 'INSUFFICIENT_QUANTITY' | 'INVALID_CHARGE_TYPE' | 'DRAFT' = null;
    let detailRemark: string = null;

    let chargeItemType: 'TIME' | 'QUANTITY' | 'PERCENTAGE' = selectedMarking.chargeItemType;

    let chargeSheetDetails = [];
    let detailDate: Date = null;

    let isChargeAsMisc: boolean = false;
    let miscChargeCode: string = null;
    let miscChargeItem: string = null;

    if (selectedMarking.detail.length > 0) {
      const MARKING_DETAIL: MarkingDetail[] = selectedMarking.detail;
      for (const detail of MARKING_DETAIL) {
        let date: Date = null;

        if (detail.startTime) {
          date = new Date(detail.startTime);
        }

        if (detail.endTime) {
          date = new Date(detail.endTime);
        }

        if (detail.chargeItemType) {
          chargeItemType = detail.chargeItemType;
        }

        isChargeAsMisc = !!detail.miscChargeCode && !!detail.miscChargeItem;
        miscChargeCode = detail.miscChargeCode;
        miscChargeItem = detail.miscChargeItem;

        detailDate = date;
        detailStatus =  detail.status;
        detailRemark = detail.remark !== null ? detail.remark : detail.status;

        const CHARGE_TYPE_TIME_AND_START_OR_END_TIME_HAS_VALUE: boolean = detail.chargeItemType === 'TIME' || (detail.startTime || detail.endTime);

        if (CHARGE_TYPE_TIME_AND_START_OR_END_TIME_HAS_VALUE) {
          chargeSheetDetails.push(detail);
        }
      }
    }

    if (selectedMarking.detail.length > 0 && (selectedMarking.searchCode === 'YES' || this.inputStage === 'data-entry')) {
      detailChargeItem = selectedMarking.detail[0].chargeItem;
      detailChargeCode = selectedMarking.detail[0].chargeCode;
    }

    if ((selectedMarking.detail.length > 0 && selectedMarking.detail[0].quantity !== null)) {
      detailItemQuantity = selectedMarking.detail[0].quantity;
    }

    if (detailItemQuantity || !detailDate) {
      chargeSheetDetails = [];
    }

    return {
      border_color: selectedMarking.border_color,
      selectedMarkerId: selectedMarking.id,
      detail: chargeSheetDetails,
      quantity: detailItemQuantity,
      chargeItem: detailChargeItem,
      chargeCode: detailChargeCode,
      status: detailStatus,
      remark: detailRemark,
      chargeItemType: chargeItemType,
      updatedAt: selectedMarking.updatedAt,
      isChargeAsMisc,
      miscChargeCode,
      miscChargeItem
    };
  }

  onPdfRendered(pdfViewCustomEvent) {
    const pdfViewerViewport: any = pdfViewCustomEvent.source.viewport;
    const pageNumber: number = pdfViewCustomEvent.pageNumber;
    this.loading = false;
    this.markerCanvasProperties[pageNumber - 1].width = pdfViewerViewport.width;
    this.markerCanvasProperties[pageNumber - 1].height = pdfViewerViewport.height;

    // Note : Pdf.js will always initiate 2 pages
    // Assign Marker Canvas Property
    this.markerCanvasProperty = this.markerCanvasProperties[this.currentPageIndex];
    this.pdfViewCanvasSubject.next(document.querySelector(`canvas[aria-label='Page ${this.currentPage}']`));
  }

  onPdfLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPdfPages = pdf.numPages;
    this.markerCoor = this.setPdfMarkerPages(this.pagesContainedMarker, this.totalPdfPages);
    // Remove undefined from CorrectedMarkerList<Map>
    this.itemInputCheckerById.delete(undefined);
    for (let i = 1; i <= pdf.numPages; i++) {
      this.pagesArray.push(i);
      this.markerCanvasProperties.push({
        width: null,
        height: null,
        nativeElement: null,
        color: null,
        context2D: null,
        offsetX: null,
        offsetY: null
      });
    }
  }

  private get CurrentPdfViewCanvas() {
    return {
      canvasValue: this.pdfViewCanvasSubject.getValue(),
      context2D: this.pdfViewCanvasSubject.getValue() ? this.pdfViewCanvasSubject.getValue().getContext('2d') : null,
      pageNumber: this.currentPage
    };
  }

  startMarking(markerType: string) {
    this.isStartMarker = true;
    this.isHighlightClicked = false;
    this.markerCanvasProperty = this.markerCanvasProperties[this.currentPageIndex];
    this.markerCanvasProperty.nativeElement.style.cursor = 'crosshair';
    this.markerCanvasProperty.color = 'red';
    this.markerCanvasProperty.context2D.strokeStyle = 'red';
    this.markerCanvasProperty.context2D.lineWidth = 3;
    this.currentMarkerLocation.width = this.markerCanvasProperty.width - 40;
    this.currentMarkerLocation.height = 25;

    const markerPreviewCanvas: HTMLCanvasElement = document.querySelector('#marked-canvas');
    const mCanvasCtx = markerPreviewCanvas.getContext('2d');

    if (markerType === 'sticker') {
      this.currentMarkerLocation.width = 175;
      this.currentMarkerLocation.height = 60;
    }

    if (markerType === 'default-half') {
      this.currentMarkerLocation.width = 378.92;
    }

    this.markerCanvasProperty.offsetX = this.markerCanvasProperty.nativeElement.getBoundingClientRect().left;
    this.markerCanvasProperty.offsetY = this.markerCanvasProperty.nativeElement.getBoundingClientRect().top;

    mCanvasCtx.clearRect(0, 0, this.markerCanvasProperty.width, this.markerCanvasProperty.height);
    this.markerPanelClassName(this.isStartMarker);
  }

  navigateTabPages(tabIndex: number): any {
    if (!this.loading) {
      this.currentPage = tabIndex + 1;

      this.currentPageIndex = tabIndex;
      this.currentPageSubject.next(this.currentPage);
      this.handleCurrentPageChange();
    }
  }

  handlePreviousNextPagination(btnClickValue: 'previous' | 'next'): void {
    if ((this.currentPage <= 1 && btnClickValue === 'previous') || (this.currentPage === this.totalPdfPages && btnClickValue === 'next')) {
      return ;
    }

    switch(btnClickValue) {
      case 'previous':
        this.currentPage -= 1;
        break;
      case 'next':
        this.currentPage += 1;
        break;
    }

    this.selectedFocusMarker =  null;
    this.currentPageIndex = this.currentPage - 1;
    this.currentPageSubject.next(this.currentPage);
    this.handleCurrentPageChange();
  }

  handleCurrentPageChange(): any {
    this.onCurrentPageChange
      .pipe(
        delay(100),
        first()
      ).subscribe({
      next: (pageNumber) => {
        this.pdfViewCanvasSubject.next(document.querySelector(`canvas[aria-label='Page ${pageNumber}']`));
      }
    });
  }

  handlePdfPageChange(pdfPage: number, markerCtx: CanvasRenderingContext2D, coorObj: { [key: number]: Marking[] | SelectedMarking[] }) {
    markerCtx.clearRect(0, 0,
      this.markerCanvasProperties[this.currentPageIndex].width, this.markerCanvasProperties[this.currentPageIndex].height);
    this.applyMarker(pdfPage, markerCtx, coorObj);
    return;
  }

  applyMarker(
    pdfPage: number, markerCtx: CanvasRenderingContext2D,
    coorObj: Marking | SelectedMarking | { [key: number]: Marking[] | SelectedMarking[] }, markerAction: string = 'barcode', isMouseClick: boolean = false): any {
    markerCtx.lineWidth = 3;
    if (!Object.keys(coorObj).length) {
      return;
    }
    for (const marker of coorObj[pdfPage]) {
      // Unmark marker border color
      markerCtx.strokeStyle = !this.isReadOnly ? '#69F0AE' : '#FFFFFFw00';
      const {adjustedX, adjustedY, adjustedWidth, adjustedHeight}: IAdjustMarkerCoordinate =
        this.adjustMarkerCoordinate(marker.x_axis, marker.y_axis, marker.marker_width, marker.marker_height);
      if (marker.id && markerAction === 'barcode' && marker.border_color !== 'red') {
        const filterSelectedMarker =
          this.pagesSelectedMarker[this.currentPage].filter((selectedMarker: SelectedMarking) => {
            const markerCode = isMouseClick ? (marker as SelectedMarking).chargeCode : (marker as Marking).code;
            return (markerCode === selectedMarker.chargeCode)
              && (marker.border_color === selectedMarker.border_color)
              && ((marker.y_axis === selectedMarker.y_axis) && (marker.x_axis === selectedMarker.x_axis));
          });
        if (filterSelectedMarker.length > 0) {
          marker.border_color === 'blue' || marker.border_color === 'purple' ?
            this.preRecordedMarker(filterSelectedMarker[0], adjustedY, markerCtx) :
            this.defaultMarker(filterSelectedMarker[0],
              {x: adjustedX, y: adjustedY, width: adjustedWidth, height: adjustedHeight}, markerCtx, true);
        } else {
          if (this.inputStage === 'marking') {
            this.defaultMarker(marker,
              {x: adjustedX, y: adjustedY, width: adjustedWidth, height: adjustedHeight},
              markerCtx);
          }
        }
      }

      if (markerAction === 'preRecorded' && (marker.border_color === 'blue' || marker.border_color === 'purple')) {
        this.preRecordedMarker(marker, adjustedY, markerCtx);
      }

      if (marker.border_color === 'red') {
        this.manualMarker(marker,
          {x: marker.x_axis, y: marker.y_axis, width: marker.marker_width, height: marker.marker_height},
          markerCtx);
      }
    }

    // Reset Preview Barcode Canvas
    this.previewMarkCanvas(1, 1, 1, 1);
    this.handleMarkingChange.emit(this.pagesSelectedMarker);
  }

  defaultMarker(marker: Marking | SelectedMarking,
                markerDetail = {x: null, y: null, width: null, height: null},
                markerCtx: CanvasRenderingContext2D,
                isBarcodeSticker: boolean = false, isRemove: boolean = false): any {
    this.markerCanvasProperties[this.currentPageIndex].nativeElement = document.querySelector('#marker-board');
    const markerBoardSize: ClientRect = this.markerCanvasProperties[this.currentPageIndex].nativeElement.getBoundingClientRect();
    const barcodeStickerSize = {x: markerDetail.x, y: markerDetail.y, width: markerDetail.width, height: markerDetail.height};

    if (markerBoardSize.width === 0 && markerBoardSize.height === 0) {
      setTimeout(() => {
        this.recallMarker({marker, markerDetail, markerCtx, isBarcodeSticker, isRemove});
      }, 100);
    }

    if (isBarcodeSticker) {
      markerCtx.strokeStyle = 'orange';
      markerCtx.lineWidth = 3;
      barcodeStickerSize.y = markerDetail.y - 5;
      barcodeStickerSize.x = markerDetail.x - 5;
      barcodeStickerSize.width = (markerBoardSize.width * 0.21);
      barcodeStickerSize.height = (markerBoardSize.height * 0.06);
      this.fillMarkerStyle('orange', barcodeStickerSize, markerCtx);
    }

    if (isRemove) {
      return markerCtx.clearRect(barcodeStickerSize.x - 2, barcodeStickerSize.y - 2,
        barcodeStickerSize.width + 5, barcodeStickerSize.height + 5);
    }

    if (barcodeStickerSize.width > 0 && barcodeStickerSize.height > 0) {
      this.previewMarkCanvas(barcodeStickerSize.x, barcodeStickerSize.y, barcodeStickerSize.width, barcodeStickerSize.height);
      Object.assign(marker, {
        cropped_img: this.getImgMarkedCanvas(
          barcodeStickerSize.x, barcodeStickerSize.y,
          barcodeStickerSize.width, barcodeStickerSize.height),
        adjustedWidth: barcodeStickerSize.width,
        adjustedHeight: barcodeStickerSize.height
      });
    }

    return markerCtx.strokeRect(barcodeStickerSize.x, barcodeStickerSize.y, barcodeStickerSize.width, barcodeStickerSize.height);
  }

  preRecordedMarker(marker: Marking | SelectedMarking,
                    markerY: number,
                    markerCtx: CanvasRenderingContext2D,
                    isRemove: boolean = false): any {
    this.markerCanvasProperties[this.currentPageIndex].nativeElement = document.querySelector('#marker-board');
    const markerBoardSize: ClientRect = this.markerCanvasProperties[this.currentPageIndex].nativeElement.getBoundingClientRect();
    let preRecordedMarkerSize = {x: 15, y: markerY, width: markerBoardSize.width - 30, height: markerBoardSize.height * 0.023};
    markerCtx.strokeStyle = marker.border_color === 'purple' ? '#9575cd' : marker.border_color;

    const adjustedMarkerValue: IAdjustMarkerCoordinate = this.adjustMarkerCoordinate(marker.x_axis, marker.y_axis, marker.marker_width, marker.marker_height);
    const IS_MARKER_HALF_SIZE: boolean = marker.marking_type === "PRE_RECORDED_DM_HALF";

    if (isRemove) {
      if (IS_MARKER_HALF_SIZE) {
        const { adjustedX, adjustedY, adjustedWidth, adjustedHeight }: IAdjustMarkerCoordinate = this.adjustHalfSizeMarker(adjustedMarkerValue, markerCtx);

        return markerCtx.clearRect(adjustedX - 2, adjustedY - 2, adjustedWidth + 5, adjustedHeight + 5);
      }

      return markerCtx.clearRect(
        preRecordedMarkerSize.x - 2, markerY - 2, preRecordedMarkerSize.width + 5, preRecordedMarkerSize.height + 5);
    }

    if (IS_MARKER_HALF_SIZE) {
      const { adjustedX, adjustedY, adjustedWidth, adjustedHeight }: IAdjustMarkerCoordinate = this.adjustHalfSizeMarker(adjustedMarkerValue, markerCtx);
      preRecordedMarkerSize = { x: adjustedX, y: markerY, width: adjustedWidth, height: adjustedHeight };
    }

    if (preRecordedMarkerSize.height > 0 && preRecordedMarkerSize.width > 0) {
      this.previewMarkCanvas(preRecordedMarkerSize.x, markerY, preRecordedMarkerSize.width, preRecordedMarkerSize.height);
      Object.assign(marker, {
        cropped_img: this.getImgMarkedCanvas(preRecordedMarkerSize.x, markerY, preRecordedMarkerSize.width, preRecordedMarkerSize.height),
        adjustedWidth: preRecordedMarkerSize.width,
        adjustedHeight: preRecordedMarkerSize.height
      });
    }

    this.fillMarkerStyle(marker.border_color,
      { x: preRecordedMarkerSize.x, y: markerY, width: preRecordedMarkerSize.width, height: preRecordedMarkerSize.height },
      markerCtx);
    this.currentCoorByChargeCode.set((marker as SelectedMarking).chargeCode, { adjustedX: preRecordedMarkerSize.x, adjustedY: preRecordedMarkerSize.y, adjustedWidth: preRecordedMarkerSize.width, adjustedHeight: preRecordedMarkerSize.height });
    return markerCtx.strokeRect(preRecordedMarkerSize.x, markerY, preRecordedMarkerSize.width, preRecordedMarkerSize.height);
  }

  manualMarker(marker: Marking | SelectedMarking,
               markerDetail = {x: null, y: null, width: null, height: null},
               markerCtx: CanvasRenderingContext2D,
               isRemove: boolean = false,
               isRecall: boolean = false): any {
    const innerWidth: number = (marker as SelectedMarking).resolutionWidth;
    const innerHeight: number = (marker as SelectedMarking).resolutionHeight;

    markerCtx.strokeStyle = marker.border_color;
    if ((!innerWidth && !innerHeight) && !isRecall) {
      setTimeout(() => {
        this.recallMarker({marker, markerDetail, markerCtx, isBarcodeSticker: false, isRemove}, 'manual');
      }, 100);
    }

    if (innerWidth && innerHeight) {
      const {adjustedX, adjustedY, adjustedWidth, adjustedHeight}: IAdjustMarkerCoordinate =
        this.adjustMarkerCoordinate(markerDetail.x, markerDetail.y, markerDetail.width, markerDetail.height,
          {pdfOriWidth: innerWidth, pdfOriHeight: innerHeight});

      markerDetail.x = adjustedX;
      markerDetail.y = adjustedY;
      markerDetail.width = adjustedWidth;
      markerDetail.height = adjustedHeight;
    }
    if (isRemove) {
      return markerCtx.clearRect(
        markerDetail.x - 2, markerDetail.y - 2, markerDetail.width + 5, markerDetail.height + 5);
    }
    this.previewMarkCanvas(markerDetail.x, markerDetail.y, markerDetail.width, markerDetail.height, true);
    Object.assign(marker, {
      cropped_img: this.getImgMarkedCanvas(markerDetail.x, markerDetail.y, markerDetail.width, markerDetail.height)
    });

    this.fillMarkerStyle(marker.border_color, markerDetail, markerCtx);
    return markerCtx.strokeRect(markerDetail.x, markerDetail.y, markerDetail.width, markerDetail.height);
  }

  adjustMarkerCoordinate(oriX: number, oriY: number, oriWidth: number, oriHeight: number,
    manualMarkerOriSize = {pdfOriWidth: null, pdfOriHeight: null}): IAdjustMarkerCoordinate {
    const pdfOriWidth = manualMarkerOriSize.pdfOriWidth ? manualMarkerOriSize.pdfOriWidth : this.pdfOriSizes[this.currentPage].width;
    const pdfOriHeight = manualMarkerOriSize.pdfOriHeight ? manualMarkerOriSize.pdfOriHeight : this.pdfOriSizes[this.currentPage].height;
    const pdfViewerWidth = this.markerCanvasProperties[this.currentPageIndex].width;
    const pdfViewerHeight = this.markerCanvasProperties[this.currentPageIndex].height;

    return {
        adjustedX: (oriX / pdfOriWidth) * pdfViewerWidth,
        adjustedY: (oriY / pdfOriHeight) * pdfViewerHeight,
        adjustedWidth: oriWidth * (pdfViewerWidth / pdfOriWidth),
        adjustedHeight: oriHeight * (pdfViewerHeight / pdfOriHeight)
      };
    }

  adjustHalfSizeMarker(adjustMarkerCoor: IAdjustMarkerCoordinate, markerCtx2D: CanvasRenderingContext2D): IAdjustMarkerCoordinate {
    const CANVAS_EL: HTMLCanvasElement = markerCtx2D.canvas;
    const ADJUSTED_X: number = adjustMarkerCoor.adjustedX - (CANVAS_EL.width * 0.428);

    const ADJUSTED_WIDTH: number = (CANVAS_EL.width - ADJUSTED_X) - (CANVAS_EL.width - adjustMarkerCoor.adjustedX) + 30;

    return {
      adjustedX: ADJUSTED_X,
      adjustedY: adjustMarkerCoor.adjustedY,
      adjustedWidth: ADJUSTED_WIDTH,
      adjustedHeight: adjustMarkerCoor.adjustedHeight
    }
  }

  recallMarker(m = {marker: null, markerDetail: null, markerCtx: null, isBarcodeSticker: null, isRemove: null}, markerType = 'default') {
    if (markerType === 'manual') {
      return this.manualMarker(m.marker, m.markerDetail, m.markerCtx, m.isRemove, true);
    }
    return this.defaultMarker(m.marker, m.markerDetail, m.markerCtx, m.isBarcodeSticker, m.isRemove);
  }

  fillMarkerStyle(
    color = 'blue',
    markerPosition: { x: number, y: number, width: number, height: number },
    markerCtx: CanvasRenderingContext2D): void {
    let fillStyleColor;
    const opacity = 0.15;
    switch (color) {
      case 'white':
        fillStyleColor = `rgba(255, 255, 255, 0.15)`;
        break;
      case 'black':
        fillStyleColor = `rgba(0, 0, 0, 0.5)`;
        break;
      case 'red':
        fillStyleColor = `rgba(255, 0, 0, ${opacity})`;
        break;
      case 'orange':
        fillStyleColor = `rgba(255, 165, 0, ${opacity})`;
        break;
      default:
        fillStyleColor = `rgba(0, 0, 255, ${opacity})`;
    }

    markerCtx.fillStyle = fillStyleColor;
    markerCtx.fillRect(markerPosition.x, markerPosition.y, markerPosition.width, markerPosition.height);
  }

  isCollides(rects: Marking[], x, y): boolean | Marking {
    let collidedRect: boolean | Marking = false;
    for (let i = 0, len = rects.length; i < len; i++) {
      const {adjustedX, adjustedY, adjustedWidth, adjustedHeight}: IAdjustMarkerCoordinate =
        this.adjustMarkerCoordinate(rects[i].x_axis, rects[i].y_axis, rects[i].marker_width, rects[i].marker_height);
      const left = adjustedX;
      const right = adjustedX + adjustedWidth;
      const top = adjustedY;
      const bottom = adjustedY + adjustedHeight;

      if (right >= x && left <= x && bottom >= y && top <= y) {
        collidedRect = (rects[i] as Marking);
      }
    }

    return collidedRect;
  }

  isMarkedCollides(mouseX: any, mouseY: any) {
    for (const [code, markerCoor] of this.currentCoorByChargeCode.entries()) {
      const LEFT = markerCoor.adjustedX;
      const RIGHT = (markerCoor.adjustedX + markerCoor.adjustedWidth);
      const TOP = markerCoor.adjustedY;
      const BOTTOM = (markerCoor.adjustedY + markerCoor.adjustedHeight);

      if (RIGHT >= mouseX && LEFT <= mouseX && BOTTOM >= mouseY && TOP <= mouseY) {
        const newObjCode = { code: code };
        return Object.assign(markerCoor, newObjCode);
      }
    }

    return false;
  }

  handleConfirmManualMarker(): any {
    this.isStartMarker = false;
    this.isHighlightClicked = true;
    const newSelectedMarking: SelectedMarking = {
      border_color: 'red',
      detail: [],
      sequence: null,
      chargeItem: null,
      chargeCode: null,
      cropped_img: this.getImgMarkedCanvas(
        this.manualMarkerProperty.x, this.manualMarkerProperty.y,
        this.manualMarkerProperty.width, this.manualMarkerProperty.height),
      marker_width: this.manualMarkerProperty.width,
      marker_height: this.manualMarkerProperty.height,
      x_axis: this.manualMarkerProperty.x,
      y_axis: this.manualMarkerProperty.y,
      page: this.currentPage,
      resolutionWidth: this.markerCanvasProperties[this.currentPageIndex].width,
      resolutionHeight: this.markerCanvasProperties[this.currentPageIndex].height,
      marking_type: null
    };
    if (!this.pagesSelectedMarker[this.currentPage]) {
      Object.assign(this.pagesSelectedMarker, {[this.currentPage]: []});
    }
    if (!this.markerCoor[this.currentPage]) {
      Object.assign(this.markerCoor, {[this.currentPage]: []});
    }
    this.pagesSelectedMarker[this.currentPage].unshift(newSelectedMarking);
    this.markerCoor[this.currentPage].unshift(newSelectedMarking);
    this.applyMarker(this.currentPage, this.markerCanvasProperty.context2D, (this.markerCoor as Marking));
    this.markerPanelClassName(this.isStartMarker);
    this.totalSelectedMarker++;
    return;
  }

  // handleAddDateTime(selectedMarker: Marking | SelectedMarking, index: number): number {
  //   const detail: MarkingDetail = {
  //     chargeCode: null,
  //     chargeSheetMarkingSelectedId: null,
  //     endTime: null,
  //     startTime: null,
  //     date: this.currentDate
  //   };
  //   if (selectedMarker.detail === null) {
  //     selectedMarker.detail = [];
  //   }
  //   selectedMarker.detail = [...selectedMarker.detail];

  //   const markerId: number = selectedMarker.id;
  //   const detailDefaultValue = {startTime: false, endTime: false};
  //   // RESET Validation Value to Code, StartTIme and EndTime
  //   delete this.correctedMarkerList.get(markerId).qty;
  //   if (this.correctedMarkerList.get(markerId).details) {
  //     this.correctedMarkerList.get(markerId).details.push(detailDefaultValue);
  //   } else {
  //     Object.assign(this.correctedMarkerList.get(markerId), {details: [detailDefaultValue]});
  //   }
  //   this.pageMarkerErrors[selectedMarker.page][index] = false;
  //   return selectedMarker.detail.push(detail);
  // }

  handleRemoveMarker(arrayIndex, markerItem: SelectedMarking) {
    const {adjustedX, adjustedY, adjustedWidth, adjustedHeight}: IAdjustMarkerCoordinate =
      this.adjustMarkerCoordinate(markerItem.x_axis, markerItem.y_axis, markerItem.marker_width, markerItem.marker_height);
    let index: number = arrayIndex;
    if (markerItem.id) {
      switch (markerItem.border_color) {
        case 'blue':
          this.preRecordedMarker(markerItem, adjustedY, this.markerCanvasProperties[this.currentPageIndex].context2D, true);
          break;
        case 'red':
          this.manualMarker(markerItem,
            {x: markerItem.x_axis, y: markerItem.y_axis, width: markerItem.marker_width, height: markerItem.marker_height},
            this.markerCanvasProperties[this.currentPageIndex].context2D, true);
          break;
        default:
          this.defaultMarker(markerItem,
            {x: adjustedX, y: adjustedY, width: adjustedWidth, height: adjustedHeight},
            this.markerCanvasProperties[this.currentPageIndex].context2D, markerItem.border_color === 'orange', true);
      }
      if (this.currentCoorByChargeCode.has(markerItem.chargeCode)) {
        this.currentCoorByChargeCode.delete(markerItem.chargeCode);
      }
    } else {
      this.markerCanvasProperties[this.currentPageIndex].context2D.clearRect(
        markerItem.x_axis - 2, markerItem.y_axis - 2, markerItem.marker_width + 5, markerItem.marker_height + 5);
    }
    this.pagesSelectedMarker[this.currentPage].splice(arrayIndex, 1);

    if (this.inputStage === 'marking' && markerItem.border_color === 'red') {
      index = this.markerCoor[this.currentPage].indexOf(markerItem);
    }

    if (markerItem.border_color === 'red') {
      this.markerCoor[this.currentPage].splice(index, 1);
    }
    this.totalSelectedMarker--;

    if (this.selectedFocusMarker) {
      this.resetAllMarker();
    }
  }

  removeAllMarker(markerCtx: CanvasRenderingContext2D, width: number, height: number) {
    return markerCtx.clearRect(0, 0, width, height);
  }

  handleMouseClick(canvasPoint: PointerEvent) {
    if (this.isStartMarker || this.inputStage === 'data-entry' || this.selectedFocusMarker || this.isReadOnly) {
      return;
    }
    const rect: boolean | Marking = this.isCollides(this.markerCoor[this.currentPage], canvasPoint.offsetX, canvasPoint.offsetY);
    if (rect) {
      const foundMarker: Marking = (rect as Marking);
      const markerCtx: CanvasRenderingContext2D = this.markerCanvasProperties[this.currentPageIndex].context2D;
      const {adjustedX, adjustedY, adjustedWidth, adjustedHeight}: IAdjustMarkerCoordinate =
        this.adjustMarkerCoordinate(
          foundMarker.x_axis, foundMarker.y_axis,
          foundMarker.marker_width, foundMarker.marker_height);

      const findMarker = this.pagesSelectedMarker[this.currentPage]
        .filter((marker: SelectedMarking) => {
          return marker.chargeCode === foundMarker.code
            && ((marker.y_axis === foundMarker.y_axis) && (marker.x_axis === foundMarker.x_axis));
        });
      if (findMarker.length === 0) {
        const selectedMarker = this.setSelectedMarkerProperty(foundMarker, 'marking-stage');
        this.pagesSelectedMarker[this.currentPage].unshift(selectedMarker);
        markerCtx.clearRect(adjustedX - 2, adjustedY - 2, adjustedWidth + 5, adjustedHeight + 5);
        this.applyMarker(
          this.currentPage,
          this.markerCanvasProperties[this.currentPageIndex].context2D,
          ({[this.currentPage]: [selectedMarker]} as any),
          foundMarker.border_color === 'blue' ? 'preRecorded' : 'barcode', true);
        this.totalSelectedMarker++;
      }
    }
  }

  setSelectedMarkerProperty(marking: Marking, currentAction: string): SelectedMarking {
    let newSelectedMarker: SelectedMarking;
    newSelectedMarker = {
      border_color: marking.border_color,
      marker_height: marking.marker_height,
      marker_width: marking.marker_width,
      x_axis: marking.x_axis,
      y_axis: marking.y_axis,
      detail: marking.detail,
      page: marking.page,
      sequence: null,
      chargeCode: marking.code,
      chargeItem: marking.items,
      id: currentAction === 'data-entry' ? marking.selectedMarkerId : marking.id,
      cropped_img: null,
      marking_type: marking.marking_type
    };

    return newSelectedMarker;
  }

  handleMouseMove(mouseEvent: MouseEvent) {
    if (this.isHighlightClicked || this.isReadOnly) {
      return ;
    }

    if (!this.isStartMarker || this.inputStage === 'data-entry') {
      this.canvasTooltipDisplay(mouseEvent);
      return;
    }

    this.markerCanvasProperty.context2D.clearRect(0, 0, this.markerCanvasProperty.width, this.markerCanvasProperty.height);

    this.currentMarkerLocation.startX = mouseEvent.clientX - this.markerCanvasProperty.offsetX;
    this.currentMarkerLocation.startY = mouseEvent.clientY - this.markerCanvasProperty.offsetY;

    if (this.currentMarkerLocation.height > 0 && this.currentMarkerLocation.width > 0) {
      const {x_axis, y_axis, canvasWidth, canvasHeight} =
        this.markCanvasConfig(mouseEvent, this.currentMarkerLocation.width, this.currentMarkerLocation.height, {getImageData: true});
      this.previewMarkCanvas(x_axis, y_axis, canvasWidth, canvasHeight);
    }

    this.markerCanvasProperty.context2D.strokeRect(
      this.currentMarkerLocation.startX, this.currentMarkerLocation.startY,
      this.currentMarkerLocation.width, this.currentMarkerLocation.height);
  }

  canvasTooltipDisplay(mouseEvent: MouseEvent): void {
    const rect: boolean | Marking = this.isCollides(this.markerCoor[this.currentPage], mouseEvent.offsetX, mouseEvent.offsetY);
    const MARKER_SCREEN_OVERLAY: HTMLElement = document.querySelector('.marker-screen-overlay');
    const CANVAS_EL: HTMLElement = document.querySelector('.marker-board');
    const selectedMarkerCoor = this.isMarkedCollides(mouseEvent.offsetX, mouseEvent.offsetY);

    if (rect || selectedMarkerCoor) {
      const MARKER: Marking | any = rect ? (rect as Marking) : selectedMarkerCoor;

      MARKER_SCREEN_OVERLAY.style.top = `${mouseEvent.offsetY - 50}px`;
      MARKER_SCREEN_OVERLAY.style.left = `${(CANVAS_EL.offsetLeft + mouseEvent.offsetX) - 50}px`;

      if (MARKER_SCREEN_OVERLAY.childNodes.length === 0) {
        const CODE_TOOLTIP: HTMLElement = this.renderer.createText(MARKER.code);

        CANVAS_EL.style.cursor = 'pointer';
        MARKER_SCREEN_OVERLAY.style.display = 'block';

        MARKER_SCREEN_OVERLAY.append(CODE_TOOLTIP);
      }
      return ;
    } else {
      if (MARKER_SCREEN_OVERLAY.childNodes.length > 0) {
        MARKER_SCREEN_OVERLAY.childNodes.forEach((child: ChildNode) => MARKER_SCREEN_OVERLAY.removeChild(child));
      }

      CANVAS_EL.style.cursor = 'default';
      MARKER_SCREEN_OVERLAY.style.removeProperty('display');
    }
  }

  handleMouseUp(mouseEvent: MouseEvent) {
    if (!this.isStartMarker || this.inputStage !== 'marking' || this.isReadOnly || mouseEvent.which === 3) {
      return;
    }
    const {x_axis, y_axis, canvasWidth, canvasHeight} =
      this.markCanvasConfig(mouseEvent,
        this.currentMarkerLocation.width, this.currentMarkerLocation.height, {strokeRect: true, getImageData: true});
    this.previewMarkCanvas(x_axis, y_axis, canvasWidth, canvasHeight);
    this.isHighlightClicked = true;
    this.markerCanvasProperty.nativeElement.style.cursor = 'default';
    this.handleConfirmManualMarker();
  }

  previewMarkCanvas(x: number, y: number, width: number, height: number, isManualMarker = false) {
    const BROWSER_ZOOM_VALUE: number = window.devicePixelRatio;
    const CAL_BROWSER_ZOOM_WIDTH: number = BROWSER_ZOOM_VALUE < 1 ? width : width * BROWSER_ZOOM_VALUE;
    const CAL_BROWSER_ZOOM_HEIGHT: number =  BROWSER_ZOOM_VALUE < 1 ? height : height * BROWSER_ZOOM_VALUE;
    const CAL_BROWSER_ZOOM_X: number = x * BROWSER_ZOOM_VALUE;
    const CAL_BROWSER_ZOOM_Y: number = y * BROWSER_ZOOM_VALUE;

    const markerPreviewCanvas: HTMLCanvasElement = document.querySelector('#marked-canvas');
    const mCanvasCtx = markerPreviewCanvas.getContext('2d');
    markerPreviewCanvas.width = BROWSER_ZOOM_VALUE < 1 ? width : width * BROWSER_ZOOM_VALUE;
    markerPreviewCanvas.height = BROWSER_ZOOM_VALUE < 1 ? height : height * BROWSER_ZOOM_VALUE;

    if (isManualMarker) {
      mCanvasCtx.scale(this.zoomValue, this.zoomValue);
    }

    // Manual Marker no need to multiply zoom value, marker panel and pdf-viewer is 1 to 1 ratio
    if (this.isStartMarker) {
      this.manualMarkerProperty.x = x;
      this.manualMarkerProperty.y = y;
      this.manualMarkerProperty.width = width;
      this.manualMarkerProperty.height = height;
    }

    const pdfCtx: CanvasRenderingContext2D = this.CurrentPdfViewCanvas.context2D;
    mCanvasCtx.putImageData(pdfCtx.getImageData(CAL_BROWSER_ZOOM_X, CAL_BROWSER_ZOOM_Y, CAL_BROWSER_ZOOM_WIDTH, CAL_BROWSER_ZOOM_HEIGHT), 0, 0);
  }

  getImgMarkedCanvas(x: number, y: number, width: number, height: number) {
    const markerPreviewCanvas: HTMLCanvasElement = document.querySelector('#marked-canvas');
    return markerPreviewCanvas.toDataURL();
  }

  markCanvasConfig(mouseEvent, markWidth: number, markHeight: number, otherConfig?: MarkCanvas) {
    const x = this.currentMarkerLocation.startX;
    const y = this.currentMarkerLocation.startY;

    // Draw Stroke Rectangle
    if (otherConfig.strokeRect) {
      this.markerCanvasProperties[this.currentPageIndex].context2D.strokeRect(x, y, markWidth, markHeight);
    }

    // Get Image from Stroked Rectangle
    if (otherConfig.getImageData) {
      this.markerCanvasProperties[this.currentPageIndex].context2D.getImageData(x, y, markWidth, markHeight);
    }

    return {
      x_axis: x,
      y_axis: y,
      canvasWidth: markWidth,
      canvasHeight: markHeight
    };
  }

  quitMarker(e) {
    this.markerCanvasProperty.nativeElement.style.cursor = 'default';
    this.markerCanvasProperty.context2D.clearRect(
      this.currentMarkerLocation.startX - 2, this.currentMarkerLocation.startY - 2,
      this.currentMarkerLocation.width + 5, this.currentMarkerLocation.height + 5);
    this.isStartMarker = false;
    this.applyMarker(this.currentPage, this.markerCanvasProperty.context2D, (this.markerCoor as Marking));
    this.resetCurrentMarkerLocation();
    this.markerPanelClassName(this.isStartMarker);
  }

  resetCurrentMarkerLocation(): void {
    Object.keys(this.currentMarkerLocation).map(keyName => {
      return this.currentMarkerLocation[keyName] = null;
    });
  }

  resetAllMarker(): void {
    this.markerCanvasProperty.context2D.clearRect(0, 0, this.markerCanvasProperty.width, this.markerCanvasProperty.height);
    this.applyMarker(this.currentPage, this.markerCanvasProperty.context2D, (this.markerCoor as Marking));
    this.selectedFocusMarker =  null;
  }

  inputChange(selectedMarkerInputChange: ISelectedMarkerInputChange): any {
    const SELECTED_MARKER_INDEX: number = selectedMarkerInputChange.selectedMarkerIndex;
    const SELECTED_MARKER: SelectedMarking = selectedMarkerInputChange.selectedMarker;
    const CORRECTED_MARKER_LIST = selectedMarkerInputChange.itemInputChecker;
    const MARKER_ID: number = SELECTED_MARKER.id;

    this.itemInputCheckerById.set(MARKER_ID, CORRECTED_MARKER_LIST);
    this.cdr.detectChanges();

    if (Object.keys(selectedMarkerInputChange).indexOf('isChargeAsMisc') > -1) {
      if (selectedMarkerInputChange.isChargeAsMisc) {
        this.chargeItemAsMiscBySelectedChargeId.set(SELECTED_MARKER.id, selectedMarkerInputChange.miscInfo);
      }

      if (!selectedMarkerInputChange.isChargeAsMisc) {
        if (this.chargeItemAsMiscBySelectedChargeId.has(SELECTED_MARKER.id)) {
          this.chargeItemAsMiscBySelectedChargeId.set(SELECTED_MARKER.id, null);
        }
      }

      this.handleMiscChargeItem.emit(this.chargeItemAsMiscBySelectedChargeId);
    }

    // For Attention Charge Sheet
    if (this.inputStage.indexOf('attention') > 0) {
      const INPUT_TYPE: string = selectedMarkerInputChange.inputType;

      return this.handleErrorCorrected.emit({
        type: INPUT_TYPE,
        id: MARKER_ID,
        isErrorCorrected: this.itemInputCheckerById.get(MARKER_ID),
        marker: SELECTED_MARKER
      });
    }
  }

  handleWarehouseQtyUpdate(warehoueDetail: IWarehouseQty): void {
    const MARKING_SELECTED_ID: number = warehoueDetail.chargeSheetMarkingSelectedId;

    const NEW_WAREHOUSE_QTY = { chargeCode: warehoueDetail.chargeCode, warehouseQty: warehoueDetail.warehouseQty };

    this.warehouseQtyBySelectedChargeId.set(MARKING_SELECTED_ID, NEW_WAREHOUSE_QTY);
  }

  // For record keeping
  inputErrorCorrection(isErrorCorrected: { index: number, selectedMarker: SelectedMarking, inputField: string }): any {
    if (this.inputStage.indexOf('attention') < 0) {
      return;
    }

    this.handleErrorCorrected.emit({
      type: isErrorCorrected.inputField,
      id: isErrorCorrected.selectedMarker.id,
      isErrorCorrected: this.itemInputCheckerById.get(isErrorCorrected.selectedMarker.id),
      marker: isErrorCorrected.selectedMarker
    });
  }

  setDisableMarker(a: HTMLElement, marker: SelectedMarking): void {
    const disabledClassName = 'disabled';
    if (a.classList.contains(disabledClassName)) {
      a.classList.remove(disabledClassName);
    } else {
      a.classList.add(disabledClassName);
    }
  }

  handleRemoveItem(markerIndex: number, selectedMarker: SelectedMarking): void {
    const IS_REMOVE_REASON_NULL = selectedMarker.removeReason === null;

    selectedMarker.removeReason = IS_REMOVE_REASON_NULL ? '' : null;
  }

  handleDisableToggle(toggleChange: MatSlideToggleChange, marker: SelectedMarking, markerItemEl: HTMLElement, index: number): any {
    const IS_DISABLED: boolean = toggleChange.checked;
    if (IS_DISABLED) {
      markerItemEl.classList.add('marker-disabled');
    } else {
      markerItemEl.classList.remove('marker-disabled');
    }

    // If disabled, The PageMarkerErros will be always true
    // if (this.inputStage === 'data-entry') {
    this.pageMarkerErrors[marker.page][index] = IS_DISABLED ? IS_DISABLED : this.assignCheckMarkerErrors(marker);
    this.handleDERemoveMarker(this.currentPage, marker.id, IS_DISABLED, index);
    // }

    return this.handleDisableMarker.emit({marker, isDisabled: IS_DISABLED});
  }

  assignCheckMarkerErrors(selectedMarker: SelectedMarking): boolean {
    const DEFAULT_CHECKER_DATA: { [key: string]: boolean } = { code: selectedMarker.searchCode !== null };

    if (this.warehouseQtyBySelectedChargeId.has(selectedMarker.id)) {
      return false;
    }

    if (selectedMarker.chargeItemType === 'QUANTITY' || selectedMarker.chargeItemType === 'PERCENTAGE') {
      Object.assign(DEFAULT_CHECKER_DATA, { quantity: selectedMarker.quantity !== null });
    }

    if (selectedMarker.chargeItemType === 'TIME') {
      const DETAILS = selectedMarker.detail[0];
      const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm';
      const START_TIME_MOMENT: moment.Moment = DETAILS.startTime ? moment(DETAILS.startTime, MOMENT_FORMAT) : null;
      const END_TIME_MOMENT: moment.Moment = DETAILS.endTime ? moment(DETAILS.endTime, MOMENT_FORMAT) : null;
      let inputCheckerDetail: boolean = (DETAILS.startTime && DETAILS.endTime);
      let isStartAndEndTimeSame: boolean = false;

      if (START_TIME_MOMENT && END_TIME_MOMENT) {
        isStartAndEndTimeSame = this.moment.compareDateWithTime(START_TIME_MOMENT, END_TIME_MOMENT, false) === 0;
        inputCheckerDetail = !isStartAndEndTimeSame;
      }

      Object.assign(DEFAULT_CHECKER_DATA, { details: inputCheckerDetail });
    }

    return Object.keys(DEFAULT_CHECKER_DATA).every((inputName: string) => DEFAULT_CHECKER_DATA[inputName] === true);
  }

  handleDERemoveMarker(currentPage: number, markerId: number, isDisabled: boolean, markerIndex: number): any {
    if (!this.disabledMarkerIdByPageNum.get(currentPage)) {
      this.disabledMarkerIdByPageNum.set(currentPage, []);
    }
    const GET_CURRENT_PAGE_MARKER_IDS: number[] = this.disabledMarkerIdByPageNum.get(currentPage);
    if (isDisabled) {
      this.itemInputCheckerById.get(markerId).isDisabled = isDisabled;
      return GET_CURRENT_PAGE_MARKER_IDS.push(markerId);
    } else {
      delete this.itemInputCheckerById.get(markerId).isDisabled;
      return GET_CURRENT_PAGE_MARKER_IDS.splice(GET_CURRENT_PAGE_MARKER_IDS.indexOf(markerId), 1);
    }
  }

  markerPanelClassName(isStartMarking: boolean): any {
    const markerPanelEl = document.querySelector('.marker-panel');
    if (isStartMarking) {
      // Button Cancel Marking
      const BTN_CANCEL_MARKING_EL: HTMLElement = document.querySelector('.btn-cancel-marking');
      const BCM_GBCR_TOP: number = BTN_CANCEL_MARKING_EL ? BTN_CANCEL_MARKING_EL.getBoundingClientRect().top : null;

      const MARKER_PANEL_EL = document.querySelector('.marker-panel');

      if ((MARKER_PANEL_EL.scrollTop > BCM_GBCR_TOP || MARKER_PANEL_EL.scrollTop < BCM_GBCR_TOP) && MARKER_PANEL_EL.scrollTop !== 0) {
        BTN_CANCEL_MARKING_EL.style.top = `${MARKER_PANEL_EL.scrollTop}px`;
      } else {
        BTN_CANCEL_MARKING_EL.style.top = 'initial';
      }

      return markerPanelEl.classList.add('disabled-scroll');
    } else {
      return markerPanelEl.classList.remove('disabled-scroll');
    }
  }

  hasWarning(pageNumber: number): boolean {
    if (this.inputStage !== 'data-entry') {
      return false;
    }

    const selectedMarkerArr: SelectedMarking[] = this.pagesSelectedMarker[pageNumber];
    let hasChargeCodeNotFound: boolean;
    try {
      hasChargeCodeNotFound = this.hasChargeCodeNotFoundInPage(selectedMarkerArr);
    } catch (e) {
      hasChargeCodeNotFound = false;
    }
    return hasChargeCodeNotFound;
  }

  hasChargeCodeNotFoundInPage(selectedMarkerArr: SelectedMarking[]): boolean {
    for (const marker of selectedMarkerArr) {
      const isChargeCodeNotFound: boolean = marker.chargeItem === null && marker.searchCode === 'YES';
      if (isChargeCodeNotFound) {
        return true;
      }
    }
    return false;
  }

  displayRemoveMarker(selectedMarker: SelectedMarking): boolean {
    const status: string = selectedMarker.status;
    const isAttention: boolean = this.inputStage.indexOf('attention') > -1;
    const isDataEntry: boolean = this.inputStage === 'data-entry';
    const hasCorrectedMarker: IItemInputChecker = this.itemInputCheckerById.get(selectedMarker.id);
    if (status === null && (this.inputStage === 'data-entry' || this.inputStage.indexOf('attention') > 0)) {
      return true;
    }

    return ((isAttention && hasCorrectedMarker) || isDataEntry) && status.indexOf('SUCCESS') < 0;
  }

  handleRdgFocusMarker(selectedMarker: SelectedMarking): any {
    if (this.inputStage !== 'marking') {
      this.handleOpenMarkerPanelPreview();
    }

    const MARKER_BOARD_DIMENSION: IMarkerBoardDimension = { width: this.markerCanvasProperties[this.currentPageIndex].width, height: this.markerCanvasProperties[this.currentPageIndex].height, x: 0, y: 0 };
    const CURRENT_MARKER_CANVAS_CONT2D: CanvasRenderingContext2D = this.markerCanvasProperties[this.currentPageIndex].context2D;
    const DIMENSION_TO_CUT: string[] = ['top', 'bottom', 'left', 'right'];
    let processedDimension: number = 0;

    this.removeAllMarker(CURRENT_MARKER_CANVAS_CONT2D, MARKER_BOARD_DIMENSION.width, MARKER_BOARD_DIMENSION.height);
    const innerWidth: number = (selectedMarker as SelectedMarking).resolutionWidth;
    const innerHeight: number = (selectedMarker as SelectedMarking).resolutionHeight;

    do {
      const ADJUSTED_MARKER_VALUE: IAdjustMarkerCoordinate = this.adjustMarkerCoordinate(selectedMarker.x_axis, selectedMarker.y_axis, selectedMarker.marker_width, selectedMarker.marker_height, {pdfOriWidth: innerWidth, pdfOriHeight: innerHeight});
      const CURRENT_DIMENSION: string = DIMENSION_TO_CUT[processedDimension];
      const IS_MARKER_HALF_SIZE: boolean = selectedMarker.marking_type === "PRE_RECORDED_DM_HALF";

      const ADJUSTED_MARKER_TO_APPLY: IAdjustMarkerCoordinate = IS_MARKER_HALF_SIZE ?
      this.adjustHalfSizeMarker(ADJUSTED_MARKER_VALUE, CURRENT_MARKER_CANVAS_CONT2D) : ADJUSTED_MARKER_VALUE;

      const CAL_MARKER_BOARD: IMarkerBoardDimension = this.targetFocusMarker(CURRENT_DIMENSION, MARKER_BOARD_DIMENSION, ADJUSTED_MARKER_TO_APPLY, selectedMarker, selectedMarker.border_color);

      this.fillMarkerStyle('black', CAL_MARKER_BOARD, this.markerCanvasProperties[this.currentPageIndex].context2D);
      processedDimension++;
    } while(processedDimension < DIMENSION_TO_CUT.length);
  }

  handleOpenMarkerPanelPreview(): void {
    this.openPreviewMarkerPanel = true;
  }

  handleCloseMarkerPanelPreview(): void {
    if (this.inputStage !== 'marking') {
      this.openPreviewMarkerPanel = false;
      this.resetAllMarker();
    }
  }

  tabPageNumberStatusPendingIndicator(selectedMarker: SelectedMarking[]): boolean {
    if (this.inputStage.toLowerCase().indexOf("attention") < 0 || selectedMarker.length === 0) {
      return false;
    }

    const PENDING_MARKERS: SelectedMarking[] = selectedMarker.filter((marker: SelectedMarking) => {
      return marker.status === 'PENDING';
    });

    return PENDING_MARKERS.length > 0;
  }

  emphasizeInputIndicator(errorOrCorrect: 'error' | 'corrected' | 'pending', markedItemIndex: number, currentPageMarkerErrors: boolean[], selectedMarker: SelectedMarking): boolean {
    if (!currentPageMarkerErrors || this.inputStage === 'marking' || this.inputStage.indexOf('completed') > -1 || this.inputStage.indexOf('waiting') > -1) {
      return false;
    }

    switch(errorOrCorrect) {
      case 'error':
        return !currentPageMarkerErrors[markedItemIndex];
      case 'corrected':
        return currentPageMarkerErrors[markedItemIndex] && !this.itemInputCheckerById.get(selectedMarker.id).isDisabled;
      case 'pending':
        if (this.inputStage.indexOf('data-entry') >= 0) {
          return false;
        }

        return selectedMarker.status === 'PENDING';
      default:
        return false;
    }
  }

  targetFocusMarker(dimensionToCut: string, markerBoardDimension: IMarkerBoardDimension, adjustedMarker: IAdjustMarkerCoordinate, selectedMarker: SelectedMarking, markerBorderColor: string): IMarkerBoardDimension {
    const CAL_MARKER_BOARD: IMarkerBoardDimension = Object.assign({}, markerBoardDimension);
    const EXPAND_VIEW_HEIGHT: number = 10;

    if (dimensionToCut === 'top') {
      CAL_MARKER_BOARD.y = markerBorderColor === 'orange' ? (adjustedMarker.adjustedY - 5) :
                           markerBorderColor === 'red' ? selectedMarker.y_axis : (adjustedMarker.adjustedY - EXPAND_VIEW_HEIGHT);

      CAL_MARKER_BOARD.height = (markerBorderColor === 'orange' || markerBorderColor === 'blue') ? ((CAL_MARKER_BOARD.height - adjustedMarker.adjustedHeight)*-1) :
                                markerBorderColor === 'red' ? (CAL_MARKER_BOARD.height - selectedMarker.marker_height)*-1 :  CAL_MARKER_BOARD.height;
    }

    if (dimensionToCut === 'left') {
      CAL_MARKER_BOARD.x = markerBorderColor === 'orange' ? (adjustedMarker.adjustedX - 5) :
                           markerBorderColor === 'red' ? selectedMarker.x_axis : -(CAL_MARKER_BOARD.width - adjustedMarker.adjustedHeight);

      CAL_MARKER_BOARD.y = markerBorderColor === 'orange' ? (adjustedMarker.adjustedY - 5) :
                           markerBorderColor === 'red' ? selectedMarker.y_axis : (adjustedMarker.adjustedY - EXPAND_VIEW_HEIGHT);

      CAL_MARKER_BOARD.width = markerBorderColor === 'orange' ? -(markerBoardDimension.width - (markerBoardDimension.width * 0.21)) :
                               markerBorderColor === 'red' ? (selectedMarker.marker_width + selectedMarker.x_axis)*-1  : CAL_MARKER_BOARD.width;

      CAL_MARKER_BOARD.height = markerBorderColor === 'orange' ? (markerBoardDimension.height *  0.065) :
                                markerBorderColor === 'red' ? selectedMarker.marker_height : (adjustedMarker.adjustedHeight + (EXPAND_VIEW_HEIGHT * 2));
    }

    if (dimensionToCut === 'right') {
      CAL_MARKER_BOARD.x = markerBorderColor === 'orange' ? ((adjustedMarker.adjustedX - 5) + (markerBoardDimension.width * 0.21)) :
                           markerBorderColor === 'red' ? (selectedMarker.x_axis + selectedMarker.marker_width) : (adjustedMarker.adjustedX + adjustedMarker.adjustedWidth);

      CAL_MARKER_BOARD.y = markerBorderColor === 'orange' ? (adjustedMarker.adjustedY - 5) :
                           markerBorderColor === 'red' ? selectedMarker.y_axis :(adjustedMarker.adjustedY - EXPAND_VIEW_HEIGHT);

      CAL_MARKER_BOARD.width = markerBorderColor === 'orange' ? (markerBoardDimension.width - (markerBoardDimension.width * 0.21)) :
                               markerBorderColor === 'red' ? (CAL_MARKER_BOARD.width - (selectedMarker.marker_width + selectedMarker.x_axis)) : CAL_MARKER_BOARD.width;

      CAL_MARKER_BOARD.height = markerBorderColor === 'orange' ? (markerBoardDimension.height *  0.065) :
                                markerBorderColor === 'red' ? selectedMarker.marker_height : (adjustedMarker.adjustedHeight + (EXPAND_VIEW_HEIGHT * 2));
    }

    if (dimensionToCut === 'bottom') {
      CAL_MARKER_BOARD.y = markerBorderColor === 'orange' ? adjustedMarker.adjustedY + (CAL_MARKER_BOARD.height * 0.06) :
                           markerBorderColor === 'red' ? (selectedMarker.y_axis + selectedMarker.marker_height) : (adjustedMarker.adjustedY + adjustedMarker.adjustedHeight) + EXPAND_VIEW_HEIGHT;
    }

    return CAL_MARKER_BOARD;
  }
}
