import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { ReportsComponent } from './reports.component';
import { MiscellaneousOnlyComponent } from './misc-only/misc-only.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  declarations: [
    ReportsComponent,
    MiscellaneousOnlyComponent
  ]
})
export class ReportsModule { }
