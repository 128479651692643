import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import { AuthService } from '@services/auth.service';

import { ResetPasswordMatcher } from '../../shared/directives/resetpassword-matcher.directive';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  passwordForm: FormGroup;
  loading = false;
  setPasswordToken: string;
  isResetPassword = true;
  passwordMatcher = new ResetPasswordMatcher();
  redirectIndicator = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      newPassword: [ { value: '', disabled: this.loading} , [Validators.required, Validators.minLength(4), Validators.maxLength(50)] ],
      confirmPassword: [ { value: '', disabled: this.loading} ,  [Validators.required] ]
    }, { validators: this.checkPasswordMatch });

    this.activatedRoute.params
      .subscribe(queryParams => {
        const tokenRegExp = new RegExp(/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/g);
        this.setPasswordToken = queryParams.setPasswordToken;

        if (!tokenRegExp.test(this.setPasswordToken)) {
          this.router.navigate(['/']);
          return ;
        }

        if (this.router.url.indexOf('/auth/activate-account') > -1) {
          this.isResetPassword = false;
          // this.apiPath = '/api/activate-user';
        }
        this.authService.logout();
      });
  }

  onSubmit() {
    if (this.passwordForm.invalid) {
      return ;
    }
    this.loading = true;
    this.passwordForm.disable();

    if (!this.isResetPassword) {
      return this.activateAccount(this.passwordForm, this.setPasswordToken);
    } else {
      return this.resetPassword(this.passwordForm, this.setPasswordToken);
    }
  }

  activateAccount(form: FormGroup, passwordToken: string) {
    this.authService.activateAccount(form, passwordToken)
      .pipe(first())
      .subscribe(
        data => {
          this.redirectIndicator = true;
          setTimeout(() => {
            this.router.navigateByUrl('/auth/login').then();
          }, 5000);
          this.openSnackBar('Account Activated Successfully.', false);
        },
        error => {
          console.error(error);
          this.loading = false;
          this.passwordForm.enable();
          this.openSnackBar(error, true);
        }
      );
  }

  resetPassword(form: FormGroup, passwordToken: string) {
    this.authService.resetPassword(form, passwordToken)
      .pipe(first())
      .subscribe(
        data => {
          this.redirectIndicator = true;
          setTimeout(() => {
            this.router.navigateByUrl('/auth/login').then();
          }, 5000);
          this.openSnackBar('Password Reset Successfully.', false);
        },
        error => {
          console.error(error);
          this.loading = false;
          this.passwordForm.enable();
          this.openSnackBar(error, true);
        }
      );
  }

  checkPasswordMatch(group: FormGroup) {
    const newPassword = group.get('newPassword').value;
    const confirmPassword = group.get('confirmPassword').value;

    return newPassword === confirmPassword ? null : { notMatch: true };
  }

  private openSnackBar(message: string, isError) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
