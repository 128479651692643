import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

import { User } from '@models/user.model';
import {Observable, of, BehaviorSubject} from 'rxjs';
import { Role } from '@models/role.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  defaultUser: User;

  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }

  public get currentUserValue(): User {
    return this.currentUserSubject.getValue();
  }

  public get currentUserRoleValue(): Role {
    return this.currentUserSubject.getValue().role;
  }

  getAllUsers(pageNumber: number): Observable<any> {
    const limit = 15;
    return this.httpClient.get<any>(`api/users?page=${pageNumber}&limit=${limit}&order=+createdAt`, {observe: 'response'})
      .pipe(
        tap(_ => this.log('Fetch Users')),
        catchError(this.handleError<User[]>('GetUsers', []))
      );
  }

  getAllRole(): Observable<any> {
    return this.httpClient.get(`api/roles`);
  }

  getAllClerk(): Observable<any> {
    return this.httpClient.get<any>(`api/users?roleId=4,3&order=+createdAt`);
  }

  getCurrentUser(token?: string): Observable<User> {
    return this.httpClient.get<User>(`api/users/profile`)
      .pipe(
        map(user => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        }
      ));
  }

  createUser(formValue) {
    const body = {
      name: formValue.value.staffName,
      email: formValue.value.email,
      roleId: formValue.value.staffRole,
      address: formValue.value.address,
      contact: formValue.value.contactNo,
      nric: formValue.value.nric,
      employeeNO: formValue.value.empNo
    };

    return this.httpClient.post<User>(`api/users`, body)
            .pipe(
              tap(_ => console.log('Create New User')),
              catchError(this.handleError<User>('Create User')),
              map(user => user)
            );
  }

  updateUserProfile(formValue, userId: number) {
    const body = {
      name: formValue.value.staffName,
      email: formValue.value.email,
      roleId: formValue.value.staffRole,
      address: formValue.value.address,
      contact: formValue.value.contactNo,
      nric: formValue.value.nric,
      employeeNO: formValue.value.empNo,
      managerId: formValue.value.lineManager,
      password: formValue.value.password
    };

    return this.httpClient.put<any>(`api/users/${userId}`, body)
      .pipe(
        tap(_ => console.log('Update User Profile')),
        catchError(this.handleError<User>('Update User Profile')),
        map(user => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  updateUser(formValue, userId: number) {
    const body = {
      name: formValue.value.staffName,
      email: formValue.value.email,
      roleId: formValue.value.staffRole,
      address: formValue.value.address,
      contact: formValue.value.contactNo,
      nric: formValue.value.nric,
      employeeNO: formValue.value.empNo,
      managerId: formValue.value.lineManager
    };

    return this.httpClient.put<any>(`api/users/${userId}`, body)
      .pipe(
        tap(_ => console.log('Update User')),
        catchError(this.handleError<User>('Update User')),
        map(user => {
          if (userId === this.currentUserValue.id) {
            console.log('This is Current User');
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  deleteUser(userId: number) {
    return this.httpClient.delete(`api/users/${userId}`)
      .pipe(
        tap(_ => console.log('Delete User')),
        catchError(this.handleError<User>('Delete User')),
      );
  }

  getManagerList() {
    return this.httpClient.get(`api/users?roleId=3`)
      .pipe(
        tap(_ => console.log('Fetch Managers'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed : ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    return message;
  }
}
