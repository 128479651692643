import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { IConfiguration } from '@models/configuration.model';
import { ConfigurationService } from '@services/configuration.service';
import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-misc-report',
  templateUrl: 'misc-report.component.html',
  styleUrls: ['./misc-report.component.scss']
})

export class MiscellaneousReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isExpanded: boolean;
  @Input() config: IConfiguration;
  @Output() handleConfigUpdate: EventEmitter<IConfiguration> = new EventEmitter<IConfiguration>();

  receipientFG: FormGroup;

  newReceipientSub: Subscription;

  isLoading: boolean = false;

  errorMsg: string = 'This field is required';
  receipientInStr: string = '';
  receipients: string[] = [];

  get isNewReceipientEmpty(): boolean { return this.receipientFG.get('newReceipient').invalid; }

  constructor(private matSnackBar: MatSnackBar, private configService: ConfigurationService) { }

  ngOnInit(): void {
    this.assignReceipientArr(this.config.value);

    this.receipientFG = new FormGroup({
      newReceipient: new FormControl('', [Validators.required, Validators.email])
    });

    this.newReceipientSub = this.receipientFG.get('newReceipient').valueChanges.subscribe({
      next: (value: string) => {
        this.setErrorMessage();
      }
    });
  }

  assignReceipientArr(receipientInStr: string): void {
    this.receipientInStr = receipientInStr;
    this.receipients = receipientInStr !== null && receipientInStr !== '' ? receipientInStr.split(';') : [];
  }

  ngOnDestroy(): void {
      this.newReceipientSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded) {
      if (!changes.isExpanded.currentValue) {
        this.receipientFG.reset();
        this.isLoading = false;
      }
    }

    if (changes.config) {
      if (changes.config.previousValue !== undefined) {
        this.assignReceipientArr(changes.config.currentValue.value);
      }
    }
  }

  setErrorMessage(): string {
    const isDirty: boolean = this.receipientFG.get('newReceipient').dirty;
    const isTouched: boolean = this.receipientFG.get('newReceipient').touched;
    const hasErrorEmail: boolean = this.receipientFG.get('newReceipient').hasError('email') && isDirty;
    const hasErrorRequired: boolean = this.receipientFG.get('newReceipient').hasError('required') && (isDirty || isTouched);

    if (hasErrorEmail) {
      return this.errorMsg = 'Invalid email format. e.g example@email.com';
    }

    if (hasErrorRequired) {
      return this.errorMsg = 'This field is required';
    }

    return this.errorMsg = '';
  }

  handleFormSubmit(): void {
    if (this.receipientFG.invalid) {
      return ;
    }
    let newReceipientsInStr: string = this.receipientInStr;
    newReceipientsInStr += this.receipients.length === 0 ? this.receipientFG.get('newReceipient').value : `;${this.receipientFG.get('newReceipient').value}`;

    this.updateMiscEmail(newReceipientsInStr, false);
  }

  handleRemoveEmail(email: string, index: number): void {
    // Check Semi-Colon Exist
    const SEMI_COLON_AT_FIRST: boolean = this.receipientInStr.indexOf(`;${email}`) > -1;
    const SEMI_COLON_AT_LAST: boolean = this.receipientInStr.indexOf(`${email};`) > -1;
    let emailToRemove: string = email;

    if (SEMI_COLON_AT_FIRST) {
      emailToRemove = `;${email}`;
    }

    if (SEMI_COLON_AT_LAST) {
      emailToRemove = `${email};`;
    }

    this.receipientInStr = this.receipientInStr.replace(emailToRemove, '');
    // this.receipients.splice(index, 1);

    this.updateMiscEmail(this.receipientInStr, true);
  }

  updateMiscEmail(newReceipient: string, isRemove: boolean): void {
    this.isLoading = true;

    this.configService.updateWeeklyMiscEmail(newReceipient)
      .subscribe({
        next: (res: IConfiguration) => {
          this.handleConfigUpdate.emit(res);
          this.isLoading = false;

          if (!isRemove) {
            this.receipientFG.get('newReceipient').reset();
            this.receipientFG.get('newReceipient').markAsPristine();
            this.receipientFG.get('newReceipient').markAsUntouched();
            this.receipientFG.get('newReceipient').updateValueAndValidity();
          }

          this.openSnackBar(isRemove ? 'Removed Email Successfully' : 'Added Email Successfully', false);
        },
        error: (err) => {
          this.isLoading = false;
          this.openSnackBar(err, true);
        }
      });
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
