import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanActivate} from '@angular/router';

import {AuthService} from '@services/auth.service';
import {Role} from '@models/role.model';
import {UserService} from '@services/user.service';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ChargeSheetGuard implements CanActivate, CanActivateChild {
  chargeSheetUrl = '/charge-sheet';
  userRole: Role;

  constructor(private router: Router, private userService: UserService, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.checkTokenExpired()
      .pipe(
        map(response => !!response),
        catchError(() => this.router.navigate(['auth/login']).then(() => this.authService.removeAuth()))
      );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    switch (state.url) {
      case `${this.chargeSheetUrl}/new-entry`:
        this.accessNewEntry(this.userRole);
        break;
    }
    return true;
  }

  checkTokenExpired(): Observable<boolean> {
    return this.authService.isTokenExpired(this.userService.getCurrentUser());
  }

  accessNewEntry(role): boolean {
    return true;
  }
}
