import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '@services/user.service';
import { Role, EnumRole } from '@models/role.model';

@Injectable({
  providedIn: 'root'
})
export class SettingGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let userRole: Role = this.userService.currentUserRoleValue;
      // console.log('Param', this.router.routerState)
      if(userRole.id === EnumRole.ADMIN) {
        return true;
      }
      this.router.navigate(['charge-sheet/dashboard'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
