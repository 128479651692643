import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@proj-env/environment';

@Injectable({providedIn: 'root'})
export class AppService {
  private currentSideBarfoSubject: BehaviorSubject<boolean>;
  public currentSideBar: Observable<any>;

  constructor() {
    this.currentSideBarfoSubject = new BehaviorSubject<boolean>(false);
    this.currentSideBar = this.currentSideBarfoSubject.asObservable();
  }

  public get titledAppVersion(): string {
    const VERSION_SHORTHAND: string = environment.appVersion.split('.')[0];
    const VERSION_ENV: string = environment.appEnv ? `-${environment.appEnv}` : '';
    return `${VERSION_SHORTHAND}.0${VERSION_ENV}`;
  }

  public get appVersion(): string {
    const VERSION_ENV: string = environment.appEnv ? `-${environment.appEnv}` : '';
    const PREBUILD: string = VERSION_ENV === '-dev' ? 'prebuild-' : '';
    return `${PREBUILD}${environment.appVersion}${VERSION_ENV}`;
  }

  public get appEnv(): string {
    return environment.appEnv;
  }

  public get currentSideBarValue(): boolean {
    return this.currentSideBarfoSubject.value;
  }

  public openSideBar(): void {
    this.currentSideBarfoSubject.next(false);
  }

  public closeSideBar(): void {
    this.currentSideBarfoSubject.next(true);
  }
}
