import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

import {ChargeSheet} from '@models/chargesheet.model';
import {IDialogData} from '@models/warn-dialog.model';
import {ChargeSheetService} from '@services/chargesheet.service';
import {UserService} from '@services/user.service';
import {ErrorDetail, IItemInputChecker, IMiscInfo, IValidateQtyBody, SelectedMarking} from '@models/marking.model';
import {IDataEntryRes} from '@models/response.model';
import {IChargeSheetAndChecker} from '@models/chargesheet.model';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ChargeSheetWarningDialogComponent } from '@shared-components/charge-sheet-warning-dialog/charge-sheet-warning-dialog.component';

@Component({
  selector: 'app-dataentrystage',
  templateUrl: './dataentrystage.component.html',
  styleUrls: ['./dataentrystage.component.scss'],
  providers: [DatePipe]
})
export class DataentrystageComponent implements OnInit, OnDestroy {
  chargeSheetId: number;
  errorMarkers = new Map();
  errorChanges: Map<'details', ErrorDetail[]> = new Map();

  chargeSheetForm: FormGroup;
  episodeNo = '';
  ward = '';
  dateTime = '';

  buttonAction = 'idle';
  isReadOnly: boolean = false;

  validatedQty: IValidateQtyBody;
  hasQtyWarningArr: boolean[] = [];
  chargeItemAsMiscById: Map<string, IMiscInfo> = new Map();

  csStatusMsg: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private chargeSheetService: ChargeSheetService,
    private datePipe: DatePipe,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private userService: UserService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(queryParams => this.chargeSheetId = queryParams.chargeSheetId);

    this.isReadOnly = (this.userService.currentUserRoleValue.name === 'STORE USER') || (this.userService.currentUserRoleValue.name === 'GUEST');

    this.chargeSheetForm = this.formBuilder.group({
      id: [''],
      episodeNumber: [{ value: '', disabled: true }, Validators.required],
      backDateCharging: [{value: '', disabled: true}, Validators.required],
      serialNumber: [{value: '', disabled: true}],
      ward: [{ value: '', disabled: true }, Validators.required],
      dateTime: [{ value: '', disabled: true }, Validators.required],
      selectedMarkings: [{ value: [] }],
      mrnNumber: [''],
      updatedDateTime: [{ value: '', }],
      lastViewPath: [this.router.url]
    });
  }

  ngOnDestroy(): void {
    this.matDialog.closeAll();
  }

  onSubmit(status) {
    const hasError: boolean = this.validateMarkerError();
    this.buttonAction = status === 'D' ? 'draft' : 'submit';

    if ((this.chargeSheetForm.invalid || hasError) && status !== 'D') {
      this.openSnackBar('Please fill in required field(s).', true);
      return;
    }

    const qtyOverMaxArr: { [key: string]: string | number }[] = this.getQtyOverMaxArr(this.chargeSheetForm.get('selectedMarkings').value);
    const HAS_QTY_WARN: boolean = this.hasQtyWarningArr.indexOf(true) > -1;

    if ((qtyOverMaxArr.length > 0 || HAS_QTY_WARN) && status !== 'D') {
      this.openWarnMsgDialog(status, qtyOverMaxArr);
    }

    if (((qtyOverMaxArr.length === 0 && !HAS_QTY_WARN) && status === 'S') || status === 'D') {
      this.submitDataEntry(status);
    }
  }

  getQtyOverMaxArr(selectedMarkingDetail: { [index: number]: SelectedMarking[] }): { [key: string]: string | number }[] {
    let overMaxQtyItem: { [key: string]: string | number }[] = [];

    for (let pageNumber in selectedMarkingDetail) {
      const pageSelectedMarker: SelectedMarking[] = selectedMarkingDetail[pageNumber];
      for (let marker of pageSelectedMarker) {
        const getChargeItem: string = marker.chargeItem ? marker.chargeItem.toLowerCase() : '';
        const isMaskOrGloveDesc: boolean = getChargeItem.indexOf('mask') > -1 && getChargeItem.indexOf('glove') > -1;
        const maxQtyBaseOnDesc: number = isMaskOrGloveDesc ? 50 : 20;
        if (marker.quantity > maxQtyBaseOnDesc) {
          overMaxQtyItem.push({ item: marker.chargeItem, page: pageNumber, quantity: marker.quantity });
        }
      }
    }
    return overMaxQtyItem;
  }

  openWarnMsgDialog(submitStatus: 'D' | 'S', qtyOverMaxArr: { [key: string]: string | number }[]): void {
    const DIALOG_DATA: IDialogData = {
      warnType: []
    };

    if (qtyOverMaxArr.length > 0) {
      DIALOG_DATA.warnType.push('quantity');
    }

    if (this.hasQtyWarningArr.indexOf(true) > -1) {
      DIALOG_DATA.warnType.push('insufficient');
    }

    if (!qtyOverMaxArr && this.hasQtyWarningArr.indexOf(true) < 0) {
      DIALOG_DATA.warnType.push('non-input-change');
    }

    const dialogConfig: { [key: string]: any | any[] } = {
      maxHeight: '700px',
      minWidth: '600px',
      data: DIALOG_DATA
    };

    this.matDialog.open(ChargeSheetWarningDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: (isDialogConfirm: boolean) => {
          if (isDialogConfirm) {
            this.submitDataEntry(submitStatus);
          }
        }
      });
  }

  submitDataEntry(status: 'S' | 'D'): void {
    const chargeSheetId: number = this.chargeSheetForm.get('id').value;
    const selectedMarkingDetail: { [index: number]: SelectedMarking[] } = this.chargeSheetForm.get('selectedMarkings').value;

    this.chargeSheetService.submitDataEntry(chargeSheetId, selectedMarkingDetail, status, this.chargeItemAsMiscById)
          .subscribe({
            next: (res: IDataEntryRes[]) => {
              if (status === 'D') {
                this.openSnackBar('Successfully saved as draft', false);
              } else {
                this.openSnackBar('Redirect to In Queue List', false);
                this.router.navigate([`/charge-sheet/submitted-list`], { queryParams: { tab: 0 } }).then();
              }
            },
            error: (errMsg: string) => {
              this.openSnackBar(errMsg, true);
            }
          });
  }

  validateMarkerError(): boolean {
    const errorArr: boolean[] = [];
    this.errorMarkers.forEach((value: IItemInputChecker, key, map) => {
      const hasDetail: boolean = Object.keys(value).indexOf('details') > -1;
      const hasDisabled: boolean = Object.keys(value).indexOf('isDisabled') > -1;
      const HAS_INSUFFICIENT: boolean =  Object.keys(value).indexOf('isInsufficient') > -1;
      const MARKER_IS_DISABLED: boolean = hasDisabled ? value.isDisabled : false;

      if (MARKER_IS_DISABLED) {
        return;
      }

      if (HAS_INSUFFICIENT) {
        this.hasQtyWarningArr.push(value.isInsufficient);
      }

      if (hasDetail) {
        const markerDetail: any[] = value.details;
        const hasError = markerDetail.filter(detail => !detail.startTime || !detail.endTime);
        errorArr.push(value.code && hasError.length < 1);
      } else {
        errorArr.push(value.code && value.quantity);
      }
    });
    return errorArr.indexOf(false) > -1;
  }

  handleErrorCorrected(markerChanged: { type: 'code', id: null, isErrorCorrected: null, marker: null }): void {
    this.errorMarkers.set(markerChanged.id, markerChanged.isErrorCorrected);
    if (markerChanged.marker) {
      this.setErrorChanges(markerChanged.marker);
    }
  }

  handleChargeSheetLoad(chargeSheetData: IChargeSheetAndChecker) {
    const chargeSheet: ChargeSheet = chargeSheetData.data;
    this.errorMarkers = chargeSheetData.ItemInputCheckerById;

    this.csStatusMsg = this.chargeSheetService.setChargeSheetStatus(chargeSheet.dischargeStatus, chargeSheet.billFinalizedStatus);
    this.chargeSheetService.setChargeSheetHeader(this.chargeSheetForm.controls, chargeSheet);
  }

  handleMarkingChange(selectedMarker) {
    setTimeout(() => {
      this.chargeSheetForm.get('selectedMarkings').patchValue(selectedMarker);
      this.buttonAction = 'idle';
    }, 0);
  }

  handleDisableMarker(markerChanged: { marker: null, isDisabled: null }): any {
    const selectedMarker: SelectedMarking = markerChanged.marker;
    const selectedMarkings: any[] = this.chargeSheetForm.get('selectedMarkings').value;
    const markerPage: number = selectedMarker.page;
    for (const marker of selectedMarkings[markerPage]) {
      if (marker.id === selectedMarker.id) {
        return Object.assign(selectedMarker, {isDisabled: markerChanged.isDisabled});
      }
    }
  }

  handleMiscChargeItem(miscInfo: Map<string, IMiscInfo>): any {
    this.chargeItemAsMiscById = miscInfo;
  }

  setErrorChanges(marker: SelectedMarking): any {
    const detail = {id: marker.id, chargeCode: marker.chargeCode, chargeItem: marker.chargeItem, chargeItemType: marker.chargeItemType};
    if (marker.quantity) {
      Object.assign(detail, {quantity: marker.quantity});
    }

    if (!this.errorChanges.get('details')) {
      return this.errorChanges.set('details', [detail]);
    }

    const getErrorChangesDetail: any[] = this.errorChanges.get('details');
    const getIndex = getErrorChangesDetail.findIndex((value, index) => value.id === marker.id);

    if (Object.keys(marker).indexOf('chargeToWard') > -1) {
      Object.assign(detail, {ward: marker.chargeToWard.ward});
    }

    if (getIndex >= 0) {
      return Object.assign(getErrorChangesDetail[getIndex], detail);
    } else {
      return this.errorChanges.get('details').push(detail);
    }
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
