import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChargeSheetService} from '@services/chargesheet.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ChargeSheet} from '@models/chargesheet.model';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-waiting-list',
  templateUrl: 'waiting.component.html'
})

export class WaitingComponent implements OnInit {
  @Output() listLoading: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Input() currentUserId: number;
  list: any[] = [];
  chargeSheetForm: FormGroup;
  submissionId: number = null;

  constructor(private chargeSheetService: ChargeSheetService,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.chargeSheetForm = this.formBuilder.group({
      id: [''],
      episodeNumber: [{ value: '', disabled: true }, Validators.required],
      backDateCharging: [{value: '', disabled: true}, Validators.required],
      serialNumber: [{value: '', disabled: true}],
      ward: [{ value: '', disabled: true }, Validators.required],
      dateTime: [{ value: '', disabled: true }, Validators.required],
      marking: [{ value: [] }],
      mrnNumber: [''],
      updatedDateTime: [{ value: '', }]
    });

    this.activatedRoute.params.subscribe({
      next: (queryParams) => this.submissionId = queryParams.submissionId
    });
  }

  handleChargeSheetLoad(chargeSheetData: { data: null, hasErrorMarker: null }): void {
    const chargeSheet: ChargeSheet = chargeSheetData.data;
    this.chargeSheetService.setChargeSheetHeader(this.chargeSheetForm.controls, chargeSheet);
  }

  handleMarkingChange(e): void {
    // console.log('[DEBUGGING] HANDLE MARKING CHANGE - WAITING LIST');
  }
}
