import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';

import { AuthService } from '@services/auth.service';
import { AppService } from '@services/app.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  titledVersion: string = '';
  appVersion: string = '';

  loginForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';
  returnUrl = '';
  hidePass: boolean = true;

  selectedFacility: string;
  facilities: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService,
    private matSnackBar: MatSnackBar) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required ],
      password: ['',  Validators.required],
      facility: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/charge-sheet/dashboard';
    this.titledVersion = this.appService.titledAppVersion;
    this.appVersion = this.appService.appVersion;
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (!this.loginForm.valid) {
      // console.log(this.loginForm);
      return;
    }

    this.loading = true;
    this.loginForm.disable();

    this.authService.login(this.f.username.value, this.f.password.value, this.f.facility.value)
      .subscribe({
        next: () => {
          this.openSnackBar('Login Successfully.', false);
          this.authService.assignFacility(this.f.facility.value);
          this.router.navigate([this.returnUrl]).then(() => this.appService.openSideBar());
        },
        error: (errorMsg: string) => {
          this.openSnackBar(errorMsg, true);
          this.error = errorMsg;
          this.loginForm.enable();
          this.loading = false;
        }
      });
  }

  handleFacilityChange(mSelectFacility: MatSelectChange): void {
    const SELECTED_FACILITY: string = mSelectFacility.value;

    this.loginForm.get('facility').setValue(SELECTED_FACILITY);
  }

  private openSnackBar(message: string, isError) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }

  handleLoginSubmit() {
    return {
      filter: this.loading ? 'blur(5px)' : 'initial'
    } ;
  }

  handleOnBlurUsername(): void {
    const USERNAME: string = this.f.username.value;

    this.authService.getADFacility(USERNAME)
    .pipe(
      tap(_ => this.facilities = [])
      )
    .subscribe({
      next: (facilities: string[]) => {
        this.facilities = facilities;
        if (facilities.length === 1) {
          this.loginForm.get('facility').setValue(facilities[0], { onlySelf: true });
        }
      }
    })
  }
}
