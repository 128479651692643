import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, BehaviorSubject, interval, throwError} from 'rxjs';

import {ErrorDetail, IMiscInfo, IWarehouseQty, Marking, MarkingDetail, SelectedMarking, SubmittedMarking, SubmittedMarkingDetail} from '@models/marking.model';
import {ChargeCode, ChargeSheet, SubmittedChargeSheet} from '@models/chargesheet.model';
import {Ward} from '@models/ward.model';
import {formatDate} from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ChargeSheetService {
  private currentChargeSheetSubject: BehaviorSubject<any>;
  public currentChargeSheet: Observable<any>;

  constructor(private httpClient: HttpClient, @Inject(LOCALE_ID) private locale: string) {
    this.currentChargeSheetSubject = new BehaviorSubject<SubmittedChargeSheet | ChargeSheet>(null);
    this.currentChargeSheet = this.currentChargeSheetSubject.asObservable();
  }

  getWardList(): Observable<Ward[]> {
    return this.httpClient.get<Ward[]>(`api/ward`);
  }

  public set chargeSheet(chargeSheet: SubmittedChargeSheet | ChargeSheet) {
    this.currentChargeSheetSubject.next(chargeSheet);
  }

  public get chargeSheet(): SubmittedChargeSheet | ChargeSheet {
    return this.currentChargeSheetSubject.value;
  }

  setChargeSheetHeader(chargeSheetForm: any, chargeSheetData: ChargeSheet): any {
    Object.keys(chargeSheetForm).map((key) => {
      if (key === 'dateTime') {
        const date = formatDate(chargeSheetData.createdAt, 'dd/MM/yyyy', 'en-gb');
        const time = new Date(chargeSheetData.createdAt).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
        return chargeSheetForm[key].setValue(`${date} ${time}`);
      }

      if (key === 'backDateCharging') {
        const PURE_FORMATE_DATE = new Date(chargeSheetData.chargeDate);
        return chargeSheetForm[key].setValue(PURE_FORMATE_DATE);
      }

      if (key === 'marking') {
        return chargeSheetForm[key].setValue(chargeSheetData.markings);
      }

      if (key === 'clerkId') {
        return chargeSheetForm[key].setValue(parseInt(chargeSheetData.createdBy as string, 10));
      }

      return chargeSheetForm[key].setValue(chargeSheetData[key]);
    });
  }

  setChargeSheetStatus(isPatientDischarge: boolean, isBillFinalize: boolean): string {
    let displayMsg: string = '';

    if (isPatientDischarge && !isBillFinalize) {
      displayMsg = 'Discharged';
    }

    if (!!isPatientDischarge && isBillFinalize) {
      displayMsg = 'Bill Finalized';
    }

    return displayMsg;
  }

  getDashboard(type = 'init'): Observable<any> {
    const getDashboardURL = type === 'today' ? `api/dashboard/today` : `api/dashboard`;

    return this.httpClient.get(getDashboardURL)
      .pipe(
        map((data = {attention: 0, inProgress: 0, success: 0, error: 0, history: []}) => {
          const newObj = {today: {}, history: []};

          if (!data) {
            return newObj;
          }

          Object.keys(data).map((key) => {
            if (key === 'history') {
              return Object.assign(newObj.history, data[key]);
            }

            Object.assign(newObj.today, this.setTodayTasks(key, data[key]));
          });
          return newObj;
        })
      );
  }

  getCumulativeDashboard(): Observable<any> {
    return this.httpClient.get('api/dashboard/cumulative');
  }

  setTodayTasks(taskName: string, taskValue: number): any {
    const todayTask = {};
    Object.assign(todayTask, {[taskName]: taskValue});
    return todayTask;
  }

  createNewEntry(wardName: string, attachmentId) {
    const body = {
      ward: wardName,
      attachmentId,
      status: 'D'
    };
    return this.httpClient.post(`api/charge-sheet`, body)
      .pipe(
        tap(_ => console.log('Submit New Charge Sheet Entry')),
      );
  }

  uploadAttachment(formData: FormData) {
    return this.httpClient.post(`api/attachment/upload`, formData)
      .pipe(
        tap(_ => console.log('Upload Attachment')),
      );
  }

  uploadAttachmentBase64(pdfFileArr: any[]) {
    const body = this.arrangePdfPage(pdfFileArr);
    return this.httpClient.post(`api/attachment/upload/base64`, body);
  }

  getChargeSheet(chargeSheetId: number, isDownload = false): Observable<ChargeSheet | string> {
    return this.httpClient.get<ChargeSheet>(`api/charge-sheet/${chargeSheetId}`)
      .pipe(
        tap(_ => console.log('Retrieve Charge Sheet')),
        map((data: ChargeSheet) => {
          if (isDownload) {
            return data.attachment.fileDownloadUri;
          }

          for (const marker of data.markings) {
            if (!marker.code) {
              marker.cropped_img = `data:image/png;base64,${marker.cropped_img}`;
            }
          }
          return data;
        })
      );
  }

  getUnassignedChargeSheet(pageNumber?: number, limit = 15): Observable<any> {
    return this.httpClient.get(`api/charge-sheet/list?status=D&createBy=SYSTEM&order=-createdAt&page=${pageNumber}&limit=${limit}`,
      {observe: 'response'});
  }

  getDraftedChargeSheet(userId: number, pageNumber?: number, limit = 15) {
    return this.httpClient.get(
      `api/charge-sheet/list?status=D&createBy=${userId}&order=-createdAt&page=${pageNumber}&limit=${limit}`, {observe: 'response'})
      .pipe(
        tap(_ => console.log('Retrieve Draft List'))
      );
  }

  duplicateChargeSheet(id: number): Observable<any> {
    const BODY = { id };
    return this.httpClient.post(`api/charge-sheet/duplicate`, BODY);
  }

  updateChargeSheetError(chargeSheetId: number, body: any): Observable<any> {
    return this.httpClient.put(`api/charge-sheet/${chargeSheetId}/error`, body);
  }

  removeDraftedChargeSheet(id: number): Observable<any> {
    return this.httpClient.delete(`api/charge-sheet/${id}`);
  }

  submit(id: number, episodeNo: string, ward: string, clerkId: number, status: string, lastViewPath: string, marking: SelectedMarking[], backDateCharging: string) {
    const FORMATTED_CHARGE_DATE: string = formatDate(backDateCharging, 'yyyy-MM-dd', 'en-gb');
    const body = {
      episodeNumber: episodeNo,
      clerkId,
      ward,
      status,
      lastViewPath,
      chargeDate: FORMATTED_CHARGE_DATE,
      markings: this.rearrangeMarkings(marking)
    };
    return this.httpClient.put(`api/charge-sheet/${id}`, body);
  }

  submitDataEntry(id: number, markings: { [index: number]: SelectedMarking[] }, status: string, chargeAsMiscByMarkingSelectedId: Map<string, IMiscInfo>): Observable<any> {
    const body = {
      chargeSheetId: id,
      details: this.rearrangeSelectedMarkings(markings, chargeAsMiscByMarkingSelectedId),
      status
    };

    if (body.details.length === 0 && status === 'S') {
      return throwError('Unable the capture data, please refresh the page.');
    }
    return this.httpClient.post(`api/charge-sheet/markings/details`, body);
  }

  getSingleChargeItemWarehouseQty(chargeCode: string, ward: string, markingSelectedId: number): Observable<IWarehouseQty> {
    const BODY = {
      chargeItemCode: chargeCode,
      ward,
      chargeSheetMarkingSelectedId: markingSelectedId
    };

    return this.httpClient.post<IWarehouseQty>('api/item/warehouse-qty', BODY);
  }

  getWardItemQuantity(chargeCode: string, quantity: number): Observable<any> {
    return this.httpClient.get(`api/item/inventory?code=${chargeCode}&quantity=${quantity}`);
  }

  getSubmittedList(userId: number, submittedStatus: string, pageNumber: number, limit = 15): Observable<any> {
    return this.httpClient
      .get(`api/submission?status=${submittedStatus}&createBy=${userId}&order=-createdAt&page=${pageNumber}&limit=${limit}`,
        {observe: 'response'});
  }

  getSubmittedChargeSheet(submissionId: number): Observable<any> {
    return this.httpClient.get(`api/submission/attention/${submissionId}`)
      .pipe(
        map((chargeSheet: SubmittedChargeSheet) => {
          return this.assignChargeSheetData(chargeSheet);
        })
      );
  }

  setStopRobotProcess(id: number): Observable<any> {
    return this.httpClient.put(`api/submission/${id}/stop`, null);
  }

  updateAttentionChargeSheet(chargeSheetId: number,
                             data: string | Map<'details', ErrorDetail[]>,
                             updateType: 'episode-no' | 'charge-sheet',
                             chargeItemAsMiscById: Map<string, IMiscInfo>): Observable<any> {
    const body = {id: chargeSheetId};
    if (updateType === 'episode-no') {
      Object.assign(body, {episodeNumber: (data as string)});
    }

    if (updateType === 'charge-sheet') {
      const reassignErrorDetail = (data as Map<'details', ErrorDetail[]>).size === 0 ? [] : this.rearrangeAttentionMarker((data as Map<'details', ErrorDetail[]>).get('details'), chargeItemAsMiscById);
      Object.assign(body, {details: reassignErrorDetail});
    }
    return this.httpClient.post(`api/submission/attention`, body);
  }

  setUrgentChargeSheet(submissionId: number): Observable<any> {
    return this.httpClient.put(`api/submission/${submissionId}/priority`, {});
  }

  rearrangeAttentionMarker(errorDetail: ErrorDetail[], chargeItemAsMiscById: Map<string, IMiscInfo>): ErrorDetail[] {
    const newArr = [];
    for (const detail of errorDetail) {
      const HAS_STATUS = Object.keys(detail).filter((value) => value === 'status').length > 0;

      if (HAS_STATUS) {
        const NEW_OBJ = { id: detail.id, status: detail.status };

        if (Object.keys(detail).indexOf('removeReason') > -1) {
          Object.assign(NEW_OBJ, { removeReason: detail.removeReason });
        }

        newArr.push(NEW_OBJ);
      } else {
        let NEW_OBJ = detail;

        if (Object.keys(detail).indexOf('startTime') > -1 && Object.keys(detail).indexOf('endTime') > -1) {
          const { startTime, endTime } = this.formatDateTime(detail.startTime as Date, detail.startTime, detail.endTime);
          detail.startTime = startTime;
          detail.endTime = endTime;
        }

        if (chargeItemAsMiscById.has((detail.id as string))) {
          const MISC_CODE: string = chargeItemAsMiscById.get((detail.id as string)).code;
          const MISC_DESC: string = chargeItemAsMiscById.get((detail.id as string)).desc;

          Object.assign(NEW_OBJ, { miscChargeItem: MISC_DESC, miscChargeCode: MISC_CODE });
        }

        newArr.push(detail);
      }
    }
    return newArr;
  }

  assignChargeSheetData(data: SubmittedChargeSheet): ChargeSheet {
    const totalPages: number = data.attachment.details.length;
    return Object.assign({}, {
      attachment: data.attachment,
      billFinalizedStatus: data.billFinalizedStatus,
      serialNumber: data.serialNumber,
      chargeDate: data.chargeDate,
      createdBy: data.createdBy,
      dischargeStatus: data.dischargeStatus,
      createdAt: data.createdAt,
      episodeNumber: data.episodeNumber,
      mrnNumber: data.mrnNumber,
      ward: data.ward,
      status: data.status,
      id: data.chargeSheetId,
      markings: this.simpleCloneValue(this.assignSubmittedMarkings(data.details, totalPages)),
      markingsSelected: this.simpleCloneValue(this.setSubmittedSelectedMarkings(data.details)),
      warehouseDetails: data.warehouseDetails,
      lastViewPath: null,
      updateAt: null,
      remark: data.remark,
      submittedChargeSheetId: data.id,
      patientName: data.patientName
    });
  }

  assignSubmittedMarkings(data: SubmittedMarkingDetail[], totalPages: number): Marking[] {
    const newArr = [];

    for (const detail of data) {
      const duplicateMarker = newArr.filter((markerDetail: SubmittedMarkingDetail) => {
        const isSameAxis: boolean =
          (markerDetail.marking.x_axis === detail.marking.x_axis) && (markerDetail.marking.y_axis === detail.marking.y_axis);
        return (markerDetail.chargeCode === detail.chargeCode) && (isSameAxis);
      });

      if (duplicateMarker.length === 0) {
        newArr.push(detail);
      }
    }

    return newArr.map((markingDetail, index, detailArr) => {
      return {
        border_color: markingDetail.marking.border_color,
        marker_width: markingDetail.marking.marker_width,
        marker_height: markingDetail.marking.marker_height,
        page: markingDetail.marking.page,
        x_axis: markingDetail.marking.x_axis,
        y_axis: markingDetail.marking.y_axis,
        quantity: markingDetail.quantity,
        items: markingDetail.chargeItem,
        code: markingDetail.chargeCode,
        adjustedWidth: null,
        adjustedHeight: null,
        id: markingDetail.id,
        status: markingDetail.status,
        detail: [this.singleMarkingDetail(markingDetail)],
        removeReason:  markingDetail.removeReason,
        isChargeAsMisc: null,
        marking_type: markingDetail.marking_type
      };
    });
  }

  setSubmittedSelectedMarkings(data: SubmittedMarkingDetail[]): SelectedMarking[] {
    const chargeCodeKeyObj: { [key: string]: any[] } = this.setDetailObj(data);
    const selectedMarkerArr: SelectedMarking[] = [];

    Object.keys(chargeCodeKeyObj).map((key: string) => {
      const firstMarkingDetail: SubmittedMarkingDetail = chargeCodeKeyObj[key][0];
      const selectedMarkerBase: SelectedMarking = this.setSubmittedSelectedMarkingBase(firstMarkingDetail);

      if (chargeCodeKeyObj[key].length === 1) {
        const newData = this.singleMarkingDetail(firstMarkingDetail);
        selectedMarkerBase.detail.push(newData);
      }

      if (chargeCodeKeyObj[key].length > 1) {
        for (const markingDetail of chargeCodeKeyObj[key]) {
          const newData = this.singleMarkingDetail(markingDetail);
          selectedMarkerBase.detail.push(newData);
        }
      }

      selectedMarkerArr.push(selectedMarkerBase);
    });
    return selectedMarkerArr;
  }

  setDetailObj(markerDetailArr: SubmittedMarkingDetail[]): { [key: string]: any[] } {
    const detailObj = {};
    const detailKeyArr: string[] = [];
    for (const detail of markerDetailArr) {
      if (detailObj[detail.chargeCode]) {
        detailObj[detail.chargeCode].push(detail);
      }

      if (!detailObj[detail.chargeCode]) {
        detailKeyArr.push(detail.chargeCode);
        Object.assign(detailObj, {[detail.chargeCode]: [detail]});
      }
    }

    Object.keys(detailObj).map((chargeCode) => {
      let differentAxisMarking: { [key: string]: SubmittedMarkingDetail[] } = {};
      if (detailObj[chargeCode].length > 1) {
        differentAxisMarking = this.multipleCoordinateMarker(detailObj[chargeCode]);
      }

      if (Object.keys(differentAxisMarking).length > 0) {
        delete detailObj[chargeCode];
        Object.assign(detailObj, differentAxisMarking);
      }
    });

    return detailObj;
  }

  multipleCoordinateMarker(markerDetailArr: SubmittedMarkingDetail[]): { [key: string]: SubmittedMarkingDetail[] } {
    const detailLength: number = markerDetailArr.length;
    const newDetailObj: { [key: string]: SubmittedMarkingDetail[] } = {};
    let duplicateCounter = 0;

    for (let index = 0; detailLength > index; index++) {
      const currentDetailMarking: SubmittedMarking = markerDetailArr[index].marking;
      const filterSameAxisMarking = markerDetailArr.filter((markerDetail) => {
        const filterLoopMarking: SubmittedMarking = markerDetail.marking;
        return (currentDetailMarking.x_axis === filterLoopMarking.x_axis) && (currentDetailMarking.y_axis === filterLoopMarking.y_axis);
      });

      if (filterSameAxisMarking.length <= 1) {
        Object.assign(newDetailObj, {[`${markerDetailArr[index].chargeCode}@${duplicateCounter++}`]: [...filterSameAxisMarking]});
      }

      if (filterSameAxisMarking.length > 1) {
        Object.assign(newDetailObj, { [markerDetailArr[index].chargeCode]: [...filterSameAxisMarking] });
      }
    }
    return newDetailObj;
  }

  singleMarkingDetail(markerData: SubmittedMarkingDetail): MarkingDetail {
    const IS_CHARGE_AS_MISC: boolean = !!markerData.miscChargeCode && !!markerData.miscChargeItem;

    const detail = {
      chargeCode: markerData.chargeCode,
      chargeItem: markerData.chargeItem,
      chargeSheetMarkingSelectedId: markerData.id,
      status: markerData.status,
      remark: markerData.remark,
      isChargeAsMisc: IS_CHARGE_AS_MISC,
      miscChargeCode: markerData.miscChargeCode,
      miscChargeItem: markerData.miscChargeItem
    };
    if (markerData.quantity) {
      Object.assign(detail, {quantity: markerData.quantity});
    }
    if (markerData.startTime) {
      Object.assign(detail, {
        startTime: markerData.startTime,
        endTime: markerData.endTime,
        date: markerData.startTime.split('T')[0]
      });
    }

    return detail;
  }

  setSubmittedSelectedMarkingBase(markerData: SubmittedMarkingDetail): SelectedMarking {
    return {
      border_color: markerData.marking.border_color,
      chargeCode: markerData.chargeCode,
      chargeItem: markerData.chargeItem,
      id: markerData.id,
      marker_width: markerData.marking.marker_width,
      marker_height: markerData.marking.marker_height,
      page: markerData.marking.page,
      y_axis: markerData.marking.y_axis,
      x_axis: markerData.marking.x_axis,
      resolutionWidth: markerData.marking.resolutionWidth,
      resolutionHeight: markerData.marking.resolutionHeight,
      sequence: markerData.marking.sequence,
      quantity: markerData.quantity,
      detail: [],
      status: markerData.status,
      removeReason: markerData.removeReason,
      chargeItemType: markerData.type,
      updatedAt: markerData.updatedAt,
      marking_type: markerData.marking_type
    };
  }

  resubmitChargeSheet(submissionId: number): Observable<any> {
    const body = {id: submissionId};
    return this.httpClient.post(`api/cyclone`, body);
  }

  searchChargeItemDetail(code: string, page: number, limitNumber: number, criteria = 'code'): Observable<ChargeCode[]> {
    return this.httpClient.get<ChargeCode[]>(`api/item?code=${code}&page=${page}&limit=${limitNumber}`);
  }

  searchMiscItem(): Observable<ChargeCode[]> {
    return this.httpClient.get<ChargeCode[]>(`api/misc-item`);
  }

  searchSubmittedListDetail(
    criteriaObj: {status?: string, episodeNumber?: string, createBy?: number}, pageNumber: number, limit = 15): Observable<any> {
    let queryStr = ``;
    const criteriaArr: string[] = Object.keys(criteriaObj);

    criteriaArr.map((key: string, index: number) => {
      queryStr += `${key}=${criteriaObj[key]}`;
      if ((index + 1) !== criteriaArr.length) {
        queryStr += '&';
      }
      return queryStr;
    });

    return this.httpClient.get(`api/charge-sheet/search?${queryStr}&order=-createdAt&page=${pageNumber}&limit=${limit}`, {observe: 'response'});
   }

  updateChargeSheetToArchive(submissionId: number): Observable<any> {
    const body = { status: 'ARC' };
    return this.httpClient.put(`api/submission/${submissionId}/status`, body);
  }

  rearrangeMarkings(markers: SelectedMarking[]): SelectedMarking[] {
    const markerArr: SelectedMarking[] = [];
    Object.keys(markers).map((key) => {
      for (const [index, marker] of markers[key].entries()) {
        const selectedMarker: SelectedMarking = {
          chargeCode: marker.chargeCode,
          chargeItem: marker.chargeItem ? marker.chargeItem : null,
          cropped_img: null,
          x_axis: marker.x_axis,
          y_axis: marker.y_axis,
          marker_width: marker.marker_width,
          marker_height: marker.marker_height,
          sequence: index + 1,
          border_color: marker.border_color,
          page: marker.page,
          isChargeAsMisc: marker.isChargeAsMisc,
          marking_type: marker.marking_type
        };

        if (marker.detail) {
          Object.assign(selectedMarker, { id: marker.id });
        }

        markerArr.push(selectedMarker);
      }
    });
    return markerArr;
  }

  rearrangeSelectedMarkings(selectedMarkers: { [index: number]: SelectedMarking[] }, chargeAsMiscByMarkingSelectedId: Map<string, IMiscInfo>): MarkingDetail[] {
    const markerArr: MarkingDetail[] = [];
    Object.keys(selectedMarkers).map((pageNumber) => {
      for (const marker of selectedMarkers[pageNumber]) {
        let markingSelectedToSubmit = {
          chargeItem: marker.chargeItem,
          chargeCode: marker.chargeCode,
          chargeSheetMarkingSelectedId: marker.id,
          chargeItemType: marker.chargeItemType
        }

        const HAS_MISC_CHARGING: boolean =
          chargeAsMiscByMarkingSelectedId.has(marker.id) ? chargeAsMiscByMarkingSelectedId.has(marker.id) : !!marker.miscChargeCode && !!marker.miscChargeItem;

        let detailBody = this.setMarkerDetail(marker.detail, markingSelectedToSubmit);
        const IS_CHARGE_TYPE_QTY: boolean = (marker.chargeItemType === 'QUANTITY' || marker.chargeItemType === 'PERCENTAGE');

        // Quantity
        if (IS_CHARGE_TYPE_QTY) {
          const IS_QTY_MARKER_NOT_NULL = marker.quantity !== null;
          let marker_qty = null;

          if (IS_QTY_MARKER_NOT_NULL) {
            marker_qty = marker.quantity;
          } else {
            const MARKINGS_SELECTED: SelectedMarking[] = (this.chargeSheet as ChargeSheet).markingsSelected;

            MARKINGS_SELECTED.filter(marking => {
              const MARKING_DETAILS: MarkingDetail[] = marking.detail;
              const MARKINGS_SELECTED_ID: number = marking.id;

              if (MARKINGS_SELECTED_ID === marker.id && MARKING_DETAILS.length > 0) {
                const MARKING_DETAIL: MarkingDetail = MARKING_DETAILS[0];
                marker_qty = MARKING_DETAIL.quantity;
                return ;
              }
              return ;
            })
          }

          Object.assign(detailBody, {quantity: marker_qty});
        }

        // Misc Charging
        if (HAS_MISC_CHARGING) {
          const SELECTED_MARKING_MISC_CHARGING: IMiscInfo = chargeAsMiscByMarkingSelectedId.get(marker.id);
          const IS_MISC_CHARGING_NOT_NULL: boolean = !!SELECTED_MARKING_MISC_CHARGING;
          let misc_desc: string = null;
          let misc_code: string = null;

          if (IS_MISC_CHARGING_NOT_NULL) {
            misc_code = SELECTED_MARKING_MISC_CHARGING.code;
            misc_desc = SELECTED_MARKING_MISC_CHARGING.desc;
          } else {
            misc_code = marker.miscChargeCode;
            misc_desc = marker.miscChargeItem;
          }

          if (!!misc_code && !!misc_desc) {
            Object.assign(detailBody, { miscChargeItem: misc_desc, miscChargeCode: misc_code });
          }
        }

        if (Array.isArray(detailBody)) {
          markerArr.push(...detailBody);
        }

        if (!marker.isDisabled && !Array.isArray(detailBody)) {
          markerArr.push(detailBody);
        }
      }
    });
    return markerArr;
  }

  setMarkerDetail(
    markerDetail: MarkingDetail[],
    detailBody: { chargeItem: string, chargeCode: string, chargeSheetMarkingSelectedId: number, chargeItemType?: 'TIME' | 'QUANTITY' | 'PERCENTAGE' }): any {
    let objDetail: any[] | {} = null;
    if (Array.isArray(markerDetail) && detailBody.chargeItemType === 'TIME') {
      if (markerDetail.length > 1) {
        (objDetail as any[]) = [];
        for (const detail of markerDetail) {
          const {startTime, endTime} = this.formatDateTime(detail.date, detail.startTime, detail.endTime);
          Object.assign(detailBody, {
            endTime,
            startTime,
          });
          (objDetail as any[]).push(JSON.parse(JSON.stringify(detailBody)));
        }
      } else {
        objDetail = detailBody;
        if (markerDetail.length === 1) {
          const {startTime, endTime} = this.formatDateTime(markerDetail[0].date, markerDetail[0].startTime, markerDetail[0].endTime);
          Object.assign(objDetail, {
            endTime,
            startTime,
          });
        } else {
          objDetail = detailBody;
        }
      }
    } else {
      objDetail = detailBody;
    }
    return objDetail;
  }

  formatDateTime(date: Date, startTime: any, endTime: any): { date: string, startTime: string, endTime: string } {
    // DateTime Value use Moment.js
    const startTimeStr = moment(startTime).isValid() ? formatDate(startTime, 'yyyy-MM-dd HH:mm:00', 'en-gb') : null;
    const endTimeStr = moment(endTime).isValid() ? formatDate(endTime, 'yyyy-MM-dd HH:mm:00', 'en-gb') : null;
    const dateStr = startTime ? startTimeStr.split(',')[0] : null;
    return {
      date: dateStr,
      startTime: startTimeStr,
      endTime: endTimeStr
    };
  }

  arrangePdfPage(attachments: any[]): any[] {
    const pdfArr: any[] = [];
    for (const [index, attachment] of attachments.entries()) {
      pdfArr.push({file: attachment.base64Str, index: index + 1});
    }
    return pdfArr;
  }

  simpleCloneValue(value: any): any {
    return JSON.parse(JSON.stringify(value));
  }

  autoRefreshList(): Observable<any> {
    const fiveMinute = interval(300000);
    return fiveMinute
      .pipe(
        catchError((err) => this.errorHandler(err))
      );
  }

  errorHandler(error: HttpErrorResponse): Observable<any> {
    return throwError(error.message || 'Server Error');
  }
}

