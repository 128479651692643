import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-correct',
  templateUrl: './correct.component.html',
  styleUrls: ['./correct.component.scss']
})
export class CorrectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
