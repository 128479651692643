import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ChargeSheetService} from '@services/chargesheet.service';
import {HttpResponse} from '@angular/common/http';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-search-by-criteria',
  templateUrl: './search-by-criteria.component.html',
  styleUrls: ['./search-by-criteria.component.scss']
})
export class SearchByCriteriaComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchType: string;
  @Input() searchCriteriaArr: string[] = [];
  @Input() searchBarAlign = 'left';
  @Input() showSearchDetail = false;
  @Input() searchDetailPosition: 'right' | 'bottom' = 'right';
  @Input() hasRecord: boolean;
  @Input() value: string;
  @Output() dataResult: EventEmitter<any> = new EventEmitter<any>();
  inputValue = new FormControl('');

  displaySearchValue: string;
  displaySearchTotal: number;
  searchBarInlineStyle: string;
  searchDetailInlineStyle: string;
  searchHistory: { currentValue: any, previousValue: any } = {currentValue: null, previousValue: null};

  constructor(private chargeSheetService: ChargeSheetService, private matSnackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.searchBarInlineStyle = `text-align: ${this.searchBarAlign}`;
    this.searchDetailInlineStyle = `display: ${this.searchDetailPosition === 'bottom' ? 'block' : 'inline-block'}`;

    if (this.value) {
      this.inputValue.setValue(this.value);
      this.handleSubmitSearch(true, this.inputValue.value)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const hasRecordChanges = changes.hasRecord;
    if (hasRecordChanges) {
      const differValue = hasRecordChanges.currentValue !== hasRecordChanges.previousValue;
      if (differValue) {
        this.setDisplaySearchResult(hasRecordChanges.currentValue, this.searchHistory);
      }
    }
  }

  setDisplaySearchResult(hasRecord: boolean, searchHistory: { currentValue: string, previousValue: string }): void {
    if (hasRecord && searchHistory.currentValue !== searchHistory.previousValue) {
      this.displaySearchValue = searchHistory.currentValue;
    }

    if (hasRecord === false && searchHistory.currentValue !== searchHistory.previousValue) {
      this.displaySearchValue = `No record for "${this.searchHistory.currentValue}"`;
    }
  }

  handleSubmitSearch(isSubmit: boolean = false, searchValue?: string): any {
    if (!this.inputValue.value && !searchValue) {
      this.openSnackBar('Please enter Episode Number.', true);
      return;
    }

    const getCriteriaObj = this.searchCriteriaArr.length === 1 ?
      {[this.searchCriteriaArr[0]]: this.inputValue.value} : this.arrangeCriteriaArrToObj();

    if (isSubmit) {
      this.searchHistory.previousValue = this.searchHistory.currentValue;
      this.searchHistory.currentValue = this.inputValue.value;

      this.displaySearchValue = `Searching ...`;
    }

    this.dataResult.emit({
      criteriaObj: getCriteriaObj,
      searchValue: this.inputValue.value,
      searchNew: this.searchHistory.currentValue === this.searchHistory.previousValue
    });

    return;
  }

  arrangeCriteriaArrToObj(): any {
    return {};
  }

  clearInputField(): any {
    return this.inputValue.reset();
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }

  ngOnDestroy(): void {
    this.searchBarAlign = 'left';
    this.searchType = null;
    this.searchCriteriaArr = [];
    this.displaySearchValue = null;
    this.displaySearchTotal = 0;

    this.clearInputField();
  }
}
