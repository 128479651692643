import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';

import { MaterialModule } from './material.module';

// import { fakeBackendProvider } from '../shared/helpers/fake-backend';
// import { BasicAuthInterceptor } from '../shared/helpers/basic-auth.interceptor';
// import { ErrorInterceptor } from '../shared/helpers/error.interceptor';

import { AuthService } from '@services/auth.service';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { RequestpasswordComponent } from './requestpassword/requestpassword.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetpasswordComponent,
    RequestpasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // fakeBackendProvider,
  ]
})
export class AuthModule { }
