import { FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class ResetPasswordMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {        
        let invalidCtrl;
        let invalidParent;
        
        if(!control.untouched) {
            invalidCtrl = !!(control && control.invalid);
            invalidParent = !!(control && control.parent && control.parent.invalid);
        }

        if(form.submitted) {
            invalidCtrl = !!(control && control.invalid);
            invalidParent = !!(control && control.parent && control.parent.invalid);
        }

        return (invalidCtrl || invalidParent);
    }  
}
