import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { IConfiguration, ConfigType } from '@models/configuration.model';

@Component({
  selector: 'app-settings-config',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})

export class ConfigurationComponent implements OnInit {
  CONFIG: Map<typeof ConfigType, IConfiguration> = new Map<typeof ConfigType, IConfiguration>();
  CONFIG_ALIGN_SEQUENCE: typeof ConfigType[] = ['CREDENTIAL', 'SETTING', 'EMAIL'];
  CONFIG_TITLE: { [key: string]: string } = {
    'CREDENTIAL': 'Cerebral Plus Credential',
    'SETTING': 'Miscellaneous Charging',
    'EMAIL': 'Weekly Miscellanous Report'
  };
  CONFIG_PANEL_EXPANDED: { [key: string]: boolean } = {};

  get credentialUsername(): string { return this.CONFIG.get('CREDENTIAL').value; };

  get isMiscChargeEnabled(): boolean { return this.CONFIG.get('SETTING').value === 'YES'; }

  get totalMiscReportEmail(): number { return this.CONFIG.get('EMAIL').value !== '' && this.CONFIG.get('EMAIL').value !== null ? this.CONFIG.get('EMAIL').value.split(';').length : 0; }

  constructor(private configService: ConfigurationService) { }

  ngOnInit(): void {
    this.CONFIG_ALIGN_SEQUENCE.forEach((configType: typeof ConfigType) => {
      Object.assign(this.CONFIG_PANEL_EXPANDED, { [configType]: false });
    });

    this.configService.retrieveAllConfig()
      .subscribe({
        next: (res: IConfiguration[]) => {
          this.assignConfigByCategory(res);
        }
      });
  }

  assignConfigByCategory(config: IConfiguration[]): void {
    config.forEach((config: IConfiguration) => {
      this.CONFIG.set(config.type, config);
    });
  }

  handleConfigUpdate(configUpdate: IConfiguration): void {
    this.CONFIG.set(configUpdate.type, configUpdate);
  }

  handleOnExpansionChange(isExpanded: boolean, configType: typeof ConfigType): void {
    if (Object.keys(this.CONFIG_PANEL_EXPANDED).indexOf(configType) > -1) {
      this.CONFIG_PANEL_EXPANDED[configType] = isExpanded;
    }
  }
}
