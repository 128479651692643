import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserComponent } from './user/user.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { CredentialComponent } from './configuration/credential/credential.component';
import { MiscellaneousChargingComponent } from './configuration/misc-charging/misc-charging.component';
import { MiscellaneousReportComponent } from './configuration/misc-report/misc-report.component';

import { MaterialModule } from './material.module';
import { SettingsRoutingModule } from './settings-routng.module';
import { SharedComponentsModule } from '@shared-components/shared-components.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    UserComponent,
    ConfigurationComponent,
    CredentialComponent,
    MiscellaneousChargingComponent,
    MiscellaneousReportComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SettingsRoutingModule,
    SharedComponentsModule,
    ReactiveFormsModule
  ]
})
export class SettingsModule { }
