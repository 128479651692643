import {AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChargeSheetService} from '@services/chargesheet.service';
import {debounceTime, tap} from 'rxjs/operators';
import {ChargeCode} from '@models/chargesheet.model';
import {MatSelectionListChange} from '@angular/material/list';
import { fromEvent } from 'rxjs';

@Component({
  templateUrl: 'search-charge-code-dialog.component.html',
  styleUrls: ['./search-charge-code-dialog.component.scss']
})

export class SearchChargeCodeDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('previewBarcodeImg') img: ElementRef<HTMLElement>;

  croppedImg: string = null;
  markerBorderColor: string = null;
  chargeCodeList: ChargeCode[] = [];
  selectedChargeCode: ChargeCode;
  loading = false;

  selectedCriteria = '0';
  criteriaStrArr = ['Charge Code', 'Description'];
  searchInputDelayMS: number = 2000;
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<SearchChargeCodeDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private chargeSheetService: ChargeSheetService) {
    this.croppedImg = data.marking.cropped_img;
    this.markerBorderColor = data.marking.border_color;
    this.selectedChargeCode = data.marking.chargeCode ?
      {code: data.marking.chargeCode, description: data.marking.chargeItem, status: 'ACTIVE'} : null;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Auto Focus on Search, using setTimeout is required.
    setTimeout(() => this.searchInput.nativeElement.focus());

    // const previewBarcodeImg: HTMLElement = this.img.nativeElement;
    // const previewBarcodeImgWidth: number = previewBarcodeImg.getBoundingClientRect().width;

    // previewBarcodeImg.style.width = `${(previewBarcodeImgWidth * 1.6)}px`;

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        tap(() => this.isLoading = this.searchInput.nativeElement.value.trim().length !== 0 ? true : false),
        debounceTime(this.searchInputDelayMS)
      )
      .subscribe({
        next: (searchKbEvent: KeyboardEvent) => {
          const searchInput: string = this.searchInput.nativeElement.value;

          if (searchInput.trim().length !== 0) {
            this.searchByCriteriaSub(searchInput);
          }
        }
      });
  }

  searchByCriteriaSub(searchInputValue: string): any {
    const PAGE_NUM: number = 1;
    const MAX_TOTAL: number = 100;

    this.chargeSheetService.searchChargeItemDetail(searchInputValue, PAGE_NUM, MAX_TOTAL).subscribe({
      next: (res: ChargeCode[]) => {
        this.chargeCodeList = res;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  handleChargeCodeChange(e: MatSelectionListChange): void {
    return this.selectedChargeCode = e.option.value;
  }

  handleConfirm(): void {
    this.dialogRef.close(this.selectedChargeCode);
  }
}
