import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChargeCode } from '@models/chargesheet.model';
import { SelectedMarking } from '@models/marking.model';
import { ChargeSheetService } from '@services/chargesheet.service';

import { SearchChargeCodeDialogComponent } from '@shared-components/search-charge-code-dialog/search-charge-code-dialog.component';

@Component({
  selector: 'app-misc-charge-input',
  templateUrl: 'marker-misc-charge-input.component.html',
  styleUrls: ['./marker-misc-charge-input.component.scss']
})

export class MarkerMiscChargeInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isChargeAsMisc: boolean;
  @Input() selectedMarker: SelectedMarking;
  @Input() isEditable: boolean;
  @Input() miscChargeCodeList: ChargeCode[];
  @Output() handleMiscChargeInputChecker: EventEmitter<FormGroup> = new EventEmitter();

  miscInfo: FormGroup;
  miscListByCode: Map<string, string> = new Map();
  miscSelectorIndex: number = null;

  searchChargeCodeDialog: MatDialogRef<SearchChargeCodeDialogComponent>;

  constructor(private matDialog: MatDialog, private chargeSheetService: ChargeSheetService) { }

  ngOnInit(): void {
    this.miscChargeCodeList.forEach((misc: ChargeCode) => {
      this.miscListByCode.set(misc.code, misc.description);
    });

    if (this.miscChargeCodeList.length === 1) {
      this.handleMiscChange(this.miscChargeCodeList[0].code);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isChargeAsMisc) {
      const IS_CHARGE_AS_MISC: boolean = changes.isChargeAsMisc.currentValue;
      if (IS_CHARGE_AS_MISC) {
        this.assignNewFGMiscInfo();
      } else {
        this.removeNewFGMiscInfo();
      }
    }

    if (changes.selectedMarker) {
      this.selectedMarker = changes.selectedMarker.currentValue;

      const IS_CHARGE_AS_MISC: boolean = this.selectedMarker.isChargeAsMisc;

      if (IS_CHARGE_AS_MISC) {
        this.miscInfo.get('code').setValue(this.selectedMarker.miscChargeCode);
        this.miscInfo.get('desc').setValue(this.selectedMarker.miscChargeItem);
      }
    }
  }

  ngOnDestroy(): void { }

  assignNewFGMiscInfo(): void {
    this.miscInfo = new FormGroup({
      desc: new FormControl(null, [Validators.required]),
      code: new FormControl({ value: null, disabled: !this.isEditable }, [Validators.required])
    });
  }

  removeNewFGMiscInfo(): void {
    this.miscInfo.removeControl('desc');
    this.miscInfo.removeControl('code');
  }

  handleMiscChange(miscCode: string): void {
    this.miscInfo.get('desc').patchValue(this.miscListByCode.get(miscCode));
    this.miscInfo.get('code').patchValue(miscCode);

    this.handleMiscChargeInputChecker.emit(this.miscInfo);
  }

  openSearchChargeCodeDialog(marking: SelectedMarking): void {
    this.searchChargeCodeDialog = this.matDialog.open(SearchChargeCodeDialogComponent, {
      minWidth: '650px',
      autoFocus: false,
      data: {
        marking
      }
    });

    this.searchChargeCodeDialog.afterClosed().subscribe({
      next: (data: ChargeCode) => {
        if (data) {
          this.miscInfo.get('desc').setValue(data.description, { emitEvent: false });
          this.miscInfo.get('code').setValue(data.code, { emitEvent: true });

          const CONTAIN_TYPE: boolean = Object.keys(data).indexOf('type') > -1;
          const IS_TYPE_TIME: boolean = CONTAIN_TYPE && data.type === 'TIME';
          const IS_TYPE_QUANTITY: boolean = CONTAIN_TYPE && data.type === 'QUANTITY';

          this.handleMiscChargeInputChecker.emit(this.miscInfo);
        }
      }
    });
  }
}
