import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChargeSheetService} from '@services/chargesheet.service';
import {UserService} from '@services/user.service';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {ChargeSheet} from '@models/chargesheet.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {first} from 'rxjs/operators';
import {ChargeSheetErrorDialogComponent} from '@shared-components/charge-sheet-error-dialog/charge-sheet-error-dialog.component';
import {Subscription} from 'rxjs';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {User} from '@models/user.model';

@Component({
  selector: 'app-unassigned',
  templateUrl: 'unassigned.component.html',
  styleUrls: ['./unassigned.component.scss']
})

export class UnassignedComponent implements OnInit, OnDestroy {
  unassignedList: ChargeSheet[];
  loading: boolean;
  totalItem: number = null;
  pageNumber = 1;
  autoRefreshList: Subscription;

  startInterval: any;
  countdown = 0;

  correctionDialog: MatDialogRef<ChargeSheetErrorDialogComponent>;

  constructor(
    private chargeSheetService: ChargeSheetService,
    private userService: UserService,
    private router: Router,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.getUnassignedList();
    this.autoRefreshList = this.chargeSheetService.autoRefreshList().subscribe({
      next: () => this.getUnassignedList(this.pageNumber),
      error: (errMsg: string) => this.openSnackBar(errMsg, true)
    });
  }

  ngOnDestroy(): void {
    this.autoRefreshList.unsubscribe();
    clearInterval(this.startInterval);
    if (typeof this.correctionDialog === 'object' && this.correctionDialog.getState() === 0) {
      this.correctionDialog.close();
    }
  }

  getUnassignedList(pageNumber = 1): any[] {
    this.chargeSheetService.getUnassignedChargeSheet(pageNumber)
      .subscribe({
        next: (res: HttpResponse<any>) => {
          this.totalItem = Number(res.headers.get('x-total-count'));
          this.unassignedList = res.body;
          this.loading = false;
        },
        error: () => this.loading = false
      });
    return;
  }

  refreshList(): void {
    this.loading = true;
    this.unassignedList = [];
    this.getUnassignedList();
    this.delayRefreshButton();
  }

  delayRefreshButton(): void {
    this.countdown = 5;
    this.startInterval = setInterval(() => {
      if (this.countdown === 0) {
        clearInterval(this.startInterval);
        return ;
      }
      this.countdown--;
    }, 1000);
  }

  handleItemClick(event: { action: string, data: ChargeSheet, listType: any }): any {
    const chargeSheetId: number = event.data.id;
    if (event.action === 'assign-to-me') {
      this.handleAssignToCurrentUser(event.data);
      return ;
    }
    this.chargeSheetService.getChargeSheet(chargeSheetId, true)
      .pipe(first())
      .subscribe({
        next: (uri: string) => {
          this.router.navigate([]).then(() => window.open(uri, '_blank'));
        }
      });
  }

  handleAssignToCurrentUser(chargeSheet: ChargeSheet): void {
    const currentUserId: number = (JSON.parse(localStorage.getItem('currentUser')) as User).id;
    this.chargeSheetService.updateChargeSheetError(chargeSheet.id, { clerkId: currentUserId })
      .pipe(first())
      .subscribe({
        next: () => {
          this.openSnackBar('Successfully assigned to you.', false);
          this.getUnassignedList();
        }
      });
  }

  handleChargeSheetError({chargeSheet, index}: any): void {
    this.correctionDialog = this.matDialog.open(ChargeSheetErrorDialogComponent, {
      restoreFocus: false,
      disableClose: true,
      data: {
        type: 'unassigned',
        chargeSheet,
        index,
        ward: chargeSheet.ward
      }
    });

    this.correctionDialog.afterClosed()
      .pipe(first())
      .subscribe({
        next: (isSuccessUpdate: boolean) => {
          if (isSuccessUpdate) {
            this.unassignedList = [];
            this.loading = true;
            this.getUnassignedList();
          }
        }
      });
  }

  handleRemoveDraftItem({dialogComponent, id}): void {
    dialogComponent.afterClosed().subscribe({
      next: (isConfirm) => {
        if (isConfirm) {
          this.removeChargeSheet(id);
        }
      }
    });
  }

  handlePageChange(pageIndex: number): void {
    this.pageNumber = pageIndex;
    this.getUnassignedList(pageIndex);
  }

  removeChargeSheet(id: number) {
    this.loading = true;
    this.chargeSheetService.removeDraftedChargeSheet(id).subscribe({
      next: () => {
        this.getUnassignedList();
      }
    });
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
