import {Component, Inject, OnInit} from '@angular/core';
import {Ward} from '@models/ward.model';
import {ChargeSheetService} from '@services/chargesheet.service';
import {UserService} from '@services/user.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChargeSheet} from '@models/chargesheet.model';
import {first} from 'rxjs/operators';
import {User} from '@models/user.model';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-charge-sheet-error-dialog',
  template: `
    <form class="cmat-dialog-container" [formGroup]="fgError" (ngSubmit)="submit()">
      <div class="dialog-header">
        <h2 mat-dialog-title class="dialog-title text-icon">
          <mat-icon class="dialog-icon-title" color="warn">warning</mat-icon>
          <p class="dialog-text-title cwarn">{{ dialogTitle }} not found</p>
        </h2>
      </div>
      <div mat-dialog-content class="cmat-dialog-content" fxLayout="column">
        <p>Pleases include them in the Charge Sheet</p>
          <mat-form-field appearance="standard">
            <mat-label>Episode Number</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="episodeNumber" required/>
            <mat-error>This field is required.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" *ngIf="chargeSheet.createdBy === 'SYSTEM'">
            <mat-label>Clerk</mat-label>
            <mat-select formControlName="clerkId" required>
              <ng-container *ngFor="let option of clerkSelection">
                <mat-option
                  *ngIf="option.status === 'ACTIVE'"
                  [value]="option.id">{{option.name}}</mat-option>
              </ng-container>
            </mat-select>
            <mat-error>This field is required.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" *ngIf="!chargeSheet.ward">
            <mat-label>Ward</mat-label>
            <mat-select formControlName="ward" required>
              <ng-container *ngFor="let option of wardSelection">
                <mat-option
                  *ngIf="option.status === 'ACTIVE'"
                  [value]="option.code">{{option.description}}</mat-option>
              </ng-container>
            </mat-select>
            <mat-error>This field is required.</mat-error>
          </mat-form-field>
      </div>
      <div mat-dialog-actions class="dialog-actions" fxLayout="row wrap" fxLayoutAlign="space-between none">
        <button mat-raised-button mat-dialog-close type="button" color="basic" [matDialogClose]="false">Cancel</button>
        <button mat-raised-button type="submit" color="primary">Confirm</button>
      </div>
    </form>`,
  styleUrls: ['./charge-sheet-error-dialog.component.scss']
})

export class ChargeSheetErrorDialogComponent implements OnInit {
  errorSection: string;
  chargeSheet: ChargeSheet;
  index: number;
  dialogTitle: string;

  clerkSelection: User[];
  wardSelection: Ward[];
  fgError: FormGroup;
  notErrorStrArr: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) data: any,
              private dialogRef: MatDialogRef<ChargeSheetErrorDialogComponent>,
              private chargeSheetService: ChargeSheetService,
              private userService: UserService,
              private router: Router,
              private matDialog: MatDialog,
              private formBuilder: FormBuilder,
              private matSnackBar: MatSnackBar) {
    this.chargeSheet = data.chargeSheet;
    this.index = data.index;
    this.errorSection = data.type;
  }

  ngOnInit() {
    this.fgError = this.formBuilder.group({
      episodeNumber: [this.chargeSheet.episodeNumber, Validators.required],
      clerkId: [this.chargeSheet.createdBy === 'SYSTEM' ? '' : this.chargeSheet.createdBy , Validators.required],
      ward: [this.chargeSheet.ward, Validators.required]
    });
    this.dialogTitle = this.setDialogTitle();
    this.getClerkList();
    this.getWardList();
  }

  setDialogTitle(): string {
    const errorText = ['Episode Number', 'Clerk', 'Ward'];
    if (this.chargeSheet.episodeNumber) {
      this.notErrorStrArr.push('episodeNumber');
      errorText.splice(0, 1);
    }

    if (this.chargeSheet.createdBy !== 'SYSTEM') {
      this.notErrorStrArr.push('clerkId');
      errorText.splice(errorText.indexOf('Clerk'), 1);
    }

    if (this.chargeSheet.ward) {
      this.notErrorStrArr.push('ward');
      errorText.splice(errorText.indexOf('Ward'), 1);
    }

    if (errorText.length === 2) {
      return errorText.toString().replace(',', ' and ');
    }

    if (errorText.length === 3) {
      const text = errorText.toString();
      const firstCommaIndex: number = text.indexOf(',');
      const textSubstr = text.substr(firstCommaIndex + 1).trim().replace(',', ' and');
      return `${errorText[0]}, ${textSubstr}`;
    }

    return errorText.toString();
  }

  getClerkList(): any {
    this.userService.getAllClerk()
      .pipe(first())
      .subscribe({
        next: (data: User[]) => this.clerkSelection = data,
        error: () => this.clerkSelection = []
      });
  }

  getWardList(): any {
    this.chargeSheetService.getWardList()
      .pipe(first())
      .subscribe({
        next: (wardList) => this.wardSelection = wardList,
        error: () => this.wardSelection = []
      });
  }

  submit(): void {
    if (this.fgError.invalid) {
      return ;
    }

    this.fgError.disable();
    const cloneFgError = JSON.parse(JSON.stringify(this.fgError.value));

    for (const notErrorKey of this.notErrorStrArr) {
      delete cloneFgError[notErrorKey];
    }

    this.chargeSheetService.updateChargeSheetError(this.chargeSheet.id, cloneFgError)
      .pipe(first())
      .subscribe({
        next: () => {
          this.openSnackBar('Update Charge Sheet Successfully.', false);
          this.fgError.enable();
          this.dialogRef.close(true);
        },
        error: (errMsg: string) => {
          this.fgError.enable();
          this.openSnackBar(errMsg, true);
        }
      });
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
