import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { NewEntryComponent } from './newentry/newentry.component';
import { CorrectComponent } from './lists/correct/correct.component';
import { ErrorComponent } from './lists/error/error.component';
import { MarkingstageComponent } from './markingstage/markingstage.component';
import { MydraftComponent } from './lists/mydraft/mydraft.component';
import { DataentrystageComponent } from './dataentrystage/dataentrystage.component';

import { ChargeSheetGuard } from '@shared-helpers/chargesheet.guard';
import {SubmittedComponent} from './lists/submitted/submitted.component';
import {AttentionComponent} from './lists/submitted/attention/attention.component';
import {WaitingComponent} from './lists/submitted/waiting/waiting.component';
import {CompletedComponent} from './lists/submitted/completed/completed.component';
import {UnassignedComponent} from './lists/unassgined/unassigned.component';

const chargeSheetRoutes: Routes = [
  {
    path: '',
    redirectTo: '/charge-sheet/dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [ChargeSheetGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'new-entry',
        component: NewEntryComponent
      },
      {
        path: 'unassigned',
        component: UnassignedComponent
      },
      {
        path: 'my-draft',
        component: MydraftComponent
      },
      {
        path: 'my-draft/:chargeSheetId/marking-stage',
        component: MarkingstageComponent
      },
      {
        path: 'my-draft/:chargeSheetId/data-entry-stage',
        component: DataentrystageComponent
      },
      {
        path: 'submitted-list',
        component: SubmittedComponent
      },
      {
        path: 'submitted-list/waiting/:submissionId',
        component: WaitingComponent
      },
      {
        path: 'submitted-list/completed/:submissionId',
        component: CompletedComponent
      },
      {
        path: 'submitted-list/attention/:submissionId',
        component: AttentionComponent
      },
      {
        path: 'submitted-list/archived/:submissionId',
        component: CompletedComponent
      },
      {
        path: 'correct-list',
        component: CorrectComponent
      },
      {
        path: 'error-list',
        component: ErrorComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(chargeSheetRoutes)],
  exports: [RouterModule]
})
export class ChargesheetRoutingModule { }
