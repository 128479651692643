import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { AuthModule } from './auth/auth.module';
import { ChargesheetModule } from './chargesheet/chargesheet.module';
import { SettingsModule } from './settings/settings.module';
import { MaterialModule } from './material.module';
import { SharedComponentsModule } from '@shared-components/shared-components.module';
import { ReportsModule } from './reports/reports.module';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpClientInterceptor} from '@shared-helpers/httpClient.interceptor';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      MaterialModule,
      AuthModule,
      ChargesheetModule,
      SettingsModule,
      SharedComponentsModule,
      MatTooltipModule,
      ReportsModule
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
