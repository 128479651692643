import {Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {User} from '@models/user.model';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';

import {UserService} from '@services/user.service';
import {ChargeSheet, SubmittedChargeSheet} from '@models/chargesheet.model';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import {ConfirmDialogComponent} from '@shared-components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges {
  @Input() inputFrom: string;
  @Input() data: ChargeSheet[] | User[];
  @Input() totalItem: number;
  @Input() pageIndex = 0;
  @Output() itemClick: EventEmitter<any> = new EventEmitter();
  @Output() removeItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() priority: EventEmitter<any> = new EventEmitter<any>();

  isUserListing = true;

  listingColumn: any[];
  dataSource: MatTableDataSource<ChargeSheet> | MatTableDataSource<User>;
  currentUserId: number;
  selection = new SelectionModel<User>(true, []); // never use

  isReadOnly: boolean = false;

  matToolTipPositionLeft: string = 'left';

  constructor(
    private userService: UserService,
    public matDialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.data = changes.data.currentValue;
      this.dataSource =
        new MatTableDataSource<ChargeSheet>(this.data as ChargeSheet[]) || new MatTableDataSource<User>(this.data as User[]);
    }

    if (changes.totalPage) {
      this.totalItem = changes.totalPage.currentValue;
    }

    if (changes.pageIndex) {
      this.pageIndex = changes.pageIndex.currentValue;
    }

    this.isReadOnly = (this.userService.currentUserRoleValue.name === 'STORE USER') || (this.userService.currentUserRoleValue.name === 'GUEST');
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ChargeSheet>(this.data as ChargeSheet[]) || new MatTableDataSource<User>(this.data as User[]);
    this.isUserListing = this.inputFrom.indexOf('users') > -1;
    this.listingColumn = this.setListHeader(this.inputFrom);
  }

  setListHeader(listType: string): string[] {
    let listHeader: string[];
    switch (listType) {
      case 'users':
        listHeader = ['name', 'email', 'role', 'createdAt', 'status', 'action'];
        this.currentUserId = this.userService.currentUserValue.id;
        break;
      case 'completed':
      case 'archived':
      case 'attention':
        listHeader = ['episodeNo', 'patientName', 'dataClerk', 'date', 'time', 'ward', 'lastAction', 'scanStatus', 'action'];
        break;
      case 'waiting':
        listHeader = ['urgent', 'episodeNo', 'patientName', 'dataClerk', 'date', 'time', 'ward', 'lastAction', 'scanStatus', 'action'];
        break;
      case 'unassigned':
        listHeader = ['episodeNo', 'patientName', 'dataClerk', 'date', 'time', 'ward', 'lastAction', 'scanStatus', 'totalPage', 'action'];
        break;
      default:
        listHeader = ['episodeNo', 'patientName', 'dataClerk', 'date', 'time', 'ward', 'lastAction', 'scanStatus', 'action'];
    }
    return listHeader;
  }

  setStatus(type: string, data: ChargeSheet | SubmittedChargeSheet | User): string {
    let statusProp: {className: string, text: string} = {className: null, text: null};
    const status = data.status.toLowerCase();

    if (this.isUserListing) {
      statusProp = this.setUserStatus(status);
    }

    if (this.inputFrom.toLowerCase() === 'mydraft' || this.inputFrom.toLowerCase() === 'unassigned') {
      const scanStatus = (data as ChargeSheet).scanStatus.toLowerCase();
      statusProp = this.setDraftStatus(scanStatus);
    }

    if (!this.isUserListing && (status !== 'draft' || this.inputFrom === 'searchAll')) {
      statusProp = this.setSubmittedStatus(status);
    }

    return statusProp[type];
  }

  setUserStatus(status: string): { className: string, text: string } {
    let className = 'verified';
    let text = status !== 'active' ? status : 'Active';

    if (status === 'unverified') {
      className = 'unverified';
      text = 'Unverified';
    }

    return { className, text };
  }

  setDraftStatus(scanStatus: string): { className: string, text: string } {
    let className = 'scan-';
    let text = scanStatus;

    switch (scanStatus) {
      case 'complete':
        className = 'success';
        text = 'Complete';
        break;
      case 'missing_info':
        className += 'missing-info';
        text = 'Missing Info';
        break;
      case 'patient_not_same':
        className += 'patient-not-same';
        text = 'Multiple Episode Number Detected';
        break;
      case 'error':
        className = 'error';
        text = 'Time Out';
        break;
      case 'in_progress':
        className = 'in-progress';
        text = 'In Progress';
        break;
    }
    return { className, text };
  }

  setSubmittedStatus(status: string): { className: string, text: string } {
    let className: string = null;
    let text = status;

    switch (status) {
      case 'attention':
        className = 'attention';
        text = 'Attention';
        break;
      case 'in_progress':
        className = 'in-progress';
        text = 'In Progress';
        break;
      case 'error':
        className = 'error';
        text = 'Time Out';
        break;
      case 'stop':
        className = 'stop';
        text = 'STOP';
        break;
      case 'completed':
        className = 'success';
        text = 'Done';
        break;
      case 'archived':
        className = '';
        text = 'Archived';
        break;
      case 'draft':
        className = 'draft';
        text = 'Draft';
        break;
      case 'charging':
        className = 'charging';
        text = 'Charging ...';
        break;
    }

    return { className, text };
  }

  handleItemClick(action: string, data, listType?: string) {
    this.itemClick.emit({action, data, listType});
  }

  handlePageChange(pageEvent: PageEvent): void {
    this.pageChange.emit(pageEvent.pageIndex + 1);
  }

  openRemoveChargeSheetDialog(chargeSheet: ChargeSheet): void {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      disableClose: true,
      data: {
        title: 'Remove Charge Sheet',
        iconTitle: 'delete',
        dialogType: 'warn',
        description: `Are you sure you want to remove this Charge Sheet ${chargeSheet.episodeNumber}`,
      }
    });

    this.removeItem.emit({dialogComponent: dialog, id: chargeSheet.id});
  }

  handleErrorItem(chargeSheet: ChargeSheet, index: number): void {
    this.errorItem.emit({ chargeSheet, index });
  }

  handlePriority(list): void {
    this.priority.emit(list.id);
  }
}
