import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {IDialogData, IDialogQtyWarn} from '@models/warn-dialog.model';

interface IDialogWarnMsg {
  title: string[];
  description: string[];
  contents?: any | IDialogQtyWarn[] | any[];
}

@Component({
    template: `<div class="cmat-dialog-container">
        <div class="dialog-header">
            <h2 mat-dialog-title class="dialog-title text-icon">
                <mat-icon class="dialog-icon-title cmat-warn">warning</mat-icon>
                <span class="dialog-text-title">{{ title }}</span>
            </h2>
        </div>
        <div mat-dialog-content>
            <h3 *ngFor="let desc of dialogConfigMsg.description" class="mat-dialog-desc">{{ desc }}</h3>
            <div class="mat-dialog-content content-list-container" *ngIf="isArr">
                <div class="content-list-header">
                    <div class="header-charge-item">Charge Item</div>
                    <div class="header-charge-page"> Page </div>
                    <div class="header-charge-quantity">Quantity</div>
                </div>
                <div class="cmat-dialog-content content-list" *ngFor="let content of contents" [matTooltip]="content.item">
                    <div class="content-item">{{ content.item }}</div>
                    <div class="content-page">{{ content.page }}</div>
                    <div class="content-quantity">{{ content.quantity }}</div>
                </div>
            </div>
            <br />
            <h3>{{ confirmationMsg }}</h3>
        </div>
        <mat-divider></mat-divider>
        <div mat-dialog-actions class="dialog-actions" fxLayout="row wrap" fxLayoutAlign="space-between none">
            <button mat-raised-button mat-dialog-close color="basic" [matDialogClose]="false">Cancel</button>
            <button mat-raised-button mat-dialog-close color="primary" [matDialogClose]="true" [disabled]="isLoading">Submit</button>
        </div>
    </div>`,
    styleUrls: ['./charge-sheet-warning-dialog.component.scss']
})

export class ChargeSheetWarningDialogComponent implements OnInit {
  isLoading: boolean;

  dialogConfigMsg: IDialogWarnMsg = {
    title: [],
    description: [],
    contents: null
  };

  title: string;
  description: string;
  contents: any | IDialogQtyWarn[] | any[];
  confirmationMsg: string = 'Do you wish to continue the submission?';

  isArr: boolean;

  constructor(public dialogRef: MatDialogRef<ChargeSheetWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
      this.isArr = (typeof data.contents === 'object' && data.contents);
      this.contents = this.isArr ? (data.contents as IDialogQtyWarn[]) : (data.contents as any | any[]);

      data.warnType.forEach((warnType: string) => {
        switch (warnType) {
          case 'quantity':
            this.dialogConfigMsg.title.push('Quantity Warning');
            this.dialogConfigMsg.description.push('There charge item(s) quantity more than 20 or 50.');
            break;
          case 'non-input-change':
            this.dialogConfigMsg.title.push('Charge Item Error Warning');
            this.dialogConfigMsg.description.push('There is no changes made to the error charge item.');
            break;
          case 'insufficient':
            this.dialogConfigMsg.title.push('Quantity More Than C+\'s Quantity');
            this.dialogConfigMsg.description.push('There are charge item(s) quantity more than warehouse\'s quantity.');
            break;
          case 'duplicate':
            this.dialogConfigMsg.title.push('Duplicate Charge Sheet');
            this.dialogConfigMsg.description.push(`Charge Sheet with Episode Number: ${data.contents ? data.contents : '(No Episode Number)'}`);
            this.confirmationMsg = 'Do you wish to duplicate current charge sheet?'
            break;
          default:
            this.dialogConfigMsg.title.push('Charge Sheet Warning');
            this.dialogConfigMsg.description.push('There\'s still error in the charge sheet.');
        }
      });

      if (data.warnType.length > 1) {
        this.title = this.dialogConfigMsg.title.join(' & ');
      } else {
        this.title = this.dialogConfigMsg.title[0];
      }
    }

  ngOnInit() { }
}
