import {Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '@services/user.service';
import {AuthService} from '@services/auth.service';

import {Route} from '@models/route.model';
import {User} from '@models/user.model';
import {EnumRole} from '@models/role.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() mockRoute: Route[];
  @Input() isSidebarClosed: boolean;
  currentUser: User;
  createNewChargeSheetPermission = true;
  newArrayRoute: Route[];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSidebarClosed) {
      this.isSidebarClosed = changes.isSidebarClosed.currentValue;
      this.cdr.detectChanges();
    }
  }

  getCurrentUser() {
    this.userService.currentUser
      .subscribe({
        next: (data) => {
          if (data) {
            this.currentUser = data;
            this.availableRoutes(data.role.id);
          }
        }
      });
  }

  private availableRoutes(roleId: number) {
    this.newArrayRoute = [...this.mockRoute];
    switch (roleId) {
      case EnumRole.ADMIN :
        break;
      case EnumRole.CHECKER :
      case EnumRole['STORE USER'] :
        this.newArrayRoute.splice(8);
        this.createNewChargeSheetPermission = false;
        break;
      case EnumRole.MANAGER :
      case EnumRole['DATA CLERK'] :
        this.newArrayRoute.splice(8);
        break;
      case EnumRole.GUEST :
        this.newArrayRoute.splice(7);
        this.createNewChargeSheetPermission = false;
        break;
      default :
        this.mockRoute.splice(0, this.mockRoute.length);
        break;
    }
  }

  isLinkActive(url: string): boolean {
    const QUERY_PARAMS_INDEX =  this.router.url.indexOf('?');
    const BASE_URL = QUERY_PARAMS_INDEX === -1 ? this.router.url :  this.router.url.slice(0, QUERY_PARAMS_INDEX);
    return BASE_URL === url || BASE_URL.indexOf(url) > -1;
  }

  setMixIcon(iconText: string, order: number): string {
    if (iconText.indexOf('-') < 0) {
      return iconText;
    }

    const splitIconText: string[] = iconText.split('-');
    return splitIconText[order];
  }

  assignQueryParam(linkText: string): { [key: string]: number } {
    switch (linkText) {
      case 'In Queue':
      case 'Reports':
        return {tab: 0};
      case 'Search':
        return {tab: 3};
      case 'Completed / Archived':
        return {tab: 1};
      case 'Attention':
        return {tab: 2};
    }
    return null;
  }
}
