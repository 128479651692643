import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmDialog} from '@models/confirm-dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div class="cmat-dialog-container">
      <div class="dialog-header">
        <h2 mat-dialog-title class="dialog-title text-icon">
          <mat-icon class="dialog-icon-title" [ngClass]="classColor" [color]="themeColor">{{ iconTitle }}</mat-icon>
          <span class="dialog-text-title" [ngClass]="classColor">{{ title }}</span>
        </h2>
      </div>
      <div mat-dialog-content>
        <p *ngIf="!isMarkingEmpty && !isArchive">{{ description }}</p>
        <ng-container *ngIf="isMarkingEmpty">
          <p>You have <b>NOT MARKED</b> :
            <ng-container *ngFor="let number of value.emptyPages; let i = index">
              <span class="para-comma primary fw-600">{{number}}</span>
            </ng-container>
          </p>
          <p>Continue to Submit ?</p>
        </ng-container>
        <ng-container *ngIf="isArchive">
          <div style="margin: 0 auto; width: 350px">
            <p>{{ description }}</p>
            <div>Episode Number: <b>{{ value.episodeNumber }}</b></div>
            <div>Ward: <b>{{ value.ward }}</b></div>
          </div>
        </ng-container>
      </div>
      <div mat-dialog-actions class="dialog-actions" fxLayout="row wrap" fxLayoutAlign="space-between none">
        <button mat-raised-button mat-dialog-close color="basic" [matDialogClose]="false">Cancel</button>
        <button mat-raised-button mat-dialog-close class="btn-confirm" [ngClass]="classColor" [color]="themeColor" [matDialogClose]="true">Confirm</button>
      </div>
    </div>`
})

export class ConfirmDialogComponent implements OnInit {
  title: string;
  description: string;
  iconTitle: string;
  dialogType = '';
  section: string;
  value: any;

  themeColor: string;
  classColor: string;

  isConfirm = false;
  isMarkingEmpty = false;
  isArchive: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmDialog) {
    this.title = data.title;
    this.iconTitle = data.iconTitle;
    this.description = data.description;
    this.dialogType = data.dialogType;
    this.section = !data.section ? '' : data.section;
    this.value = data.value;
  }

  ngOnInit() {
    let colorName = 'primary';
    this.isMarkingEmpty = this.section.indexOf('marking') > -1 && this.section.indexOf('empty') > -1;
    this.isArchive = this.section === 'archive';
    switch (this.dialogType.toLowerCase()) {
      case 'warn':
        colorName = 'warn';
        break;
      case 'accent':
        colorName = 'accent';
        break;
      case 'info':
        colorName = 'info';
        break;
    }
    this.themeColor = colorName;
    this.classColor = `${colorName}`;
  }
}
