import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService} from '@services/auth.service';
import {UserService} from '@services/user.service';

import {Role} from '@models/role.model';
import {User} from '@models/user.model';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChargeSheetGuard implements CanActivate, CanActivateChild {
  chargeSheetUrl = '/charge-sheet';
  userRole: Role;

  constructor(
    private authService: AuthService,
    private userService: UserService) {
    this.userService.currentUser
      .pipe(first())
      .subscribe(
        (user: User) => user ? this.userRole = user.role : null,
        error => console.log(error)
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    switch (state.url) {
      case `${this.chargeSheetUrl}/new-entry`:
        this.accessNewEntry(this.userRole);
        break;
    }

    return true;
  }

  accessNewEntry(role): boolean {
    return true;
  }
}
