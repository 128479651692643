export interface Role {
    id: number,
    name: string;
}

export enum EnumRole {
    "ADMIN" = 1,
    "CHECKER" = 2,
    "MANAGER" = 3,
    "DATA CLERK" = 4,
    "STORE USER" = 5,
    "GUEST" = 6
}
