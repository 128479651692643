import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

import {ChargeSheetService} from '@services/chargesheet.service';
import {ChargeSheet} from '@models/chargesheet.model';
import {SelectedMarking} from '@models/marking.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomSnackBarComponent} from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@shared-components/confirm-dialog/confirm-dialog.component';
import {first, switchMap} from 'rxjs/operators';
import {User} from '@models/user.model';
import {UserService} from '@services/user.service';
import {Ward} from '@models/ward.model';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-markingstage',
  templateUrl: './markingstage.component.html',
  styleUrls: ['./markingstage.component.scss'],
  providers: [DatePipe]
})
export class MarkingstageComponent implements OnInit {
  loading = false;
  chargeSheetId: number;

  chargeSheetForm: FormGroup;
  episodeNo = '';
  ward = '';
  dateTime = '';
  csScanStatus: string = '';

  clerkSelection: User[];
  wardSelection: Ward[];

  isReadOnly: boolean = false;

  csStatusMsg: string = '';

  constructor(
    private chargeSheetService: ChargeSheetService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(queryParams => this.chargeSheetId = queryParams.chargeSheetId);
    this.isReadOnly = (this.userService.currentUserRoleValue.name === 'STORE USER') || (this.userService.currentUserRoleValue.name === 'GUEST');
    this.chargeSheetForm = this.formBuilder.group({
      id: [''],
      episodeNumber: [{ value: '', disabled: this.isReadOnly }, Validators.required],
      backDateCharging: [{ value: null, disabled: this.isReadOnly }, Validators.required],
      clerkId: [{value: '', disabled: this.isReadOnly}, Validators.required],
      ward: [{ value: '', disabled: this.isReadOnly }, Validators.required],
      dateTime: [{ value: '', disabled: this.isReadOnly }, Validators.required],
      marking: [{ value: [] }],
      mrnNumber: [''],
      updatedDateTime: [{ value: '', }],
      lastViewPath: [this.router.url]
    });
    this.getClerkList();
    this.getWardList();
  }

  getClerkList(): void {
    this.userService.getAllClerk()
      .pipe(first())
      .subscribe({
        next: (data: User[]) => {
          let allClerks: User[] = data;
          if (this.userService.currentUserValue.role.name === 'ADMIN') {
            allClerks.unshift(this.userService.currentUserValue);
          }
          this.clerkSelection = allClerks;
        },
        error: () => this.clerkSelection = []
      });
  }

  getWardList(): any {
    this.chargeSheetService.getWardList()
      .pipe(first())
      .subscribe({
        next: (wardList) => this.wardSelection = wardList,
        error: () => this.wardSelection = []
      });
  }

  handleChargeSheetLoad(chargeSheetData) {
    const chargeSheet: ChargeSheet = chargeSheetData.data;
    this.csScanStatus = chargeSheet.scanStatus;

    this.csStatusMsg = this.chargeSheetService.setChargeSheetStatus(chargeSheet.dischargeStatus, chargeSheet.billFinalizedStatus);
    this.chargeSheetService.setChargeSheetHeader(this.chargeSheetForm.controls, chargeSheet);
  }

  handleMarkingChange(marker) {
    this.chargeSheetForm.get('marking').setValue(marker);
  }

  onSubmit(status: 'D', btnAction: 'Submit' | 'Save'): any {
    const marking: { [key: number]: SelectedMarking[] } = JSON.parse(JSON.stringify(this.chargeSheetForm.get('marking').value));
    const {emptyPages, haveMarkerPages} = this.checkEmptyPages(marking);
    let confirmDialog: MatDialogRef<any> = null;

    if (this.chargeSheetForm.invalid && btnAction.toLowerCase() === 'submit') {
      this.openSnackBar('Please fill in the required field.', true);
      return;
    }

    if (emptyPages.length > 0 && btnAction.toLowerCase() === 'submit') {
      confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: true,
        data: {
          title: 'Gentle Reminder',
          iconTitle: 'warning',
          description: ``,
          dialogType: 'info',
          section: 'marking-empty',
          value: { emptyPages, haveMarkerPages }
        }
      });
    } else {
      return this.submitMarkingStage(btnAction);
    }

    confirmDialog.afterClosed().subscribe({
      next: (isConfirm: boolean) => {
        if (isConfirm) {
          this.submitMarkingStage(btnAction);
        }
      }
    });
  }

  submitMarkingStage(btnAction: 'Submit' | 'Save'): void {
    const chargeSheetId: number = this.chargeSheetForm.get('id').value;
    const episodeNo: string = this.chargeSheetForm.get('episodeNumber').value;
    const ward: string = this.chargeSheetForm.get('ward').value;
    const clerkId: number = this.chargeSheetForm.get('clerkId').value;
    const lastViewPath = btnAction.toLowerCase() === 'save' ? this.router.url : `/charge-sheet/my-draft/${chargeSheetId}/data-entry-stage`;
    const marking = JSON.parse(JSON.stringify(this.chargeSheetForm.get('marking').value));
    const BACK_DATE_CHARGE = this.chargeSheetForm.get('backDateCharging').value;

    const UPDATE_CHARGE_SHEET_OBV: Observable<any> = this.chargeSheetService.updateChargeSheetError(chargeSheetId, { ward });
    const SUBMIT_MS_CS_OBV = this.chargeSheetService.submit(chargeSheetId, episodeNo, ward, clerkId, 'D', lastViewPath, marking, BACK_DATE_CHARGE);

    this.loading = true;

    if (this.csScanStatus.toLowerCase() === 'missing_info') {
      UPDATE_CHARGE_SHEET_OBV.pipe(
        switchMap((updateRes: ChargeSheet) => {
          if (updateRes) {
            this.csScanStatus = updateRes.scanStatus;
          }

          return SUBMIT_MS_CS_OBV;
        })
      ).subscribe({
        next: () => {
          this.loading = false;
          switch(btnAction.toLowerCase()) {
            case 'submit':
              this.openSnackBar('Moving to Data Entry Stage', false);
              return this.router.navigate([`/charge-sheet/my-draft/${chargeSheetId}/data-entry-stage`]).then();
            case 'save':
              return this.openSnackBar('Successfully saved as draft', false);
          }
        },
        error: (err) => {
          this.loading = false;
          this.openSnackBar(err, true);
        }
      });
    } else {
      SUBMIT_MS_CS_OBV
      .subscribe({
        next: () => {
          this.loading = false;
          switch(btnAction.toLowerCase()) {
            case 'submit':
              this.openSnackBar('Moving to Data Entry Stage', false);
              return this.router.navigate([`/charge-sheet/my-draft/${chargeSheetId}/data-entry-stage`]).then();
            case 'save':
              return this.openSnackBar('Successfully saved as draft', false);
          }
        },
        error: (err) => {
          this.loading = false;
          this.openSnackBar(err, true);
        }
      })
    }
  }

  checkEmptyPages(selectedMarking: { [key: number]: SelectedMarking[] }):
    {emptyPages: number[], haveMarkerPages: number[]} {
    const emptyPages: number[] = [];
    const haveMarkerPages: number[] = [];
    Object.keys(selectedMarking).map((pageNumber: string) => {
      if (selectedMarking[pageNumber].length === 0) {
        emptyPages.push(parseInt(pageNumber, 10));
      } else {
        haveMarkerPages.push(parseInt(pageNumber, 10));
      }
    });
    return { emptyPages, haveMarkerPages };
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
