import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ListComponent } from './list/list.component';
import { UseravatarComponent } from './useravatar/useravatar.component';
import { CustomSnackBarComponent } from './custom-snack-bar/custom-snack-bar.component';
import { UserpopupComponent } from './userpopup/userpopup.component';
import { MarkerpanelComponent } from './markerpanel/markerpanel.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {ConfirmDialogComponent} from '@shared-components/confirm-dialog/confirm-dialog.component';
import {ChargeWardQuantityDialogComponent} from '@shared-components/charge-ward-quantity-dialog/charge-ward-quantity-dialog.component';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {ChargeSheetErrorDialogComponent} from '@shared-components/charge-sheet-error-dialog/charge-sheet-error-dialog.component';
import {MarkerInputComponent} from '@shared-components/markerpanel/marker-input/marker-input.component';
import { SearchByCriteriaComponent } from './search-by-criteria/search-by-criteria.component';
import {ChargeSheetWarningDialogComponent} from '@shared-components/charge-sheet-warning-dialog/charge-sheet-warning-dialog.component';
import {MarkerMiscChargeInputComponent} from '@shared-components/markerpanel/marker-misc-charge-input/marker-misc-charge-input.component';

const SHARED_COMPONENTS = [
  ListComponent,
  UseravatarComponent,
  CustomSnackBarComponent,
  UserpopupComponent,
  MarkerpanelComponent,
  ConfirmDialogComponent,
  ChargeWardQuantityDialogComponent,
  ChargeSheetErrorDialogComponent,
  MarkerInputComponent,
  SearchByCriteriaComponent,
  ChargeSheetWarningDialogComponent,
  MarkerMiscChargeInputComponent
];

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgxMatMomentModule
  ],
  exports: [
    ...SHARED_COMPONENTS
  ]
})
export class SharedComponentsModule { }
