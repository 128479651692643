import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';

import { ConfigurationService } from '@services/configuration.service';
import {IConfiguration} from '@models/configuration.model';

import { delay } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-misc-charging',
  templateUrl: 'misc-charging.component.html',
  styleUrls: ['./misc-charging.component.scss']
})

export class MiscellaneousChargingComponent implements OnInit, OnChanges {
  @Input() config: IConfiguration;
  @Input() isExpanded: boolean;
  @Output() handleConfigUpdate: EventEmitter<IConfiguration> = new EventEmitter<IConfiguration>();
  miscChargingFG: FormGroup;

  isLoading: boolean = false;

  get isValueUnchange() { return this.miscChargingFG.get('current').value === this.miscChargingFG.get('original').value }

  constructor(private configService: ConfigurationService, private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.miscChargingFG = new FormGroup({
      current: new FormControl({ value: this.config.value, disabled: true }, [Validators.required]),
      original: new FormControl({ value: this.config.value, disabled: true })
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.isExpanded) {
        if (!changes.isExpanded.currentValue) {
          this.handleCancelForm();
        }
      }

      if (changes.config) {
        if (changes.config.previousValue !== undefined) {
          const MISC_CHARGING_ORI: string = changes.config.currentValue.value;
          this.miscChargingFG.get('original').patchValue(MISC_CHARGING_ORI);
        }
      }
  }

  handleEditForm(): void {
    this.miscChargingFG.get('current').enable();
  }

  handleCancelForm(): void {
    this.miscChargingFG.get('current').disable();

    this.resetCurrentToOriValue();
  }

  resetCurrentToOriValue(): void {
    const SET_ORI_VALUE: string = this.miscChargingFG.get('original').value;

    this.miscChargingFG.get('current').patchValue(SET_ORI_VALUE);
  }

  handleSubmitForm(): void {
    const MISC_CHARGE_VALUE: 'YES' | 'NO' = this.miscChargingFG.get('current').value;

    this.isLoading = true;

    this.configService.updateMiscCharging(MISC_CHARGE_VALUE)
    .pipe(delay(5))
      .subscribe({
        next: (res: IConfiguration) => {
          this.handleConfigUpdate.emit(res);
          this.miscChargingFG.get('current').disable();
          this.openSnackBar('Updated Miscellaneous Charging Successfully', false);
        },
        error: (err) => {
          this.openSnackBar(err, true);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      })
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
