import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthService} from '@services/auth.service';
import {environment} from '@proj-env/environment';
import {Router} from '@angular/router';
import {catchError, filter, first, mergeMap, take} from 'rxjs/operators';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  private apiHost = environment.apiUrl;
  private refreshTokenInProgress = false;
  private facility: string = this.authService.currentFacilityValue;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.currentFacility
      .subscribe({
        next: (newFacility: string) => {
          this.facility = newFacility;
        }
      });
  }

  private static authToken(facility: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: environment.oauth,
      'X-TenantID': facility
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    req = req.clone({
      url: `${this.apiHost}${req.url}`,
      responseType: req.url.indexOf('/download') > -1 ? 'blob' as 'json' : 'json',
      headers: this.headerOptions(req.url)
    });

    return next.handle(req)
      .pipe(
        catchError(err => {
          if (err.status !== 401) {
            return this.handleAuthError(err, req.url);
          }

          if (this.refreshTokenInProgress) {
            return this.getUpdatedToken(req, next);
          } else {
            this.refreshTokenInProgress = true;
            return this.refreshToken(req, next);
          }
        })
      );
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const refreshToken: string = localStorage.getItem('refreshToken');
    return this.authService.refreshToken(refreshToken)
      .pipe(
        first(),
        mergeMap(() => {
          this.refreshTokenInProgress = false;
          request = this.addAuthorizeToken(request);
          return next.handle(request);
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          return this.handleAuthError(err, request.url);
        })
      );
  }

  private getUpdatedToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.authService.currentTokenInfo
      .pipe(
        filter(result => result.token !== null),
        take(1),
        mergeMap(() => {
          request = this.addAuthorizeToken(request);
          return next.handle(request);
        })
      );
  }

  private handleAuthError(err: HttpErrorResponse, reqUrl: string): Observable<any> {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // Client Side Error
      errorMessage = `Error: ${err.error.message}`;
    } else {
      // Server Side Error
      errorMessage = this.setErrorMessage(err, reqUrl);
    }

    if ([401, 403].indexOf(err.status) > -1 || reqUrl.indexOf('oauth/token') > -1) {
      this.authService.removeAuth();
      this.router.navigateByUrl(`/auth/login`).then();
    }

    return throwError(errorMessage);
  }

  addAuthorizeToken(req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        Authorization: this.authService.currentTokenValue.token,
        'X-TenantID': this.facility
      }
    });
  }

  private headerOptions(apiUrl: string): any {
    if (apiUrl.indexOf('tenant/search') > -1) {
      return this.authFacility();
    }

    if (apiUrl.indexOf('oauth/token') > -1) {
      return HttpClientInterceptor.authToken(this.facility);
    }

    if (apiUrl.indexOf('api/forgot-password') > -1 ||
        apiUrl.indexOf('api/reset-password') > -1 ||
        apiUrl.indexOf('api/activate-user') > -1) {
      return;
    }
    return this.requestToken();
  }

  private authFacility(): any {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: environment.oauth
    });
  }

  private requestToken(): any {
    return new HttpHeaders({
      Authorization: this.authService.currentTokenValue.token,
      'X-TenantID': this.facility
    });
  }

  private setErrorMessage(error: HttpErrorResponse, path: string): string {
    // const errorDesc: string = error.error.error_description;
    //
    // if (errorDesc === 'Bad credentials' && path.indexOf('/oauth/token') > -1) {
    //   return 'Invalid email or password.';
    // }

    const ERROR_MESSAGE: string = error.error.message ? error.error.message : error.statusText;

    if (path.indexOf('/oauth/token') > -1) {
      return 'Invalid email or password';
    }

    return `Error ${error.status}, ${ERROR_MESSAGE}`;
  }
}
