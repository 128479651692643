import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {Auth} from '@models/auth.model';
import {FormGroup} from '@angular/forms';

import { environment } from '@proj-env/environment';

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentTokenInfoSubject: BehaviorSubject<any>;
  public currentTokenInfo: Observable<any>;

  private currentFacilitySubject: BehaviorSubject<string>;
  public currentFacility: Observable<string>;

  constructor(private http: HttpClient) {
    this.currentTokenInfoSubject = new BehaviorSubject<any>({
      token: localStorage.getItem('token'),
      expires: localStorage.getItem('expires')
    });
    this.currentTokenInfo = this.currentTokenInfoSubject.asObservable();

    this.currentFacilitySubject = new BehaviorSubject<string>(localStorage.getItem('facility'));
    this.currentFacility = this.currentFacilitySubject.asObservable();
  }

  public get currentTokenValue() {
    return this.currentTokenInfoSubject.getValue();
  }

  public get currentFacilityValue(): string {
    return this.currentFacilitySubject.getValue();
  }

  login(email: string, password: string, facility: string): Observable<Auth> {
    const body: HttpParams = new HttpParams()
      .set('username', email)
      .set('password', password)
      .set('grant_type', 'password');

    if (facility) {
      this.currentFacilitySubject.next(facility);
    }

    return this.http.post<Auth>(`oauth/token`, body)
      .pipe(
        map(data => {
          this.assignToken(data);
          return data;
        }
      ));
  }

  assignFacility(facility: string): void {
    localStorage.setItem('facility', facility);
  }

  getADFacility(username: string): Observable<string[]> {
    const isProd: boolean = environment.production;

    if (!isProd) {
      return of(['GKK', 'GKL', 'GMH', 'GPG', 'PHA', 'PHAK', 'PHBP', 'PHC', 'PHI', 'PHK', 'PHKL', 'PHLM', 'PHM', 'PHP', 'PHSP']);
    }

    return this.http.get<string[]>(`api/tenant/search/${username}`);
  }

  refreshToken(refreshToken: string): Observable<Auth> {
    const body: HttpParams = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken);
    return this.http.post<Auth>(`oauth/token`, body)
      .pipe(
        map(data => {
          this.assignToken(data);
          return data;
        }));
  }

  isTokenExpired(getCurrentUser: Observable<any>): Observable<boolean> {
    return getCurrentUser;
  }

  assignToken(data: any): any {
    localStorage.setItem('token', `${data.token_type} ${data.access_token}`);
    localStorage.setItem('refreshToken', data.refresh_token);
    localStorage.setItem('expires', data.expires_in.toString());
    this.currentTokenInfoSubject.next({ token: `${data.token_type} ${data.access_token}`, expires: data.expires_in });
  }

  resetPassword(formValue: FormGroup, setPasswordToken: string) {
    const body = {
      password: formValue.get('newPassword').value,
      confirmPassword: formValue.get('confirmPassword').value
    };
    return this.http.put(`api/reset-password/${setPasswordToken}`, body)
      .pipe(
        tap(_ => console.log('Password Reset.'))
      );
  }

  activateAccount(formValue: FormGroup, setPasswordToken: string) {
    const body = {
      password: formValue.get('newPassword').value,
      confirmPassword: formValue.get('confirmPassword').value
    };
    return this.http.put(`api/activate-user/${setPasswordToken}`, body)
      .pipe(
        tap(_ => console.log('Account Activated.'))
      );
  }

  requestPassword(formValue: FormGroup) {
    const body = {
      email: formValue.get('email').value
    };
    return this.http.post(`api/forgot-password`, body)
      .pipe(
        tap(_ => console.log('Request Password Sent.'))
      );
  }

  logout(): Observable<any> {
    // remove user from local storage to log user out
    return this.http.post('api/logout', {Authorization: this.currentTokenValue})
      .pipe(
        tap(() => {
          this.removeAuth();
        })
      );
  }

  removeAuth() {
    localStorage.clear();
    this.currentFacilitySubject.next(null);
    this.currentTokenInfoSubject.next({ token: null, expires: null });
  }
}
