import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';

import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-requestpassword',
  templateUrl: './requestpassword.component.html',
  styleUrls: ['./requestpassword.component.scss']
})
export class RequestpasswordComponent implements OnInit {
  requestPasswordForm: FormGroup;
  loading = false;
  redirectIndicator = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.requestPasswordForm = this.formBuilder.group({
      email: [ '', [ Validators.required, Validators.email ] ]
    });
  }

  onSubmit() {
    if(this.requestPasswordForm.invalid) {
      return ;
    }

    this.requestPasswordForm.disable();
    this.loading = true;

    return this.authService.requestPassword(this.requestPasswordForm)
      .subscribe(
        data => {
          this.redirectIndicator = true;
          setTimeout(() => {
            this.router.navigateByUrl('/auth/login');
          }, 5000);
          this.openSnackBar('Request Password has been sent.', false);
        },
        error => {
          console.error(error);
          this.requestPasswordForm.enable();
          this.loading = false;
        });
  }

  private openSnackBar(message: string, isError) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message: message,
        isError: isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
