import { Component, OnDestroy, OnInit, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { ConfigurationService } from '@services/configuration.service';
import { IConfiguration, ICredential } from '@models/configuration.model';

import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-setting-config-credential',
  templateUrl: './credential.component.html',
  styleUrls: ['./credential.component.scss']
})

export class CredentialComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() config: IConfiguration;
  @Input() isExpanded: boolean;
  @Output() handleConfigUpdate: EventEmitter<IConfiguration> = new EventEmitter<IConfiguration>();

  credentialFG: FormGroup;

  hideOldPass: boolean = true;
  hideNewPass: boolean = true;
  isReadOnly: boolean = false;

  isUpdateCredential: boolean = false;
  isLoading: boolean = false;

  constructor(private configService: ConfigurationService, private matSnackBar: MatSnackBar) { }

  get isFirstTimeSetup() { return this.config.value === null }

  ngOnInit() {
    this.credentialFG = new FormGroup({
      username: new FormControl( { value: this.config.value, disabled: true }, [Validators.required])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.isExpanded) {
        if (!changes.isExpanded.currentValue) {
          this.handleCancelForm();
        }
      }
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void { }

  handleFormSubmit(): any {
    if (this.credentialFG.invalid) { return; }

    this.isReadOnly = true;
    this.isLoading = true

    const USERNAME: string = this.credentialFG.get('username').value;
    const NEW_PASSW: string = this.credentialFG.get('newPassword').value;

    this.configService.updateApplicationCredential(USERNAME, NEW_PASSW)
      .subscribe({
        next: (res: ICredential) => {
          this.credentialFG.disable();
          this.openSnackBar('Update Cerebral Plus Credential Successfully', false);
          this.removeFGControls();
          this.handleConfigUpdate.emit(res);
        },
        error: (err) => {
          this.credentialFG.enable();
          this.openSnackBar(err, true);
          this.isReadOnly = false;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  handleEditForm(): void {
    this.isUpdateCredential = true;
    this.isReadOnly = false;
    this.credentialFG.enable();

    this.credentialFG.addControl('newPassword', new FormControl(null, [Validators.required]));
    this.credentialFG.get('newPassword').reset();
  }

  handleCancelForm(): void {
    this.credentialFG.disable();

    this.removeFGControls();
  }

  removeFGControls(): void {
    this.isUpdateCredential = false;

    if (Object.keys(this.credentialFG.controls).indexOf('newPassword') > -1) {
      this.credentialFG.removeControl('newPassword');
    }
  }

  private openSnackBar(message: string, isError: boolean): MatSnackBarRef<any> {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }
}
