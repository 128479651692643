import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CustomSnackBarComponent } from '@shared-components/custom-snack-bar/custom-snack-bar.component';
import { UserService } from '@services/user.service';

import { User } from '@models/user.model';
import { Role, EnumRole } from '@models/role.model';
import { UserPopupModel } from '@models/userpopup.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-userpopup',
  templateUrl: './userpopup.component.html',
  styleUrls: ['./userpopup.component.scss']
})
export class UserpopupComponent implements OnInit {
  userDetail: User;
  userConfig: string;
  userForm: FormGroup;
  roleSelection: Role[] = [];
  managerSelection: User[] = [];
  selectedRole: number = null;
  selectedManager: number = null;

  isCreateUser: boolean;
  isUpdateUser: boolean;
  isUserProfile: boolean;
  isDeleteUser: boolean;
  isAdmin: boolean;
  isDataClerk: boolean;

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: UserPopupModel,
    private dialogRef: MatDialogRef<UserpopupComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private matSnackBar: MatSnackBar) {
      this.userDetail = data.userDetail;
      this.userConfig = data.userConfig;
    }

  ngOnInit(): void {
    this.isUpdateUser = this.userConfig === 'update profile' || this.userConfig === 'update user' || this.userConfig === 'delete user';
    this.isUserProfile = this.userConfig === 'update profile';
    this.isDeleteUser = this.userConfig === 'delete user';

    this.userService.currentUser
    .pipe(first())
    .subscribe(user => {
      switch (user.role.id) {
        case EnumRole.ADMIN :
          return this.isAdmin = true;
        case EnumRole['DATA CLERK'] :
          return this.isDataClerk = true;
      }
    });

    if (this.isUpdateUser) {
      if (this.userDetail.role.id === EnumRole['DATA CLERK']) {
        this.isDataClerk = true;
      }
    } else {
      this.isCreateUser = true;
    }

    // Initialize Role Array
    for (let n in EnumRole) {
      typeof EnumRole[n] === 'number' ? this.roleSelection.push({ id: <any>EnumRole[n], name: n }) : null;
    }

    this.userDetail !== null ? this.initRoleAndManager() : null;
    this.userForm = this.formBuilder.group({
      staffName: [ (this.isUpdateUser ? this.userDetail.name : ''), Validators.required],
      empNo: [(this.isUpdateUser ? this.userDetail.employeeNO : '')],
      email: [(this.isUpdateUser ? this.userDetail.email : '')],
      contactNo: [(this.isUpdateUser ? this.userDetail.contact : '')],
      nric: [(this.isUpdateUser ? this.userDetail.nric : '')],
      address: [(this.isUpdateUser ? this.userDetail.address : '')],
      staffRole: [{
          value: this.selectedRole,
          disabled: this.isDeleteUser || !this.isAdmin
        } , Validators.required
      ],
      lineManager: [{
        value: this.selectedManager,
        disabled: this.isDeleteUser || !this.isAdmin
      }],
      password: [ {
        value: '',
        disabled: this.loading
      } ]
    });

    if (this.isDeleteUser) {
      this.userForm.disable();
    }
  }

  submitForm(): any {
    this.loading = true;

    if (this.userForm.invalid) {
      this.loading = false;
      return ;
    }

    this.userForm.disable();
    switch (this.userConfig) {
      case 'create' :
        return this.createUser();
      case 'update profile' :
        return this.updateProfile();
      case 'update user' :
        return this.updateUser();
      case 'delete user' :
        return this.deleteUser();
      default:
        this.enableUserForm();
        break;
    }
  }

  createUser() {
    this.userService.createUser(this.userForm)
      .subscribe(
        data => {
          this.openSnackBar('Created User Successfully.', false);
          this.dialogRef.close();
        },
        error => {
          this.enableUserForm();
          this.openSnackBar(error, true);
        }
      );
  }

  updateProfile() {
    this.userService.updateUserProfile(this.userForm, this.userDetail.id)
      .pipe(first())
      .subscribe(
        data => {
          this.openSnackBar('Updated Profile Successfully', false);
          this.dialogRef.close();
        },
        error => {
          this.enableUserForm();
          this.openSnackBar(error.message, true);
        });
  }

  updateUser() {
    this.userService.updateUser(this.userForm, this.userDetail.id)
      .pipe(first())
      .subscribe(
        data => {
          this.openSnackBar('Updated User Successfully', false);
          this.dialogRef.close();
        },
        error => {
          this.enableUserForm();
          this.openSnackBar(error.message, true);
        });
  }

  deleteUser() {
    this.userService.deleteUser(this.userDetail.id)
      .subscribe(
        data => {
          this.openSnackBar('Deleted User Successfully', false);
          this.dialogRef.close();
        },
        error => {
          this.enableUserForm();
          this.openSnackBar(error.message, true);
        }
      );
  }

  private initRoleAndManager() {
    this.roleSelection.forEach(role => {
      if (role.name.includes(this.userDetail.role.name)) {
        return this.selectedRole = role.id;
      }
    });

    this.userService.getManagerList()
      .subscribe((manager: User[]) => {
        this.managerSelection = manager;
        if (this.managerSelection.length > 0) {
          this.managerSelection.forEach((data) => {
            if (data.id === this.userDetail.managerId) {
              return this.selectedManager = data.id;
            }
          });
        }
      });
  }

  private enableUserForm() {
    this.loading = false;
    this.userForm.enable();
  }

  private openSnackBar(message: string, isError: boolean) {
    return this.matSnackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        message,
        isError
      },
      verticalPosition: 'top',
      panelClass: isError ? ['error-sb'] : ['success-sb']
    });
  }

}
